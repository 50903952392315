import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ContentfulImageQuery, WmkImage, Img } from "wmk-image";
import { WmkLink } from "wmk-link";
import { colors } from "../../ui/colors";

const StyledLink = styled(WmkLink)`
  .title {
    font-weight: bold;
    line-height: 1;
    font-size: 3rem;
    padding: 1rem;
    text-shadow: 3px 6px 12px rgba(0, 0, 0, 0.25);
    color: ${colors.white.getHex()};
    @media screen and (max-width: 800px) {
      font-size: 2.25rem;
    }
  }
`;

const StyledTile = styled.div`
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 70%;
  background-color: rgba(255, 255, 255, 0.3);

  display: flex;
  align-items: center;
`;

const WaterLessYall = () => {
  const data: TileQuery = useStaticQuery(query);
  return (
    <Container style={{display: `flex`, justifyContent: `center`}} id="water-less-yall-tiles">
      <Row style={{maxWidth: `830px`}}>
        {data?.tiles?.tiles.map((tile) => {
          return (
            <Col xs={6} lg={4} key={tile.title}>
              <StyledLink to={tile.url} target="blank">
                <div style={{ padding: `.75rem 0`, position: "relative" }}>
                  <WmkImage image={new Img(tile.asset)} />
                  <StyledTile className="tile">
                    <div className="title">{tile.title}</div>
                  </StyledTile>
                </div>
              </StyledLink>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default WaterLessYall;

interface TileQuery {
  tiles: {
    tiles: {
      title: string;
      asset: ContentfulImageQuery;
      url: string;
    }[];
  };
}

const query = graphql`
  {
    tiles: contentfulMenuContainer(title: { eq: "Water Less, Y'all Tiles" }) {
      tiles: menuItems {
        ... on ContentfulMenuItem {
          title
          asset {
            file {
              url
              contentType
            }
            gatsbyImageData(width: 250, height: 250, resizingBehavior: FILL)
            title
          }
          url
        }
      }
    }
  }
`;
