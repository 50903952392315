import React from "react";
import { Row, Col } from "react-bootstrap";
import WidgetSubTitle from "../../../common/typography/WidgetSubTitle";
import NewsExcerptTitle from "../NewsExcerptTitle";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";
import { NewsPost } from "../../../../classes/News";

const RecentWrap = styled(Col)`
  .dots {
    display: flex;
    align-items: center;
  }
  .dot {
    height: 1.5vh;
    width: 1.5vh;
    border: 1px solid ${colors.hex("white")};
    display: block;
    border-radius: 50%;
    margin: 0.5vh;
    transition: all 0.3s ease;
  }
  &:hover .dot {
    background: ${colors.hex("white")};
    transition: all 0.3s ease;
    &.second-dot {
      transition-delay: 0.1s;
    }
    &.third-dot {
      transition-delay: 0.2s;
    }
  }
`;

const RecentReleases = ({ news }: { news: NewsPost[] }) => {
  return (
    <Row className="flex-column">
      <Col>
        <WidgetSubTitle
          style={{
            textShadow: "none",
            textAlign: "left",
            textTransform: "uppercase"
          }}>
          Recent Releases
        </WidgetSubTitle>
      </Col>
      {news &&
        news.length &&
        news.map((n, i) => {
          const {
            title,
            path
            //article
          } = n;
          return (
            <RecentWrap key={title + i}>
              <Row>
                <Col>
                  <Row
                    className="flex-column"
                    style={{
                      borderBottom: "1px solid " + colors.hex("white"),
                      paddingBottom: ".5rem",
                      paddingTop: ".5rem"
                    }}>
                    <Col>
                      <NewsExcerptTitle
                        path={path}
                        reverse
                        style={{ fontSize: `1.125rem` }}>
                        {title}
                      </NewsExcerptTitle>
                    </Col>
                    {/* <Col style={{ color: colors.hex("tertiary") }}>
                      {getNewsIntro(article, 60)}
                    </Col> */}
                  </Row>
                </Col>
                <Col sm="auto" className="dots">
                  <Row className="flex-column">
                    <Col>
                      <span className="dot first-dot" />
                    </Col>
                    <Col>
                      <span className="dot second-dot" />
                    </Col>
                    <Col>
                      <span className="dot third-dot" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </RecentWrap>
          );
        })}
    </Row>
  );
};

export default RecentReleases;
