import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
// import Icon1 from "../images/icon-step1.png";

const Icon1 = require("../images/icon-step1.png")

const step1IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`;

const Wrap = styled.div`
  .click-step1 {
    height: 9vw;
    top: 4%;
    &.open {
      z-index: 150 !important;
      border-right: 6px solid white !important;
      width: 40%;
      left: 54%;
      clip-path: polygon(15% 0%, 0% 100%, 100% 100%, 100% 0%);
      transition: left 0.75s ease, width 0.5s ease 0.75s,
        clip-path 0.5s ease 0.75s, border-right 0.5s ease 0.75s;
    }
    &.closed {
      border-right: 0;
      width: 10.5vw;
      left: 67%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width 0.65s ease, border-right 0.5s ease, clip-path 0.65s ease,
        left 0.5s ease 0.65s;
    }
    // .right-border-div {
    //   height: 100%;
    //   width: 5px;
    //   background: white;
    // }
    .info-wrap {
      .title {
        text-align: center;
        white-space: nowrap;
        &.title-only {
          margin-top: 20px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 1rem 10px 1rem 2rem;
        .icon {
          margin-right: 1rem;
          img.open {
            width: auto;
            height: 5rem;
            animation: ${step1IconGrow} 0.75s ease;
          }
          img.close {
            width: auto;
            height: 0rem;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            margin-bottom: 10px;
            font-size: 1.2rem;
          }
          .copy {
            text-align: left;
            line-height: 20px;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .step1-line1 {
    height: 4px;
    top: 12%;
    left: 60%;
    width: 12%;
    transition: width 0.25s ease-in 0.65s;
    &.open {
      width: 1%;
      transition: width 0.75s ease;
    }
  }
  .circle-step1 {
    top: 10%;
    right: 38.5%;
  }

  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step1 {
      top: 1%;
      &.open {
        width: 40%;
        left: 51%;
        // height: 20% !important;
      }
      .full-info-wrap {
        padding-top: 4% !important;
        .title {
          // font-size: .9rem !important;
        }
        .copy {
          // font-size: .7rem !important;
        }
        .icon {
          padding-top: 0.5rem;
          img {
            height: 3rem !important;
          }
        }
      }
    }
    .step1-line1 {
      left: 59%;
      width: 15%;
      transition: width 0.25s ease-in 0.65s;
      &.closed {
        width: 15%;
      }
    }
  }
  @media only screen and (max-width: 850px) {
    .click-step1 {
      &.open {
        width: 48%;
      }
      .info-wrap.closed {
        .title {
          font-size: 0.7rem;
          line-height: 13px !important;
          margin-top: 1vw !important;
        }
      }
      .full-info-wrap {
        padding: 2% 5px 1rem 7% !important;
        .title {
          font-size: 1rem !important;
          margin-bottom: 4px !important;
        }
        .copy {
          font-size: 0.8rem !important;
          line-height: 13px !important;
        }
        .icon {
          padding-top: 0.5rem;
          margin-right: 5px !important;
          img {
            height: 3rem !important;
          }
        }
      }
    }
    .step1-line1 {
      top: 14%;
    }
  }
  @media only screen and (max-width: 475px) {
    .click-step1 {
      &.open {
        width: 45%;
      }
      .info-wrap.closed {
        .title {
          font-size: 0.5rem;
        }
      }
      .full-info-wrap {
        margin-top: -4px;
        padding: 0 5px 1rem 4% !important;
        .title {
          font-size: 0.6rem !important;
          margin-bottom: -2px !important;
        }
        .copy {
          font-size: 0.6rem !important;
          line-height: 10px !important;
        }
        .icon {
          padding-top: 0.75rem;
          margin-right: 2px !important;
          img {
            height: 2rem !important;
          }
        }
      }
    }
  }
`;

const Step1 = ({ currStep, updateCurrStep }: {currStep: number, updateCurrStep: Function}) => {
  // -state var to control render of info - if true, render icon + title + copy. if false, render only title
  const [fullInfo, setFullInfo] = useState(false);
  // -ensure that the full info is only rendered for this step if this step is currently selected - using a delay to make sure that the info only changes when the keyframe animation gives the info section an opacity of 0 (rendering different info immediately results in a jarring switch of content right before the info fades to 0 opacity)
  useEffect(() => {
    setTimeout(
      () => (currStep === 1 ? setFullInfo(true) : setFullInfo(false)),
      500
    );
  }, [currStep]);
  // -attempt at using a state var to render the white side border once the info button is fully opened (hence the delay) - the delayed border render worked for the initial page load, but once the border rendered for a step, it was there until the page was refreshed again - need to debug
  // const [rightBorder, setRightBorder] = useState(false);
  // const delayedStates = (fullInfo, rightBorder) => {
  //   const fullInfoTO = setTimeout(() => setFullInfo(!fullInfo), 500);
  //   const rightBorderTO = setTimeout(() => {setRightBorder(!rightBorder); console.log('rightBorderTO fired off')}, 1000);
  // }
  const buttonClick = () => {
    updateCurrStep(1);
  };
  const info = {
    icon: Icon1?.default,
    title: {
      full: "Pumping to Treatment",
      piece1: "Pumping",
      piece2: "to Treatment"
    },
    copy: "Raw water is pumped out of one of our source lakes to a treatment plant."
  };

  const buttonClosed = `click-step click-step1 closed`;
  const buttonOpen = `click-step click-step1 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step1-line1 open`;
  const stepLineOneClosed = `step-line step1-line1`;

  return (
    <Wrap>
      <button
        className={currStep === 1 ? buttonOpen : buttonClosed}
        onClick={() => buttonClick()}>
        <div
          className={currStep === 1 ? infoWrapOpen : infoWrapClosed}
          style={!fullInfo ? { paddingTop: "1.5rem" } : { paddingTop: "0" }}>
          {fullInfo ? (
            <div
              className="full-info-wrap"
              style={
                currStep === 1
                  ? { opacity: 1, transition: "opacity .75s ease .25s" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <div className="icon">
                <img
                  className={fullInfo ? `open` : `closed`}
                  src={info.icon}
                  alt={info.title.full + ` icon`}
                  style={
                    currStep === 1
                      ? {
                          transform: "scale(1)",
                          transition: "transform .75s ease"
                        }
                      : {
                          transform: "scale(0)",
                          transition: "transform .75s ease"
                        }
                  }
                />
              </div>
              <div className="title-and-copy">
                <p className="title" style={fullInfo && { textAlign: "left" }}>
                  {info.title.full}
                </p>
                <p className="copy">{info.copy}</p>
              </div>
            </div>
          ) : (
            <div
              className="title title-only"
              style={
                currStep !== 1
                  ? { opacity: 1, transition: "opacity .75s ease" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
            </div>
          )}
        </div>
      </button>
      <div
        className={
          currStep === 1
            ? "circle-step circle-step1 open"
            : "circle-step circle-step1"
        }>
        1
      </div>
      <div className={currStep === 1 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  );
};

export default Step1;
