import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import InteractiveGraphicPiece from "./Piece";
import { RichTextQuery } from "wmk-rich-text";
import { ContentfulImageQuery } from "wmk-image";

const InteractiveGraphicPieces = ({
  clickedPiece,
  handleClick
}: {
  clickedPiece: string;
  handleClick: (s: string) => void;
}) => {
  const data: PiecesQuery = useStaticQuery(query);
  const pieces = data.pieces.edges.map((p) => p.node);
  // console.log('pieces: ', pieces);
  return (
    <>
      {pieces.map((piece, i) => {
        return (
          <InteractiveGraphicPiece
            key={piece.title + i}
            piece={piece}
            // hoveredPiece={hoveredPiece}
            // setHoveredPiece={setHoveredPiece}
            clickedPiece={clickedPiece}
            handleClick={handleClick}
          />
        );
      })}
    </>
  );
};

export default InteractiveGraphicPieces;

interface PieceImage extends ContentfulImageQuery {
  __typename: "ContentfulAsset";
  contentful_id: string;
}

export interface PieceRichText extends RichTextQuery {
  references: PieceImage[];
}

export interface PieceQuery {
  title: string;
  copy: PieceRichText;
}

interface PiecesQuery {
  pieces: {
    edges: {
      node: PieceQuery;
    }[];
  };
}

const query = graphql`
  query {
    pieces: allContentfulBlockBodyCopy(
      filter: { title: { regex: "/interactive graphic piece/i" } }
    ) {
      edges {
        node {
          title
          copy {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                ...BlockAssetFields
              }
            }
          }
        }
      }
    }
  }
`;
