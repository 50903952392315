import * as React from "react";
import styled from "styled-components";
import { Img, WmkImage } from "wmk-image";
import { RichText, RichTextReact } from "wmk-rich-text";
import { colors } from "../../../../vars/palette";
import { intOptions } from "../../../Blocks/interactiveOptions";
import { PieceQuery } from "./Pieces";

const PieceWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export interface PieceSpecs {
  x: string;
  y: string;
  z: number;
  width: string;
}

const BaseWrap = styled.div<{
  specs: PieceSpecs;
}>`
  position: absolute;
  .gatsby-image-wrapper,
  img {
    width: 100%;
    height: 100%;
  }
  transform: scale(1);
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease;
    cursor: pointer;
  }
  ${({ specs }) => {
    const width = specs.width;
    const x = specs.x;
    const y = specs.y;
    const z = specs.z;
    return `
      width: ${width};
      height: auto;
      left: ${x};
      top: ${y};
      z-index: ${z};
    `;
  }}
  &.active {
    transform: scale(1.1);
    &.base-tree {
      transform: scale(1.05);
    }
    &.base-hose {
      transform: scale(1.05);
    }
    &.base-sun {
      opacity: 1;
    }
  }
  &.darken {
    filter: brightness(50%);
  }
  &.base-sun {
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  &.base-tree {
    &:hover {
      transform: scale(1.05);
    }
  }
  &.base-hose {
    &:hover {
      transform: scale(1.05);
    }
  }
`;
const SecondaryWrap = styled.div<{ specs: PieceSpecs }>`
  position: absolute;
  width: 0;
  height: auto;
  z-index: -1;
  opacity: 0;
  ${({ specs }) => {
    const x = specs.x;
    const y = specs.y;
    return `    
      left: ${x};
      top: ${y};
    `;
  }}
  transition: opacity .3s ease, width .1s ease .5s, z-index .1s ease .5s !important;
  &.active {
    ${({ specs }) => {
      const width = specs.width;
      const x = specs.x;
      const y = specs.y;
      const z = specs.z;
      return `
        width: ${width};      
        left: ${x};
        top: ${y};
        z-index: ${z};
        opacity: 1;
        transition: left .1s ease, width .1s ease, z-index .1s ease, opacity .3s ease .2s !important;
      `;
    }}
  }
  .gatsby-image-wrapper,
  img {
    width: 100%;
    height: 100%;
  }
`;
const MessageWrap = styled.div<{ message: { x: string; y: string } }>`
  position: absolute;
  width: 0;
  height: auto;
  z-index: -1;
  opacity: 0;
  padding: 1rem 2rem;
  // left: 25%;
  // top: 12%;
  ${({ message }) => {
    const x = message.x;
    const y = message.y;
    return ` 
      left: ${x};
      top: ${y};         
    `;
  }}
  transition: opacity .3s ease, width .1s ease .5s, z-index .1s ease .5s !important;
  &.active {
    width: 58%;
    z-index: 100;
    ${({ message }) => {
      const x = message.x;
      const y = message.y;
      return ` 
        left: ${x};
        top: ${y};  
        opacity: 1;
        transition: left .1s ease, width .1s ease, z-index .1s ease, opacity .3s ease .2s !important;    
      `;
    }}
  }
  h2,
  p {
    font-weight: bold;
    color: ${colors.hex("coaccent")};
    opacity: 0.8;
  }
  p {
    margin-bottom: 0;
  }
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid ${colors.hex("coaccent")};
  border-radius: 10px;
  li {
    line-height: 20px;
  }
`;

const CloseOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  &.active {
    z-index: 98;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 4%;
  right: 2%;
  padding: 0rem 0.4rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.hex("coaccent")};
  transform: scaleX(1.2);
  background: rgba(255, 255, 255, 0.5);
`;

const InteractiveGraphicPiece = ({
  piece,
  // hoveredPiece,
  // setHoveredPiece,
  clickedPiece,
  handleClick
}: {
  piece: PieceQuery;
  clickedPiece: string;
  handleClick: (s: string) => void;
}) => {
  // console.log("piece: ", piece);
  const title = piece.title ? piece.title.split("- ")[1].toLowerCase() : "";
  // console.log("title: ", title);
  let baseWidth,
    baseX,
    baseY,
    baseZ,
    secondaryWidth,
    secondaryX,
    secondaryY,
    secondaryZ,
    messageX,
    messageY;
  switch (title) {
    case "trinity":
      baseWidth = "19%";
      baseX = "35%";
      baseY = "18%";
      baseZ = 6;
      messageX = "37%";
      messageY = "43%";
      break;
    case "sun":
      baseWidth = "17%";
      baseX = "54%";
      baseY = "10%";
      baseZ = 6;
      messageX = "25%";
      messageY = "40%";
      break;
    case "sprinkler":
      baseWidth = "11%";
      baseX = "12%";
      baseY = "20%";
      baseZ = 6;
      secondaryWidth = "97%";
      secondaryX = "2%";
      secondaryY = "-16%";
      secondaryZ = 3;
      messageX = "13%";
      messageY = "43%";
      break;
    case "planter":
      baseWidth = "32%";
      baseX = "6%";
      baseY = "38%";
      baseZ = 6;
      secondaryWidth = "28%";
      secondaryX = "10%";
      secondaryY = "34%";
      secondaryZ = 6;
      messageX = "37%";
      messageY = "40%";
      break;
    case "barrel":
      baseWidth = "17%";
      baseX = "46%";
      baseY = "48%";
      baseZ = 6;
      secondaryWidth = "32%";
      secondaryX = "40%";
      secondaryY = "-2%";
      secondaryZ = 6;
      messageX = "23%";
      messageY = "62%";
      break;
    case "tree":
      baseWidth = "97.5%";
      baseX = "3%";
      baseY = "22%";
      baseZ = 5;
      secondaryWidth = "16%";
      secondaryX = "78%";
      secondaryY = "82%";
      secondaryZ = 3;
      messageX = "15%";
      messageY = "50%";
      break;
    default:
      baseWidth = "0%";
      break;
  }
  const pieceSpecs = {
    basePiece: {
      width: baseWidth,
      x: baseX,
      y: baseY,
      z: baseZ
    },
    secondaryPiece: {
      width: secondaryWidth,
      x: secondaryX,
      y: secondaryY,
      z: secondaryZ
    },
    message: {
      x: messageX,
      y: messageY
    }
  };
  // get needed data for the images
  const richText = new RichText(piece.copy);
  const mainImage = piece.copy.references[0];
  const secImage = piece.copy.references[1];

  return (
    <PieceWrap>
      <BaseWrap
        specs={pieceSpecs.basePiece}
        onClick={() => handleClick(title)}
        className={
          clickedPiece === title
            ? `active base-${title}`
            : clickedPiece !== ""
            ? `darken base-${title}`
            : `base-${title}`
        }>
        <WmkImage image={new Img(mainImage)} />
      </BaseWrap>

      <SecondaryWrap
        specs={pieceSpecs.secondaryPiece}
        className={clickedPiece === title ? "active" : ""}>
        <WmkImage image={new Img(secImage)} />
        {/* <img src={secondaryUrl} alt={secondaryAlt} /> */}
      </SecondaryWrap>

      <MessageWrap
        message={pieceSpecs.message}
        className={clickedPiece === title ? "active" : ""}>
        {/* {documentToReactComponents(json, options)} */}
        <RichTextReact content={richText} options={intOptions} />
        <CloseButton onClick={() => handleClick("")}>X</CloseButton>
      </MessageWrap>
      <CloseOverlay
        onClick={() => handleClick("")}
        className={clickedPiece !== "" ? "active" : ""}
      />
    </PieceWrap>
  );
};

export default InteractiveGraphicPiece;
