import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from "../images/Ultraviolet_Disinfection.png"

const step7IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step7 {
    height: 8vw;
    top: 53%; 
    right: 23.3%;
    &.open {     
      z-index: 150 !important;
      border-left: 6px solid white !important;
      width: 50%;
      top: 28%; 
      right: 17%;
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 85% 0%); 
      transition: top .75s ease, right .5s ease .75s, width .5s ease 1.25s, clip-path .5s ease 1.25s, border-left .5s ease 1.25s;
    }
    &.closed {
      border-left: 0;
      width: 9.5vw;
      top: 53%;
      right: 23.3%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .5s ease, border-left .25s ease, clip-path .5s ease, right .5s ease .5s, top .75s ease 1s;
    }
    .info-wrap {
      .title {
        text-align: center;
        padding-top: .5rem;
        p {
          margin-bottom: 0;
          line-height: 16px;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: .5vw 4vw 1rem 3px;
        .icon {
          margin-left: .5rem;
          margin-top: .25rem;
          img {
            width: 9rem;
            height: 5rem;
          }
          img.open {
            animation: ${step7IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            margin-bottom: 5px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step7-line1 {
    width: 4px;
    top: 36%;
    right: 28%;
    height: 28%;
    transition: height .5s ease-in 1s;
    &.open {
      height: 0%;
      transition: height .75s ease;
    }
  }
  .step7-line2 {
    height: 4px;
    top: 36%;
    right: 23%;
    width: 5%;
    transition: width .5s ease-in .5s;
    &.open {
      width: 0%;
      transition: width .75s ease .5s;
    }
  }
  .circle-step7 {
    top: 33.5%;
    right: 21.5%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step7 {
      right: 21.6%;
      &.open {
        width: 55%;
        top: 24%;
      }
      .title-only {
        margin-top: -1vw;
      }
      .full-info-wrap {
        padding: 2px 4vw 1rem 3px !important;
        .icon {
          margin-left: 0rem !important;
          margin-top: .25rem !important;
          img {
            width: 9rem !important;
            height: 5rem !important;
          }
        }
        .title-and-copy {
          margin-top: -5px !important;
          .title {
            margin-bottom: 5px !important;
            font-size: 1.3rem !important;
          }
          .copy {
            line-height: 19px !important;
            font-size: 1rem !important;
          }
        }
      }  
    }
    .step7-line1 {
      right: 29%;
    }
    .step7-line2 {
      right: 22.3%;
      width: 7%;
    }
  }
  @media only screen and (max-width: 1007px) {
    .click-step7 {
      &.open {
        width: 65%;
      }
      .title-only {
        margin-top: -2vw;
        p {
          font-size: .8rem;
        }
      }
      .full-info-wrap {
        padding: 0px 5vw 1rem 0px !important;
        .icon {
          margin-left: 0rem !important;
          margin-top: .5rem !important;
          img {
            width: 7rem !important;
            height: 3.5rem !important;
          }
        }
        .title-and-copy {
          margin-top: -5px !important;
          .title {
            margin-bottom: 5px !important;
            font-size: 1.1rem !important;
          }
          .copy {
            line-height: 17px !important;
            font-size: .95rem !important;
          }
        }
      } 
    }
  }
  @media only screen and (max-width: 850px) {
    .click-step7 {
      &.open {
        width: 70%;
      }
      .title-only {
        p {
          font-size: .8rem;
        }
      }
      .full-info-wrap {
        padding: 0px 5vw 1rem 0px !important;
        .icon {
          margin-left: 0rem !important;
          margin-top: .5rem !important;
          img {
            width: 7rem !important;
            height: 3.5rem !important;
          }
        }
        .title-and-copy {
          margin-top: -5px !important;
          .title {
            margin-bottom: 5px !important;
            font-size: 1.1rem !important;
          }
          .copy {
            line-height: 17px !important;
            font-size: .95rem !important;
          }
        }
      } 
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step7 {
      right: 22.2%;
      &.open {
        width: 80%;
      }
      &.closed {
        top: 50%;
      }
      .title-only {
        margin-top: -4vw;
        p {
          font-size: .55rem !important;
          line-height: 10px !important;
        }
      }
      .full-info-wrap {
        padding: 0px 5vw 1rem 0px !important;
        .icon {
          margin-left: 0rem !important;
          margin-top: 0.1rem !important;
          img {
            width: 6rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          margin-top: -8px !important;
          .title {
            margin-bottom: 0px !important;
            font-size: 1rem !important;
          }
          .copy {
            line-height: 14px !important;
            font-size: .8rem !important;
          }
        }
      } 
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step7 {
      right: 20% !important;
      &.open {
        width: 83%;
        top: 20%;
        right: 15% !important;
      }
      &.closed {
        top: 40%;
      }
      .title-only {
        p {
          font-size: .65rem !important;
          line-height: 13px !important;
        }
      }
      .full-info-wrap {
        padding: 0px 5vw 1rem 0px !important;
        .icon {
          margin-left: 0rem !important;
          margin-top: 0.5rem !important;
          img {
            width: 6rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          margin-top: -9px !important;
          .title {
            margin-bottom: 0px !important;
            font-size: .9rem !important;
          }
          .copy {
            line-height: 13px !important;
            font-size: .75rem !important;
          }
        }
      } 
    }
  }
`
 
const Step7 = ({ currStep, updateCurrStep, newStep, closeSteps }) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => currStep === 7 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  const buttonClick = (fullInfo) => {
    updateCurrStep(7);
  }
  const info = {
    icon: Icon, 
    title: {
      full: 'Ultraviolet Disinfection',
      piece1: 'Ultraviolet',
      piece2: 'Disinfection'
    }, 
    copy: 'Ultraviolet light is used to disinfect the effluent by killing any remaining bacteria or harmful microorganisms.'
  };

  const buttonClosed = `click-step click-step7 closed`;
  const buttonOpen = `click-step click-step7 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step7-line1 open`;
  const stepLineOneClosed = `step-line step7-line1`;
  const stepLineTwoOpen = `step-line step7-line2 open`;
  const stepLineTwoClosed = `step-line step7-line2`;
  const circleClosed = `circle-step circle-step7`;
  const circleOpen = `circle-step circle-step7 open`;
  
  return (
    <Wrap>
      <button className={currStep === 7 ? buttonOpen : buttonClosed} onClick={() => buttonClick(fullInfo)}>
        <div className={currStep === 7 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {marginTop: '3rem'} : {marginTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 7 ? {opacity: 1, transition: 'opacity .75s ease .5s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 7 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
              </div>
          : <div className="title title-only" style={currStep !== 7 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
            </div>
          }
        </div>
      </button>      
      <div className={currStep === 7 ? circleOpen : circleClosed}>7</div>
      <div className={currStep === 7 ? stepLineOneOpen : stepLineOneClosed} />
      <div className={currStep === 7 ? stepLineTwoOpen : stepLineTwoClosed} />
    </Wrap>
  )
}
 
export default Step7