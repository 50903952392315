import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from "../images/Primary_Settling.png"

const step5IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step5 {
    height: 8vw;
    top: 67%; 
    right: 41.3%;
    &.open {     
      z-index: 150 !important;
      border-left: 6px solid white !important;
      width: 55%;
      top: 50%; 
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 85% 0%); 
      transition: top .75s ease, width .5s ease .75s, clip-path .5s ease .75s, border-left .5s ease .75s;
    }
    &.closed {
      border-left: 0;
      width: 9.5vw;
      top: 67%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .65s ease, border-left .5s ease, clip-path .65s ease, top 1s ease .65s;
    }
    .info-wrap {
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: .5vw 5vw 1rem 2px;
        .icon {
          margin-left: .5rem;
          margin-top: 1rem;
          img {
            width: 5rem;
            height: 4rem;
          }
          img.open {
            animation: ${step5IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            margin-bottom: 3px;
            font-size: 1.2rem;
          }
          .copy {
            text-align: left;
            line-height: 17px;
            font-size: .9rem;
          }
        }
      }  
    }
  }
  .step5-line1 {
    width: 4px;
    top: 61%;
    right: 46%;
    height: 12%;
    transition: height .25s ease-in .65s;
    &.open {
      height: 0%;
      transition: height .75s ease;
    }
  }
  .circle-step5 {
    top: 57%;
    right: 44.5%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step5 {
      right: 39.7%;
      &.open {
        width: 59%;
      }
      .title-only {
        margin-top: -.5rem;
        p {
          font-size: .9rem !important;
          line-height: 14px !important;          
        }
      }
      .full-info-wrap {
        padding: .1vw 5vw 1rem 2px !important;
        .icon {
          margin-left: .5rem !important;
          margin-top: 1rem !important;
          img {
            width: 4rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 0px !important;
            font-size: 1rem !important;
          }
          .copy {
            line-height: 12px !important;
            font-size: .75rem !important;
          }
        }
      }  
    }    
    .step5-line1 {
      right: 45.5%;
    }
  }
  @media only screen and (max-width: 900px) {
    .click-step5 {
      right: 40.2%;
      &.open {
        width: 59%;
      }
      .title-only {
        margin-top: -1rem;
        p {
          font-size: .6rem !important;
          line-height: 11px !important;
        }
      }
      .full-info-wrap {
        padding: 0 6vw 1rem 0px !important;
        .icon {
          margin-left: .5rem !important;
          margin-top: 1rem !important;
          img {
            width: 3rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          margin-top: -4px;
          .title {
            margin-bottom: -1px !important;
            font-size: .9rem !important;
          }
          .copy {
            line-height: 14px !important;
            font-size: .8rem !important;
          }
        }
      } 
    }    
    .step5-line1 {
      right: 46%;
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step5 {
      right: 40.2%;
      &.open {
        width: 59%;
      }
      .title-only {
        margin-top: -1rem;
        p {
          font-size: .6rem !important;
          line-height: 11px !important;
        }
      }
      .full-info-wrap {
        padding: 0 5vw 1rem 0px !important;
        .icon {
          margin-left: .5rem !important;
          margin-top: 1rem !important;
          img {
            width: 3rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          margin-top: -4px;
          .title {
            margin-bottom: -3px !important;
            font-size: .8rem !important;
          }
          .copy {
            line-height: 10px !important;
            font-size: .6rem !important;
          }
        }
      } 
    }    
    .step5-line1 {
      right: 46%;
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step5 {
      right: 36.8%;
      &.open {
        top: 36%;
        width: 62%;
      }
      .title-only {
        margin-top: -1rem;
        p {
          font-size: .7rem !important;
          line-height: 11px !important;
        }
      }
      .full-info-wrap {
        padding: 0 5vw 1rem 0px !important;
        .icon {
          margin-left: .25rem !important;
          margin-top: 1rem !important;
          img {
            width: 3rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          margin-top: -5px;
          .title {
            margin-bottom: -3px !important;
            font-size: .7rem !important;
          }
          .copy {
            line-height: 10px !important;
            font-size: .6rem !important;
          }
        }
      } 
    }    
    .step5-line1 {
      right: 46%;
    }
  }
`
 
const Step5 = ({ currStep, updateCurrStep, newStep, closeSteps }) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => currStep === 5 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  const buttonClick = (fullInfo) => {
    updateCurrStep(5);
  }
  const info = {
    icon: Icon, 
    title: {
      full: 'Primary Settling',
      piece1: 'Primary',
      piece2: 'Settling'
    }, 
    copy: 'Large circular tanks, called primary clarifiers, allow the water to separate from the solid particles, which sink to the bottom and are continuously pumped back to the aeration tanks.  At this step, the effluent (treated wastewater) is over 90% treated.'
  };

  const buttonClosed = `click-step click-step5 closed`;
  const buttonOpen = `click-step click-step5 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step5-line1 open`;
  const stepLineOneClosed = `step-line step5-line1`;
  const circleClosed = `circle-step circle-step5`;
  const circleOpen = `circle-step circle-step5 open`;
  
  return (
    <Wrap>
      <button className={currStep === 5 ? buttonOpen : buttonClosed} onClick={() => buttonClick(fullInfo)}>
        <div className={currStep === 5 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {marginTop: '2.5rem'} : {marginTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 5 ? {opacity: 1, transition: 'opacity .75s ease .25s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 5 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
              </div>
          : <div className="title title-only" style={currStep !== 5 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
            </div>
          }
        </div>
      </button>      
      <div className={currStep === 5 ? circleOpen : circleClosed}>5</div>
      <div className={currStep === 5 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  )
}
 
export default Step5