import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../ui/colors";
// import { Container } from "react-bootstrap"
import BGImage from "./images/wastewaterBackground.png";
import Step1 from "./WastewaterSteps/Step1";
import Step2 from "./WastewaterSteps/Step2";
import Step3 from "./WastewaterSteps/Step3";
import Step4 from "./WastewaterSteps/Step4";
import Step5 from "./WastewaterSteps/Step5";
import Step6 from "./WastewaterSteps/Step6";
import Step7 from "./WastewaterSteps/Step7";
import Step8 from "./WastewaterSteps/Step8";

const WastewaterTreatment = () => {
  const [currStep, setCurrStep] = useState(0);
  const updateCurrStep = (stepNum) => {
    return stepNum === currStep ? closeSteps() : newStep(stepNum);
  };
  const newStep = (stepNum) => {
    setCurrStep(stepNum);
  };
  const closeSteps = () => {
    setCurrStep(0);
  };

  return (
    <Wrap>
      <img
        className="background"
        src={BGImage}
        alt="infographic depicting the water treatment plant from above"
      />
      <div className="click-areas-wrap">
        <Step1
          currStep={currStep}
          updateCurrStep={updateCurrStep}
          newStep={newStep}
          closeSteps={closeSteps}
        />
        <Step2
          currStep={currStep}
          updateCurrStep={updateCurrStep}
          newStep={newStep}
          closeSteps={closeSteps}
        />
        <Step3
          currStep={currStep}
          updateCurrStep={updateCurrStep}
          newStep={newStep}
          closeSteps={closeSteps}
        />
        <Step4
          currStep={currStep}
          updateCurrStep={updateCurrStep}
          newStep={newStep}
          closeSteps={closeSteps}
        />
        <Step5
          currStep={currStep}
          updateCurrStep={updateCurrStep}
          newStep={newStep}
          closeSteps={closeSteps}
        />
        <Step6
          currStep={currStep}
          updateCurrStep={updateCurrStep}
          newStep={newStep}
          closeSteps={closeSteps}
        />
        <Step7
          currStep={currStep}
          updateCurrStep={updateCurrStep}
          newStep={newStep}
          closeSteps={closeSteps}
        />
        <Step8
          currStep={currStep}
          updateCurrStep={updateCurrStep}
          newStep={newStep}
          closeSteps={closeSteps}
        />
        <div className="off-click" onClick={() => closeSteps()} />
      </div>
      <div className="solids-text-wrap">
        <div className="border-div" />
        <div className="text">
          <h4>SOLIDS PROCESSING</h4>
          <p>
            The solid materials removed during treatment are processed through a
            belt press or centrifuge to remove excess water before being
            transported in a covered truck to the landfill.
          </p>
        </div>
      </div>
      <div className="odor-text-wrap">
        <div className="border-div" />
        <div className="text">
          <h4>ODOR CONTROL</h4>
          <p>
            Incorporated during the treatment process to be a good neighbor to
            the surrounding community.
          </p>
        </div>
      </div>
    </Wrap>
  );
};

export default WastewaterTreatment;

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 51vw;
  img.background {
    width: 100%;
    height: 51vw;
  }
  .click-areas-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 54vw;
    .info-wrap {
      .title,
      .title-only {
        line-height: 18px;
        font-size: 15px;
        font-weight: bold;
      }
      .copy {
        font-size: 0.9rem;
      }
    }
    .click-step {
      position: absolute;
      z-index: 100;
      background: #d8e9ed;
      color: ${colors.blue.getHex()};
      border: 0;
    }
    .step-line {
      background: #36acdd;
      position: absolute;
      z-index: 0;
    }
    .circle-step {
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      background: #b0d6e0;
      font-size: 2rem;
      font-weight: bold;
      border-radius: 100%;
      width: auto;
      padding: 0px 15px;
      position: absolute;
      z-index: 10;
      transition: all 1s ease;
      &.open {
        font-size: 0rem !important;
        padding: 0 !important;
        transition: all 1s ease 1s;
      }
    }
    .off-click {
      position: absolute;
      top: 0;
      z-index: 15;
      width: 100%;
      height: 100%;
      // background: green;
    }
  }
  .solids-text-wrap,
  .odor-text-wrap {
    position: absolute;
    z-index: 0;
    .text {
      position: absolute;
      z-index: 50;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 3vw;
      background: ${colors.white.getHex()};
      color: ${colors.blue.getHex()};
      clip-path: polygon(5% 0, 100% 0, 100% 83%, 95% 100%, 0 100%, 0 17%);
      h4 {
        font-size: 1.2vw;
        font-weight: bold;
        margin-bottom: 0.5vw;
      }
      p {
        font-size: 0.94vw;
        font-weight: bold;
      }
    }
    .border-div {
      position: absolute;
      top: -0.5vw;
      left: -0.5vw;
      z-index: 40;
      background: rgba(255, 255, 255, 0.5);
      clip-path: polygon(5% 0, 100% 0, 100% 83%, 95% 100%, 0 100%, 0 17%);
    }
  }
  .solids-text-wrap {
    top: 3vw;
    left: 66vw;
    .text {
      width: 22vw;
      height: 12vw;
      h4 {
        margin-top: 1.5vw;
      }
    }
    .border-div {
      width: 23vw;
      height: 13vw;
    }
  }
  .odor-text-wrap {
    top: 37vw;
    left: 17vw;
    .text {
      width: 20vw;
      height: 10vw;
      h4 {
        margin-top: 1.5vw;
      }
    }
    .border-div {
      width: 21vw;
      height: 11vw;
    }
  }

  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step {
      height: 17%;
      &.closed {
        width: 12%;
      }
    }
    .circle-step {
      font-size: 1rem !important;
      padding: 2px 10px !important;
    }
    .solids-text-wrap,
    .odor-text-wrap {
      h4 {
        margin-top: 3.5vw;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step {
      height: 27%;
      &.closed {
        width: 19%;
      }
    }
    .circle-step {
      font-size: 0.8rem !important;
      padding: 1px 8px !important;
    }
    .solids-text-wrap,
    .odor-text-wrap {
      display: none;
    }
  }
  @media only screen and (max-width: 475px) {
    .click-step {
      height: 30%;
      &.closed {
        width: 20%;
      }
    }
    .circle-step {
      font-size: 0.7rem !important;
      padding: 0 5px !important;
    }
  }
`;
