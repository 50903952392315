import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import WidgetSubTitle from "../../../../../common/typography/WidgetSubTitle";
//import { ListItemColumns }
import { colors } from "../../../../../../vars/palette";
import { BoardOfDirectorsFields } from "../../../../../../node/fragments/NodeBODFields";

const MemberWrap = styled(Row)`
  padding-bottom: 3vh;
  & > .col {
    display: flex;
    justify-content: center;
    color: ${colors.hex("white")};
    // next two lines fix safari's issues with flexbox (without them, the names and cities overlap each other)
    -webkit-flex: 0;
    flex-shrink: 0;
  }
`;

const BoardCurrent = ({ members }: { members: BoardOfDirectorsFields[] }) => {
  members.sort((a, b) => a.sortName.localeCompare(b.sortName));
  return (
    Array.isArray(members) && (
      <Container fluid style={{ background: colors.hex("secondary") }}>
        <Container>
          <Row>
            <Col>
              <WidgetSubTitle style={{ padding: `3vh 0 6vh 0` }}>
                Board Members
              </WidgetSubTitle>
            </Col>
          </Row>
          <Row>
            {members.map((mem, i) => {
              const { name, job, org } = mem;
              return (
                <Col key={name + i} sm={12} md={6} lg={4}>
                  <MemberWrap className="flex-column">
                    <Col>
                      <strong>{name}</strong>
                    </Col>
                    <Col>
                      {job ? job + ", " : ""}
                      {org}
                    </Col>
                  </MemberWrap>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
    )
  );
};

export default BoardCurrent;
