import React from "react";
import { WmkLink } from "wmk-link";
import BulletDrop from "../../../flexible/blocks/BlockBody/Bullets/BulletDrop";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";
import { Event } from "../../../../classes/Events";
import moment from "moment";

const ListWrap = styled.div`
  margin-left: 3rem;
  .list-item {
    margin: 1rem 0;
    .drop-wrap {
      margin-right: 1rem;
      .bullet-drop-stroke {
        fill: ${colors.hex("secondary")};
      }
    }
    .link-wrap {
      color: ${colors.hex("accent")};
      a {
        color: ${colors.hex("accent")};
      }
    }
  }
`;

const H3Wrap = styled.h3`
  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
`;
const OldUpcoming = ({ eventData }: { eventData: Event[] }) => {
  // console.log("eventData: ", eventData);

  return (
    <>
      <H3Wrap>
        Check out what’s going on at UTRWD and other related events of interest:
      </H3Wrap>
      <ListWrap>
        {eventData.map((event, i) => {
          // create link
          const link = event?.link ? (
            <span className="link-wrap">
              [<WmkLink to={event?.link}>{event?.link}</WmkLink>]
            </span>
          ) : null;
          const _date = moment(event?.date);
          return event.linkAllText ? (
            <div className="list-item" key={event?.title + i}>
              <span className="drop-wrap">
                <BulletDrop />
              </span>
              <WmkLink to={event?.link} target="blank">
                {event?.title + `, ` + event?.date.toDateString()}
              </WmkLink>
            </div>
          ) : (
            <div className="list-item" key={event?.title + i}>
              <span className="drop-wrap">
                <BulletDrop />
              </span>
              {event?.title +
                `, ` +
                _date.format("MMMM D, YYYY") +
                (link ? `, ` : ``)}
              {link}
            </div>
          );
        })}
      </ListWrap>
    </>
  );
};

export default OldUpcoming;
