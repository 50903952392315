import React from "react";
import styled from "styled-components";
import WastewaterTreatment from "../component-utrwd-treatment-graphic/WastewaterTreatment";

const Wrap = styled.div``;

const WastewaterTreatmentDeliveryPage = () => {
  return (
    <Wrap>
      <WastewaterTreatment />
    </Wrap>
  );
};

export default WastewaterTreatmentDeliveryPage;
