import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
// import Icon5 from "../images/icon-step5.png"

const Icon5 = require("../images/icon-step5.png");

const step5IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step5 {
    height: 9vw;
    top: 13%;
    right: 45%;
    &.open {     
      z-index: 150 !important;
      border-left: 6px solid white !important;
      width: 30%;
      top: 31%; 
      clip-path: polygon(0% 0%, 0% 100%, 85% 100%, 100% 0%);
      transition: top .75s ease, width .5s ease .75s, clip-path .5s ease .75s, border-left .5s ease .75s;
    }
    &.closed {
      border-left: 0;
      width: 10.5vw;
      top: 13%;
      clip-path: polygon(0 0, 50% 100%, 51% 100%, 100% 0);
      transition: width .65s ease, border-left .5s ease, clip-path .65s ease, top 1s ease .65s;
    }
    // .right-border-div {
    //   height: 100%;
    //   width: 5px;
    //   background: white;
    // }
    .info-wrap {
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 5px 10px 1rem .5rem;
        .icon {
          margin-right: 1rem;
          margin-top: .75rem;
          img {
            width: 5rem;
            height: 4rem;
          }
          img.open {
            animation: ${step5IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 65%;
          margin-right: 5px;
          .title {
            margin-bottom: 5px;
            font-size: 1.4rem;
          }
          .copy {
            text-align: left;
            line-height: 18px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step5-line1 {
    width: 4px;
    bottom: 63%;
    right: 50.3%;
    height: 12%;
    transition: height .25s ease-in .65s;
    &.open {
      height: 1%;
      transition: height .75s ease;
    }
  }
  .circle-step5 {
    top: 37%;
    right: 49%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step5 {
      top: 9% !important;
      right: 41.8%;
      &.open {
        width: 45%;
        top: 30% !important; 
      }
      &.closed {
        top: 9% !important;
      }
    }
    .step5-line1 {
      right: 50.5%; 
      height: 15%;
      transition: height .25s ease-in .65s;
      &.closed {
        height: 0%;
      }
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step5 {
      top: 9% !important;
      right: 42.4%;
      &.open {
        width: 53%;
        top: 30% !important; 
      }
      &.closed {
        top: 9% !important;
      }
      .title-only {
        font-size: .7rem !important;
        line-height: 15px !important;
        margin-top: 0vw !important;
      }
      .full-info-wrap {
        padding-top: 1% !important;
        .title {
          font-size: .9rem !important;
          margin-bottom: 0px !important;
        }
        .copy {
          font-size: .75rem !important;
          line-height: 15px !important;
        }
        .icon {
          padding-top: 0rem;
          margin-right: 15px !important;
          margin-left: 5px !important;
          img {
            height: 3rem !important;
            width: 4rem !important;
            padding-right: 0 !important;
          }
        }
      }
    }
    .step5-line1 {
      right: 50.5%; 
      height: 15%;
      transition: height .25s ease-in .65s;
      &.closed {
        height: 0%;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step5 {
      top: 9% !important;
      right: 42.4%;
      &.open {
        width: 56%;
        top: 30% !important; 
      }
      &.closed {
        top: 9% !important;
      }
      .title-only {
        font-size: .7rem !important;
        line-height: 15px !important;
        margin-top: 0vw !important;
      }
      .full-info-wrap {
        padding: .5% 10px 1rem .25rem !important;
        .title {
          font-size: .8rem !important;
          margin-bottom: -1px !important;
        }
        .copy {
          font-size: .7rem !important;
          line-height: 12px !important;
        }
        .icon {
          img {
            height: 2.5rem !important;
            width: 3.5rem !important;
            padding-right: 0 !important;
          }
        }
      }
    }
    .step5-line1 {
      right: 51%; 
      height: 15%;
      transition: height .25s ease-in .65s;
      &.closed {
        height: 0%;
      }
    }
  }
`
 
const Step5 = ({
  currStep,
  updateCurrStep
}: {
  currStep: number;
  updateCurrStep: Function;
}) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => currStep === 5 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  // const [rightBorder, setRightBorder] = useState(false);
  // const delayedStates = (fullInfo, rightBorder) => {
  //   const fullInfoTO = setTimeout(() => setFullInfo(!fullInfo), 500);
  //   const rightBorderTO = setTimeout(() => {setRightBorder(!rightBorder); console.log('rightBorderTO fired off')}, 1000);
  // }
  const buttonClick = () => {
    updateCurrStep(5);
  }
  const info = {
    icon: Icon5?.default, 
    title: {
      full: 'Filtration'
    }, 
    copy: 'The water travels through a filter of activated carbon and sand to remove any remaining impurities.'
  };

  const buttonClosed = `click-step click-step5 closed`;
  const buttonOpen = `click-step click-step5 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step5-line1 open`;
  const stepLineOneClosed = `step-line step5-line1`;
  
  return (
    <Wrap>
      <button className={currStep === 5 ? buttonOpen : buttonClosed} onClick={() => buttonClick()}>
        <div className={currStep === 5 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {marginTop: '-2rem'} : {marginTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 5 ? {opacity: 1, transition: 'opacity .75s ease .25s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 5 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
              </div>
          : <div className="title title-only" style={currStep !== 5 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.full}</p>
            </div>
          }
        </div>
        {/* {rightBorder && <div className="right-border-div" />} */}
      </button>      
      <div className={currStep === 5 ? "circle-step circle-step5 open" : "circle-step circle-step5"}>5</div>
      <div className={currStep === 5 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  )
}
 
export default Step5