import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
// import Icon4 from "../images/icon-step4.png"

const Icon4 = require("../images/icon-step4.png");

const step4IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`;

const Wrap = styled.div`
  .click-step4 {
    height: 9vw;
    top: 41%;
    &.open {
      z-index: 150 !important;
      border-right: 6px solid white !important;
      width: 30%;
      left: 58%;
      clip-path: polygon(0% 0%, 15% 100%, 100% 100%, 100% 0%);
      transition: left 0.75s ease, width 0.5s ease 0.75s,
        clip-path 0.5s ease 0.75s, border-right 0.5s ease 0.75s;
    }
    &.closed {
      border-right: 0;
      width: 10.5vw;
      left: 74%;
      clip-path: polygon(0 0, 50% 100%, 51% 100%, 100% 0);
      transition: width 0.65s ease, border-right 0.5s ease, clip-path 0.65s ease,
        left 0.5s ease 0.65s;
    }
    // .right-border-div {
    //   height: 100%;
    //   width: 5px;
    //   background: white;
    // }
    .info-wrap {
      .title-only {
        p {
          margin-bottom: -5px;
        }
      }
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 0.5rem 1px 1rem 2.5rem;
        .icon {
          margin-right: 1rem;
          margin-top: 5px;
          img {
            width: 4rem;
            height: 4rem;
          }
          img.open {
            animation: ${step4IconGrow} 0.75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 18px;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .step4-line1 {
    height: 4px;
    top: 47.5%;
    left: 64%;
    width: 14%;
    transition: width 0.25s ease-in 0.65s;
    &.open {
      width: 1%;
      transition: width 0.75s ease;
    }
  }
  .circle-step4 {
    top: 45%;
    right: 35%;
  }

  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step4 {
      top: 41%;
      &.open {
        width: 45%;
        left: 53%;
      }
      &.closed {
        left: 74%;
      }
      .full-info-wrap {
        padding-top: 1% !important;
        .title {
          font-size: 1.2rem !important;
        }
        .copy {
          font-size: 0.9rem !important;
        }
        .icon {
          padding-top: 0.5rem;
          img {
            height: 4rem !important;
            padding-right: 0.5rem;
          }
        }
      }
    }
    .step4-line1 {
      right: 19%;
      width: 15%;
      transition: width 0.25s ease-in 0.65s;
      &.closed {
        width: 0%;
      }
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step4 {
      top: 41%;
      &.open {
        width: 46%;
        left: 53%;
      }
      &.closed {
        left: 74%;
      }
      .title-only {
        font-size: 0.7rem !important;
        line-height: 15px !important;
        margin-top: 0vw !important;
      }
      .full-info-wrap {
        padding: 0rem 0 1rem 1.75rem !important;
        .title {
          font-size: 1rem !important;
          margin-bottom: 0px !important;
        }
        .copy {
          font-size: 0.8rem !important;
          line-height: 14px !important;
        }
        .icon {
          padding-top: 0.5rem;
          margin-right: 0.4rem !important;
          img {
            height: 2.5rem !important;
            width: 2.5rem !important;
            padding-right: 0;
            margin-left: -10px;
          }
        }
      }
    }
    .step4-line1 {
      right: 19%;
      width: 15%;
      transition: width 0.25s ease-in 0.65s;
      &.closed {
        width: 0%;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step4 {
      top: 41%;
      &.open {
        width: 48%;
        left: 53%;
      }
      &.closed {
        left: 74%;
      }
      .full-info-wrap {
        padding: 0 0 1rem 2rem !important;
        .title {
          font-size: 0.75rem !important;
          margin-bottom: -3px !important;
        }
        .copy {
          font-size: 0.6rem !important;
          line-height: 12px !important;
        }
        .icon {
          padding-top: 0.5rem;
          margin-right: 0rem !important;
          img {
            height: 2.5rem !important;
            width: 2.5rem !important;
            padding-right: 0.25rem;
            margin-left: -15px;
          }
        }
      }
    }
    .step4-line1 {
      top: 49%;
      right: 19%;
      width: 15%;
      transition: width 0.25s ease-in 0.65s;
      &.closed {
        width: 0%;
      }
    }
  }
`;

const Step4 = ({
  currStep,
  updateCurrStep
}: {
  currStep: number;
  updateCurrStep: Function;
}) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(
      () => (currStep === 4 ? setFullInfo(true) : setFullInfo(false)),
      750
    );
  }, [currStep]);
  // const [rightBorder, setRightBorder] = useState(false);
  // const delayedStates = (fullInfo, rightBorder) => {
  //   const fullInfoTO = setTimeout(() => setFullInfo(!fullInfo), 500);
  //   const rightBorderTO = setTimeout(() => {setRightBorder(!rightBorder); console.log('rightBorderTO fired off')}, 1000);
  // }
  const buttonClick = () => {
    updateCurrStep(4);
  };
  const info = {
    icon: Icon4?.default,
    title: {
      full: "Ozone Treatment",
      piece1: "Ozone",
      piece2: "Treatment"
    },
    copy: "Ozone gas is injected as a powerful disinfectant to kill harmful microorganisms and improve the water’s odor and taste."
  };

  const buttonClosed = `click-step click-step4 closed`;
  const buttonOpen = `click-step click-step4 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step4-line1 open`;
  const stepLineOneClosed = `step-line step4-line1`;

  return (
    <Wrap>
      <button
        className={currStep === 4 ? buttonOpen : buttonClosed}
        onClick={() => buttonClick()}>
        <div
          className={currStep === 4 ? infoWrapOpen : infoWrapClosed}
          style={!fullInfo ? { marginTop: "-2rem" } : { marginTop: "0" }}>
          {fullInfo ? (
            <div
              className="full-info-wrap"
              style={
                currStep === 4
                  ? { opacity: 1, transition: "opacity .75s ease .25s" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <div className="icon">
                <img
                  className={fullInfo ? `open` : `closed`}
                  src={info.icon}
                  alt={info.title.full + ` icon`}
                  style={
                    currStep === 4
                      ? {
                          transform: "scale(1)",
                          transition: "transform .75s ease"
                        }
                      : {
                          transform: "scale(0)",
                          transition: "transform .75s ease"
                        }
                  }
                />
              </div>
              <div className="title-and-copy">
                <p className="title" style={fullInfo && { textAlign: "left" }}>
                  {info.title.full}
                </p>
                <p className="copy">{info.copy}</p>
              </div>
            </div>
          ) : (
            <div
              className="title-only"
              style={
                currStep !== 4
                  ? { opacity: 1, transition: "opacity .75s ease" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
            </div>
          )}
        </div>
        {/* {rightBorder && <div className="right-border-div" />} */}
      </button>
      <div
        className={
          currStep === 4
            ? "circle-step circle-step4 open"
            : "circle-step circle-step4"
        }>
        4
      </div>
      <div className={currStep === 4 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  );
};

export default Step4;
