import React, { useEffect, useState } from "react";
import get from "lodash/get";

export interface LocationResult {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  geometry: {
    bounds: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
    location: {
      lat: number;
      lng: number;
    };
    location_type: string;
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
  };
  place_id: string;
  types: string[];
}

export interface LocationResponse {
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  results: LocationResult[];
  status: string;
}

export type LocErrorMsg = "You must allow browser location settings in order to view your recommendation.";

export interface LocationContextData {
  userLocation: LocationResponse | { error: LocErrorMsg };
  start: boolean;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  doLocCheck: () => void;
  userRegion: FoundLocation | LocErrorMsg;
  isLoading: boolean;
  hasLoaded: boolean;
  setHasLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationContext = React.createContext<LocationContextData>(undefined);

const LocationProvider = ({ children }: { children: React.ReactNode }) => {
  const [userLocation, setUserLocation] = useState<
    LocationResponse | { error: LocErrorMsg }
  >();
  const [startLocating, setStartLocating] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userRegion, setUserRegion] = useState<FoundLocation>();
  const [locCheck, setLocCheck] = useState<Geolocation>();
  const error =
    "You must allow browser location settings in order to view your recommendation.";
  const doLocCheck = () => {
    if (locCheck) {
      setIsLoading(true);
      setStartLocating(true);
      locCheck.getCurrentPosition((pos) => {
        const lat = get(pos, "coords.latitude"); //33.033;
        const long = get(pos, "coords.longitude"); //-97.06;
        fetch(
          `https://api.watermarkadvertising.net/utrwd_geo.php?lat=${lat}&long=${long}`
        )
          .then((res) => res.json())
          .then((res) => {
            setUserLocation(res);
            setUserRegion(checkLocation(res.results));
            setIsLoading(false);
          });
      });
    } else {
      setUserLocation({
        error
      });
      setLocCheck(undefined);
    }
  };
  useEffect(() => {
    setLocCheck(navigator.geolocation);
  }, []);
  return (
    <LocationContext.Provider
      value={{
        userLocation: locCheck ? userLocation : { error },
        start: startLocating,
        setStart: setStartLocating,
        doLocCheck,
        userRegion: locCheck ? userRegion : error,
        isLoading,
        hasLoaded,
        setHasLoaded,
        setIsLoading
      }}>
      {children}
    </LocationContext.Provider>
  );
};

const useLocationState = () => {
  const context = React.useContext(LocationContext);
  if (context === undefined) {
    throw new Error("useLocationState must be used within a LocationProvider");
  }
  return context;
};

export interface FoundLocation {
  region: string;
  codes: number[];
  zip: number;
}

const checkLocation = (results: LocationResult[]) => {
  let found: FoundLocation = undefined;
  if (results && results.length) {
    results.forEach((res) => {
      const formatted_address = res.formatted_address;
      zipCodes.forEach((r) => {
        const { codes } = r;
        if (codes && codes.length) {
          codes.map((zip) => {
            if (formatted_address.indexOf(zip + "") > -1) {
              found = { ...r, zip };
            }
            return found;
          });
        }
      });
    });
  }
  return found;
};

const zipCodes = [
  {
    region: "Lewisville",
    codes: [
      75010,
      75019,
      75027,
      75028,
      75029,
      75056,
      75057,
      75065,
      75067,
      75077,
      75287,
      76201,
      76210
    ]
  },
  {
    region: "Providence Village",
    codes: [
      75009,
      75068,
      75068,
      75078,
      76201,
      76202,
      76204,
      76206,
      76208,
      76209,
      76227,
      76258,
      76266,
      76272
    ]
  },
  {
    region: "Argyle",
    codes: [75022, 76203, 76205, 76207, 76226, 76247, 76249, 76259, 76262]
  }
];

export { LocationProvider, useLocationState, LocationContext, checkLocation };
