import React from "react";
import { Row, Col } from "react-bootstrap";
import NewsExcerptTitle from "../NewsExcerptTitle";
import NewsDate from "../NewsDate";
import { WmkLink } from "wmk-link";
import DefaultImage from "../../../common/DefaultImage";
import { isENewsletter } from "../NewsExcerpts/NewsExcerpt";
import { colors } from "../../../../vars/palette";
import { WmkImage } from "wmk-image";
import { NewsPost } from "../../../../classes/News";

const FeaturedMain = ({ newsItem }: { newsItem: NewsPost }) => {
  const { image, title, date, content, tags, link, path } = newsItem;
  const isENews = isENewsletter(tags);
  return (
    <Row className="flex-column" style={{ position: "relative", zIndex: 999 }}>
      <Col>
        <WmkLink
          to={isENews ? link : path}
          target={isENews ? "blank" : undefined}>
          {image ? (
            <WmkImage image={image} crop="thumb" />
          ) : (
            <DefaultImage size="long" />
          )}
        </WmkLink>
      </Col>

      <Col>
        <Row
          style={{
            borderBottom: `1px solid ${colors.hex("primary")}`,
            alignItems: "center",
            //padding: 0,
            margin: 0
          }}>
          <Col>
            <NewsExcerptTitle
              path={isENews ? undefined : path}
              url={isENews && link ? link : undefined}>
              {title}
            </NewsExcerptTitle>
          </Col>
          <Col sm="auto">
            <NewsDate>{date.toLocaleDateString()}</NewsDate>
          </Col>
        </Row>
        <Row style={{ margin: 0 }}>
          <Col style={{ color: colors.hex("primary") }}>
            {content.excerpt(300)}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FeaturedMain;
