import React from "react";
import styled from "styled-components";
import { colors } from "../../ui/colors";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  div {
    position: relative;
    top: -80px;
    border: 3px solid ${colors.cyan.getHex()};
    background: ${colors.white.getHex()};
    padding: 5rem;
  }
`;

const DisposalEventsBox = () => {
  return (
    <Wrap>
      <div>
        There are no events scheduled at this time. Please check back with us
        again.
      </div>
    </Wrap>
  );
};

export default DisposalEventsBox;
