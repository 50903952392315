import React from "react";
import { WmkLink } from "wmk-link";
import styled from "styled-components";
import { colors } from "../../../vars/palette";

const ExcerptWrap = styled.h5`
  margin: 0;
  transition: all 0.3s ease;
  font-weight: bold;
  &.white {
    color: ${colors.hex("white")};
    &:hover {
      color: ${colors.hex("tertiary")};
    }
  }
  &.blue {
    color: ${colors.hex("primary")};
    &:hover {
      color: ${colors.hex("secondary")};
    }
  }
`;

const NewsExcerptTitle = ({
  children,
  reverse,
  path,
  url,
  style
}: {
  children: string;
  reverse?: boolean;
  path?: string;
  url?: string;
  style?: React.CSSProperties;
}) => {
  const color = reverse ? "white" : "blue";
  const classes = ["news-excerpt-title"];
  classes.push(color);
  return (
    <WmkLink
      to={url ? url : path}
      target={url ? "blank" : undefined}
      style={{ textDecoration: "none" }}>
      <ExcerptWrap className={classes.join(" ")} style={style}>
        {children.toUpperCase()}
      </ExcerptWrap>
    </WmkLink>
  );
};

export default NewsExcerptTitle;
