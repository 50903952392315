import * as React from "react";
import { Img, ContentfulImageQuery, WmkImage } from "wmk-image";
import { graphql, useStaticQuery } from "gatsby";

const DefaultImage = ({
  size,
  style
}: {
  size: "square" | "long" | "big" | "thumb";
  style?: React.CSSProperties;
}) => {
  const data: DefaultImageQuery = useStaticQuery(query);
  return size === "square" ? (
    <WmkImage image={new Img(data.images.square)} style={style} />
  ) : size === "thumb" ? (
    <WmkImage image={new Img(data.images.thumb)} style={style} />
  ) : (
    <WmkImage image={new Img(data.images.long)} style={style} />
  );
};

export default DefaultImage;

interface DefaultImageQuery {
  images: {
    square: ContentfulImageQuery;
    thumb: ContentfulImageQuery;
    long: ContentfulImageQuery;
  };
}

const query = graphql`
  {
    images: contentfulGlobal(title: { eq: "UTRWD Footer Options" }) {
      square: homeHeroThumbnail {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(width: 600, height: 600)
      }
      thumb: homeHeroThumbnail {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(width: 370, height: 200)
      }
      long: homeHeroThumbnail {
        title
        file {
          url
          contentType
        }
        gatsbyImageData
      }
    }
  }
`;
