import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Button from "../../common/Button";
import SingleContact from "./SingleContact";
import { colors } from "../../../vars/palette";
import { ContactFields } from "../../../node/fragments/NodeContactFields";

const Wrap = styled(Container)`
  padding: 0;
  .intro-wrap {
    margin: 2rem 0;
    text-align: center;
    h2 {
      color: ${colors.hex("secondary")};
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 0;
    }
    p {
      font-size: 0.9rem;
    }
  }
  .contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }
`;

interface ContactPageQuery {
  allContentfulContacts: {
    edges: { node: ContactFields }[];
  };
  contentfulGlobalContactIntroLine1TextNode: {
    contactIntroLine1: string;
  };
  contentfulGlobalContactIntroLine2TextNode: {
    contactIntroLine2: string;
  };
  contentfulGlobalContactIntroLine3TextNode: {
    contactIntroLine3: string;
  };
  contentfulGlobalContactIntroLine4TextNode: {
    contactIntroLine4: string;
  };
}

const ContactPage = () => {
  const contactData: ContactPageQuery = useStaticQuery(graphql`
    query {
      allContentfulContacts(sort: { fields: sortOrder }) {
        edges {
          node {
            ...NodeContactFields
          }
        }
      }
      contentfulGlobalContactIntroLine1TextNode {
        contactIntroLine1
      }
      contentfulGlobalContactIntroLine2TextNode {
        contactIntroLine2
      }
      contentfulGlobalContactIntroLine3TextNode {
        contactIntroLine3
      }
      contentfulGlobalContactIntroLine4TextNode {
        contactIntroLine4
      }
    }
  `);

  const contacts = contactData.allContentfulContacts.edges.map((p) => p.node);

  // console.log(contacts)

  // console.log(contactData)

  const intro = {
    line1:
      contactData?.contentfulGlobalContactIntroLine1TextNode?.contactIntroLine1,
    line2:
      contactData?.contentfulGlobalContactIntroLine2TextNode?.contactIntroLine2,
    line3:
      contactData?.contentfulGlobalContactIntroLine3TextNode?.contactIntroLine3,
    line4:
      contactData?.contentfulGlobalContactIntroLine4TextNode?.contactIntroLine4
  };

  const phoneOnlyNumbers = intro?.line3.replace(/[^0-9]/g, "");

  return (
    <Wrap fluid>
      <div className="intro-wrap">
        <h2>{intro?.line1}</h2>
        <p style={{ marginTop: "15px" }}>{intro?.line2}</p>
        <Button to={`tel:` + phoneOnlyNumbers} target="blank">
          {intro?.line3}
        </Button>
        <p style={{ marginTop: "15px" }}>{intro?.line4}</p>
      </div>
      <div className="contacts">
        <table>
          {contacts.map((contact, i) => {
            const {
              category,
              contactName,
              contactTitle,
              description,
              phoneNumber,
              button
            } = contact;
            return (
              <SingleContact
                key={`contact-` + contactName + contactTitle + i}
                category={category}
                contactName={contactName}
                contactTitle={contactTitle}
                description={description}
                phoneNumber={phoneNumber}
                button={button}
                index={i}
              />
            );
          })}
        </table>
      </div>
    </Wrap>
  );
};

export default ContactPage;
