import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from "../images/Collection_System.png"

const step1IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step1 {
    height: 8vw;
    top: 49%;
    &.open {    
      z-index: 150 !important;
      border-right: 6px solid white !important; 
      width: 50%;
      left: 22%; 
      clip-path: polygon(15% 0%, 0% 100%, 100% 100%, 100% 0%); 
      transition: left .75s ease, width .5s ease .75s, clip-path .5s ease .75s, border-right .5s ease .75s;
    }
    &.closed {
      border-right: 0;
      width: 9.5vw;
      left: 10%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .65s ease, border-right .5s ease, clip-path .65s ease, left .5s ease .65s;
    }
    .info-wrap {
      .title-only {
        margin-top: 10px;
        p {
          font-size: .9rem;
          margin-bottom: -3px;
        }
      }
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 5px 10px 1rem 5vw;
        .icon {
          margin-right: .5rem;
          margin-top: 1rem;
          img {
            width: 5.5rem;
            height: 4rem;
          }
          img.open {
            animation: ${step1IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step1-line1 {
    height: 4px;
    top: 57%;
    right: 73%;
    width: 11%;
    transition: width .25s ease-in .65s;
    &.open {
      width: 0%;
      transition: width .75s ease;
    }
  }
  .circle-step1 {
    top: 55%;
    right: 71%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step1 {
      top: 49%;
      &.open {
        width: 50%;
        left: 22%; 
      }
      &.closed {
        left: 10%;
      }
      .title-only {
        margin-top: 20px !important;
        p {
          font-size: 1rem !important;
        }        
      }
      .full-info-wrap {
        // padding: 5px 10px 1rem 2rem !important;
        .title-and-copy {
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }
    }
    .step1-line1 {
      right: 73%;
    }
  }
  @media only screen and (max-width: 1007px) {
    .click-step1 {
      &.open {
        width: 70%;
        left: 21%; 
      }
      .title-only {
        margin-top: 5px !important;
        p {
          font-size: .7rem !important;
        }   
      }
      .full-info-wrap {
        padding: 5px 10px 1rem 6vw !important;
        .title-and-copy {
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            line-height: 19px;
            font-size: 1rem;
          }
        }
        .icon {
          margin-right: .5rem;
          margin-top: 1rem;
          img {
            width: 3rem !important;
            height: 3rem !important;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step1 {
      &.open {
        width: 78%;
        left: 20%; 
      }
      .title-only {
        margin-top: 0 !important;
        p {
          font-size: .55rem !important;
          line-height: 11px;
        }   
      }
      .full-info-wrap {
        padding: 0px 10px 1rem 7vw !important;
        .title-and-copy {
          .title {
            margin-bottom: 1px !important;
            font-size: 1rem !important;
          }
          .copy {
            line-height: 14px !important;
            font-size: .8rem !important;
          }
        }
        .icon {
          margin-right: .5rem;
          margin-top: 2vw !important;
          img {
            width: 2.5rem !important;
            height: 2.5rem !important;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step1 {
      &.open {
        width: 78%;
      }
      &.closed {
        left: 5%;
      }
      .title-only {
        margin-top: 5px !important;
        p {
          font-size: .6rem !important;
          margin-bottom: 1px !important;
        }
      }
      .full-info-wrap {
        padding: 0px 10px 1rem 8vw !important;
        .title-and-copy {
          .title {
            margin-bottom: 0px !important;
            font-size: .9rem !important;
          }
          .copy {
            line-height: 13px !important;
            font-size: .75rem !important;
          }
        }
        .icon {
          margin-right: .5rem;
          margin-top: 2vw !important;
          img {
            width: 2.5rem !important;
            height: 2.5rem !important;
          }
        }
      }
    }
    .step1-line1 {
      top: 57%;
    }
`
 
const Step1 = ({ currStep, updateCurrStep, newStep, closeSteps }) => {
  const [fullInfo, setFullInfo] = useState(false);  
  useEffect(() => {
    setTimeout(() => currStep === 1 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  const buttonClick = (fullInfo) => {
    updateCurrStep(1);
  }
  const info = {
    icon: Icon, 
    title: {
      full: 'Collection System',
      piece1: 'Collection',
      piece2: 'System'
    }, 
    copy: 'Used water from inside homes, schools and businesses is piped through sewer lines to the water reclamation plant.'
  };

  const buttonClosed = `click-step click-step1 closed`;
  const buttonOpen = `click-step click-step1 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step1-line1 open`;
  const stepLineOneClosed = `step-line step1-line1`;
  const circleClosed = `circle-step circle-step1`;
  const circleOpen = `circle-step circle-step1 open`;
  
  return (
    <Wrap>
      <button className={currStep === 1 ? buttonOpen : buttonClosed} onClick={() => buttonClick(fullInfo)}>
        <div className={currStep === 1 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {paddingTop: '2rem'} : {paddingTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 1 ? {opacity: 1, transition: 'opacity .75s ease .25s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 1 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
              </div>
          : <div className="title-only" style={currStep !== 1 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
            </div>
          }
        </div>
      </button>      
      <div className={currStep === 1 ? circleOpen : circleClosed} >1</div>
      <div className={currStep === 1 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  )
}
 
export default Step1