import React, { useState } from "react";
import FeaturedNews from "./FeaturedNews/FeaturedNews";
import NewsExcerpts from "./NewsExcerpts/NewsExcerpts";
import SubscribeWidget from "./SubscribeWidget";
import MediaContact from "./MediaContact";
import { graphql, useStaticQuery } from "gatsby";
import { NewsExcerptFields } from "../../../node/fragments/NodeNewsExcerptFields";
import { NewsPost, NewsPosts } from "../../../classes/News";

const Newsroom = () => {
  const [mediaContactHeight, setMediaContactHeight] = useState(0);
  const data: NewsroomQuery = useStaticQuery(query);

  const featured = new NewsPost(data.featured.edges[0].node);
  const recent = new NewsPosts(data.recent.edges).list;
  const enews = new NewsPosts(data.enews.edges).list;
  const releases = new NewsPosts(data.releases.edges).list;
  return (
    <React.Fragment>
      <SubscribeWidget />
      <FeaturedNews
        featured={featured}
        releases={releases}
        mediaContactHeight={mediaContactHeight}
      />
      <MediaContact setHeight={setMediaContactHeight} />
      <NewsExcerpts
        newsGroups={[
          {
            title: "Recent News Stories",
            news: recent
          },
          { title: "UTRWD eNews", news: enews }
        ]}
      />
    </React.Fragment>
  );
};

export default Newsroom;

interface NewsroomQuery {
  featured: {
    edges: {
      node: NewsExcerptFields;
    }[];
  };
  recent: {
    edges: {
      node: NewsExcerptFields;
    }[];
  };
  enews: {
    edges: {
      node: NewsExcerptFields;
    }[];
  };
  releases: {
    edges: {
      node: NewsExcerptFields;
    }[];
  };
}

export const query = graphql`
  {
    featured: allContentfulNews(
      limit: 1
      sort: { fields: [date, title], order: DESC }
      filter: { visibility: { in: "UTRWD" } }
    ) {
      edges {
        node {
          ...NodeNewsExcerptFields
        }
      }
    }
    recent: allContentfulNews(
      limit: 4
      filter: { tags: { in: "Recent News Story" }, visibility: { in: "UTRWD" } }
      sort: { fields: [date, title], order: DESC }
    ) {
      edges {
        node {
          ...NodeNewsExcerptFields
        }
      }
    }
    enews: allContentfulNews(
      limit: 4
      filter: { tags: { in: "UTRWD Enews" }, visibility: { in: "UTRWD" } }
      sort: { fields: [date, title], order: DESC }
    ) {
      edges {
        node {
          ...NodeNewsExcerptFields
        }
      }
    }
    releases: allContentfulNews(
      limit: 3
      filter: { tags: { in: "Recent Release" }, visibility: { in: "UTRWD" } }
      sort: { fields: [date, title], order: DESC }
    ) {
      edges {
        node {
          ...NodeNewsExcerptFields
        }
      }
    }
  }
`;
