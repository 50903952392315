import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import * as React from "react";
import { Fade } from "react-awesome-reveal";
import { WmkImage, Img, ContentfulImageQuery } from "wmk-image";
import ReactPlayer from "react-player";
import { Typography } from "../ui/Typography";
import { EmbeddedBlock, RichTextBlock, RichTextNode } from "wmk-rich-text";
import { BlockButton, blockHash, constructPath } from "./BlockHash";
import { WmkLink } from "wmk-link";
import { Col, Container, Row } from "react-bootstrap";
import BulletDrop from "../flexible/blocks/BlockBody/Bullets/BulletDrop";
import { PageFields } from "../../node/fragments/NodePageFields";
import { colors } from "../ui/colors";
import { AssetFields } from "../../node/fragments/NodeNewsFields";

const NullComp = () => <></>;

export const intOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: RichTextNode) => {
      const image = node?.reference?.data as ContentfulImageQuery;
      const type = image?.file?.contentType;
      const url = image?.file?.url;
      return <></>;
      // return !type ? (
      //   <NullComp />
      // ) : type.match(`image`) ? (
      //   <figure
      //   // style={{
      //   //   border: image.description
      //   //     ? `1px solid ${colors.cyan.toString()}`
      //   //     : undefined
      //   // }}
      //   >
      //     <div
      //       style={{
      //         width: "100%",
      //         justifyContent: "center",
      //         display: "flex"
      //       }}>
      //       <WmkImage
      //         image={new Img({ ...image })}
      //         style={{ margin: "0 0 2vh 0" }}
      //       />
      //     </div>
      //     {image.description ? (
      //       <figcaption
      //         style={{
      //           textAlign: "center",
      //           paddingBottom: `.5rem`,
      //           fontSize: `.9rem`,
      //           color: colors.blue.toString()
      //         }}>
      //         {image.description}
      //       </figcaption>
      //     ) : null}
      //   </figure>
      // ) : type.match(`video`) && url ? (
      //   <ReactPlayer url={url} controls />
      // ) : (
      //   <BlockButton
      //     __typename="ContentfulMenuItem"
      //     title={node.reference.data.title as string}
      //     asset={node.reference.data as ContentfulImageQuery}
      //     entry={null}
      //     url={null}
      //   />
      // );
    },
    [BLOCKS.PARAGRAPH]: (node: RichTextNode, children: React.ReactNode) => {
      return (
        <div style={{ padding: `0.5rem 0` }}>
          <Typography.P>{children}</Typography.P>
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: RichTextNode) => {
      const entry = new EmbeddedBlock(node, blockHash);
      return entry.render();
    },
    [BLOCKS.HEADING_1]: (node: RichTextNode, children: React.ReactNode) => {
      return (
        <Fade direction="up">
          <Typography.H1>{children}</Typography.H1>
        </Fade>
      );
    },
    [BLOCKS.HEADING_2]: (node: RichTextNode, children: React.ReactNode) => {
      return (
        <Fade direction="up">
          <Typography.H2 style={{ textAlign: "center" }}>
            {children}
          </Typography.H2>
        </Fade>
      );
    },
    [BLOCKS.HEADING_3]: (node: RichTextNode, children: React.ReactNode) => {
      return (
        <Fade direction="up">
          <Typography.H3 style={{ textAlign: "center" }}>
            {children}
          </Typography.H3>
        </Fade>
      );
    },
    [BLOCKS.HEADING_4]: (node: RichTextNode, children: React.ReactNode) => {
      return (
        <Fade>
          <Typography.H4>{children}</Typography.H4>
        </Fade>
      );
    },
    [BLOCKS.HEADING_5]: (node: RichTextNode, children: React.ReactNode) => {
      return (
        <Fade>
          <Typography.H5>{children}</Typography.H5>
        </Fade>
      );
    },
    [BLOCKS.HEADING_6]: (node: RichTextNode, children: React.ReactNode) => {
      return (
        <Fade>
          <Typography.H6>{children}</Typography.H6>
        </Fade>
      );
    },
    [BLOCKS.UL_LIST]: (node: RichTextBlock, children: JSX.Element[]) => {
      const content = node.content;
      return Array.isArray(content) ? (
        <Container>
          <ul style={{ listStyleType: "none" }}>
            {content.map((n, i) => {
              return (
                <Row
                  key={n.nodeType + i}
                  style={{
                    padding: `0.25rem 0rem`,
                    display: "flex",
                    alignItems: "flex-start"
                  }}>
                  <Col xs="auto" style={{ paddingTop: `.4rem` }}>
                    <BulletDrop />
                  </Col>
                  <Col>{children[i]}</Col>
                </Row>
              );
            })}
          </ul>
        </Container>
      ) : null;
    },
    [INLINES.HYPERLINK]: (node: RichTextNode, children: JSX.Element) => {
      const data = node.data as { uri: string };
      return (
        <WmkLink to={data.uri} target="_blank">
          {Array.isArray(children) ? children.join(" ") : children}
        </WmkLink>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node: RichTextNode, children: JSX.Element) => {
      const ref = node.reference;
      const data = ref.data as PageFields | AssetFields;
      return (
        <WmkLink
          to={
            ref?.__typename === "ContentfulPages"
              ? constructPath(data as PageFields)
              : data && "file" in data
              ? data.file.url
              : "/"
          }
          target="_blank">
          {children}
        </WmkLink>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node: RichTextNode, children: string[]) => {
      const ref = node?.reference?.data as AssetFields;

      return (
        <WmkLink to={ref?.file?.url} target="_blank">
          {Array.isArray(children) ? children.join(" ") : children}
        </WmkLink>
      );
    }
  }
};
