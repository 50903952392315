import * as React from "react";
import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import InteractiveGraphicPieces from "./Pieces/Pieces";
import InteractiveGraphicBackground from "./Background";

const Wrap = styled.div``;

const RelativeFrame = styled.div<{ frameWidth: number }>`
  * {
    transition: all 0.3s ease;
  }
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
  height: ${({ frameWidth }) =>
    frameWidth ? `${(2 / 3) * frameWidth}px` : "auto"};
`;

const InteractiveGraphic = () => {
  const [frameWidth, setFrameWidth] = useState(0);
  const [clickedPiece, setClickedPiece] = useState("");
  const frameRef = useRef<HTMLDivElement>();

  const handleClick = (s: string) => setClickedPiece(s);

  useEffect(() => {
    const current = frameRef.current
    const handleResize = () =>{
      const frame = current ? current.getBoundingClientRect() : null
      setFrameWidth(frame?.width)};
    handleResize();
    window.addEventListener("resize", handleResize);
    // console.log('frameWidth: ', frameWidth);
    return () => window.removeEventListener("resize", handleResize);
  }, [frameWidth]);
  return (
    <Wrap>
      <Container>
        <RelativeFrame ref={frameRef} frameWidth={frameWidth}>
          <InteractiveGraphicBackground clickedPiece={clickedPiece} />
          <InteractiveGraphicPieces
            clickedPiece={clickedPiece}
            handleClick={handleClick}
          />
        </RelativeFrame>
      </Container>
    </Wrap>
  );
};

export default InteractiveGraphic;
