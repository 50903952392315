import React from "react";
import styled from "styled-components";

const SVGWrap = styled.div`
  display: block;
  width: 100%;
  .cls-1 {
    fill: none;
  }
  svg {
    margin: auto;
  }
`;

export const IconRotor = () => {
  return (
    <SVGWrap>
      <svg viewBox="0 0 130.81 69">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="Layer_2-2" data-name="Layer 2">
              <g id="Layer_1-2-2" data-name="Layer 1-2">
                <polygon
                  className="cls-1"
                  points="0 14.78 4.54 14.78 4.54 19.32 0 19.32 0 14.78 0 14.78"
                />
                <polygon
                  className="cls-1"
                  points="18 14.78 22.54 14.78 22.54 19.32 18 19.32 18 14.78 18 14.78"
                />
                <polygon
                  className="cls-1"
                  points="9.08 14.78 13.63 14.78 13.63 19.32 9.08 19.32 9.08 14.78 9.08 14.78"
                />
                <polygon
                  className="cls-1"
                  points="0 9.03 4.54 9.03 4.54 13.57 0 13.57 0 9.03 0 9.03"
                />
                <polygon
                  className="cls-1"
                  points="18 9.03 22.54 9.03 22.54 13.57 18 13.57 18 9.03 18 9.03"
                />
                <polygon
                  className="cls-1"
                  points="9.08 9.03 13.63 9.03 13.63 13.57 9.08 13.57 9.08 9.03 9.08 9.03"
                />
                <polygon
                  className="cls-1"
                  points="26.83 14.78 31.38 14.78 31.38 19.32 26.83 19.32 26.83 14.78 26.83 14.78"
                />
                <polygon
                  className="cls-1"
                  points="26.83 9.03 31.38 9.03 31.38 13.57 26.83 13.57 26.83 9.03 26.83 9.03"
                />
                <polygon
                  className="cls-2"
                  points="90.52 14.78 95.06 14.78 95.06 19.32 90.52 19.32 90.52 14.78 90.52 14.78"
                />
                <polygon
                  className="cls-2"
                  points="108.52 14.78 113.06 14.78 113.06 19.32 108.52 19.32 108.52 14.78 108.52 14.78"
                />
                <polygon
                  className="cls-2"
                  points="99.6 14.78 104.15 14.78 104.15 19.32 99.6 19.32 99.6 14.78 99.6 14.78"
                />
                <polygon
                  className="cls-2"
                  points="90.52 9.03 95.06 9.03 95.06 13.57 90.52 13.57 90.52 9.03 90.52 9.03"
                />
                <polygon
                  className="cls-2"
                  points="108.52 9.03 113.06 9.03 113.06 13.57 108.52 13.57 108.52 9.03 108.52 9.03"
                />
                <polygon
                  className="cls-2"
                  points="99.6 9.03 104.15 9.03 104.15 13.57 99.6 13.57 99.6 9.03 99.6 9.03"
                />
                <polygon
                  className="cls-2"
                  points="126.27 14.78 130.81 14.78 130.81 19.32 126.27 19.32 126.27 14.78 126.27 14.78"
                />
                <polygon
                  className="cls-2"
                  points="117.35 14.78 121.9 14.78 121.9 19.32 117.35 19.32 117.35 14.78 117.35 14.78"
                />
                <polygon
                  className="cls-2"
                  points="126.27 9.03 130.81 9.03 130.81 13.57 126.27 13.57 126.27 9.03 126.27 9.03"
                />
                <polygon
                  className="cls-2"
                  points="117.35 9.03 121.9 9.03 121.9 13.57 117.35 13.57 117.35 9.03 117.35 9.03"
                />
                <path
                  className="cls-2"
                  d="M81.45,3.56a37.91,37.91,0,0,0-32.09,0v10H81.45Zm-27.55,6v-3a33,33,0,0,1,23,0v3Z"
                />
                <path
                  className="cls-2"
                  d="M89.67,64.43l-4.55-9.08H81.45V14.78H49.36V55.35H45.69l-4.54,9.08H26.66V69h77.5V64.43ZM53.9,18.72h23v23h-23Zm0,27.55h23v9.08h-23ZM46.22,64.43l2.27-4.54H82.32l2.27,4.54Z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SVGWrap>
  );
};
