import React from "react";
import { SprinklerFields } from "../../../../../node/fragments/NodeSprinklersFields";
import Sprinkler from "./Sprinkler";

const SGInfo = ({
  sprinklers,
  active
}: {
  sprinklers: SprinklerFields[];
  active: number;
}) => {
  return sprinklers && sprinklers.length ? (
    <div>
      {sprinklers.map((sprinkler, i) => {
        return active === i ? (
          <Sprinkler key={"spr" + i} sprinkler={sprinkler} index={i} />
        ) : null;
      })}
    </div>
  ) : null;
};

export default SGInfo;
