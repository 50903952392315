import React from "react";
import { Col, Row } from "react-bootstrap";
import get from "lodash/get";
import styled from "styled-components";
import { IconMulti, IconRotor, IconSpray } from "./icons";
import { colors } from "../../../../../vars/palette";
import { SprinklerFields } from "../../../../../node/fragments/NodeSprinklersFields";

const Button = styled.button`
  background: none;
  appearance: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: initial;
  svg {
    height: 8.75vh;
    max-width: 25vw;
    fill: ${({ active }: {active: string}) =>
      active ? colors.hex("secondary") : colors.hex("silver")};
    transition: all 0.4s ease;
    margin: auto;
  }
  &:hover {
    svg {
      fill: ${colors.hex("primary")};
      transition: all 0.4s ease;
    }
    span {
      transition: all 0.4s ease;
      color: ${colors.hex("primary")};
    }
  }
  :focus {
    @media only screen {
      outline: none;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  span {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: ${({ active }) =>
      active ? colors.hex("secondary") : colors.hex("silver")};
    transition: all 0.4s ease;
    font-size: 2vh;
  }
`;

const ButtonsWrap = styled(Row)`
  .col-4:not(:last-child) {
    border-right: 2px solid;
  }
`;

const SGButtons = ({
  sprinklers,
  set,
  active
}: {
  sprinklers: SprinklerFields[];
  set: Function;
  active: number;
}) => {
  //console.log(sprinklers);
  return sprinklers && sprinklers.length ? (
    <ButtonsWrap>
      {sprinklers.map((s, i) => {
        const { title, icon } = s;
        const src = get(icon, "file.url");
        const isRotor = src?.indexOf("rotor_3.svg") > 0;
        const isSpray = src?.indexOf("sprayhead.svg") > 0;
        const isMulti = src?.indexOf("multi.svg") > 0;
        return (
          <Col
            xs={4}
            key={"sprinkbtn-" + title}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <Button onClick={() => set(i)} active={active === i ? "true" : ""}>
              {isMulti && <IconMulti />}
              {isRotor && <IconRotor />}
              {isSpray && <IconSpray />}
              <span>{title}</span>
            </Button>
          </Col>
        );
      })}
    </ButtonsWrap>
  ) : null;
};

export default SGButtons;
