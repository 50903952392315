import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { JobFields } from "../../../../node/fragments/NodeJobFields";
import { colors } from "../../../../vars/palette";
import Theme from "../../../../vars/ThemeOptions";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: ${colors.hex("primary")};
    margin-bottom: -10px;
    .wmk-link {
      font-size: 1rem;
      font-weight: normal;
      color: ${colors.hex("accent")};
      text-decoration: underline;
    }
  }
  .summary {
    color: ${colors.hex("secondary")};
    .bold {
      font-weight: bold;
    }
  }
`;

const JobPreview = ({ job, noDesc }: { job: JobFields; noDesc?: boolean }) => {
  // console.log("job from job preview: ", job)
  return (
    <Wrap>
      <div className="title">
        <div>
          {job.title}
          <span>
            &nbsp;&nbsp;&nbsp;{" "}
            <WmkLink to={Theme.paths.job + job?.slug}>View More</WmkLink>
          </span>
        </div>
      </div>
      {noDesc ? null : (
        <div className="summary">
          <div>
            <span className="bold">Position Summary:</span>
            {` ` + job.jobSummary?.jobSummary}
          </div>
        </div>
      )}
    </Wrap>
  );
};

export default JobPreview;
