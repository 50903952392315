import React from "react";
import { colors } from "../../ui/colors";

const NewsDate = ({
  children,
  reverse,
  style
}: {
  children: React.ReactNode;
  reverse?: boolean;
  style?: React.CSSProperties;
}) => {
  const classes = ["news-date"];
  const color = reverse ? "white" : "blue";
  // const date = new Date(children);
  // const year = date.getFullYear().toString();
  // const month =
  //   date.getMonth() + 1 < 10
  //     ? "0" + (date.getMonth() + 1)
  //     : (date.getMonth() + 1).toString();
  // const day =
  //   date.getDate() < 10 ? "0" + date.getDate() : date.getDate().toString();
  // const dateString = `${month}/${day}/${year.substring(
  //   year.length - 2,
  //   year.length
  // )}`;

  return (
    <span
      className={classes.join(" ")}
      style={{ color: colors[color].getHex(), ...style }}>
      {children}
    </span>
  );
};

export default NewsDate;
