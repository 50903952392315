import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from "../images/Monitoring_and_Testing.png"

const step8IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step8 {
    height: 8vw;
    top: 48%; 
    right: 12.3%;
    &.open {     
      z-index: 150 !important;
      border-left: 6px solid white !important;
      width: 55%;
      top: 31%;
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 85% 0%); 
      transition: top .75s ease, width .5s ease .75s, clip-path .5s ease .75s, border-left .5s ease .75s;
    }
    &.closed {
      border-left: 0;
      width: 9.5vw;
      top: 48%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .65s ease, border-left .5s ease, clip-path .65s ease, top 1s ease .65s;
    }
    .info-wrap {
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 0.5vw 5vw 1rem 3px;
        .icon {
          margin-left: .5rem;
          margin-top: 1rem;
          img {
            width: 5rem;
            height: 4rem;
          }
          img.open {
            animation: ${step8IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step8-line1 {
    width: 4px;
    top: 41%;
    right: 17%;
    height: 15%;
    transition: height .5s ease-in .65s;
    &.open {
      height: 0%;
      transition: height .75s ease;
    }
  }
  .circle-step8 {
    top: 39%;
    right: 15.5%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step8 {
      right: 10.8%;
      &.open {
        width: 75%;
      }
      .full-info-wrap {
        padding: 0.5vw 6vw 1rem 3px !important;
        .icon {
          margin-left: .55rem !important;
          margin-top: .5rem !important;
          img {
            width: 5rem !important;
            height: 4rem !important;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 3px !important;
            font-size: 1.3rem !important;
          }
          .copy {
            line-height: 19px !important;
            font-size: 1rem !important;
          }
        }
      }  
    }
    .step8-line1 {
      right: 16.6%;
    }
  }
  @media only screen and (max-width: 1007px) {
    .click-step8 {
      // right: 13.1%;
      &.open {
        width: 75%;
        // top: 25%;
      }
      .title-only {
        margin-top: -5px;
        p {
          font-size: .7rem;
          line-height: 13px;
        }
      }
      .full-info-wrap {
        padding: 0.5vw 7vw 1rem 3px !important;
        .icon {
          margin-left: .55rem !important;
          margin-top: .75rem !important;
          img {
            width: 4rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 3px !important;
            font-size: 1.1rem !important;
          }
          .copy {
            line-height: 16px !important;
            font-size: .9rem !important;
          }
        }
      }  
    }
    .step8-line1 {
      // right: 19%;
    }
  }
  @media only screen and (max-width: 850px) {
    .click-step8 {
      right: 11.2%;
      &.open {
        width: 75%;
      }
      .title-only {
        margin-top: -5px;
        p {
          font-size: .7rem;
          line-height: 13px;
        }
      }
      .full-info-wrap {
        padding: 0 7vw 1rem 3px !important;
        .icon {
          margin-left: .35rem !important;
          margin-top: .75rem !important;
          img {
            width: 4rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 1px !important;
            font-size: 1.1rem !important;
          }
          .copy {
            line-height: 16px !important;
            font-size: .9rem !important;
          }
        }
      }  
    }
    .step8-line1 {
      right: 17%;
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step8 {
      &.open {
        width: 85%;
      }
      .title-only {
        margin-top: -20px;
        p {
          font-size: .55rem;
          line-height: 10px;
        }
      }
      .full-info-wrap {
        padding: 0 8vw 1rem 0 !important;
        .icon {
          margin-left: .15rem !important;
          margin-top: .75rem !important;
          img {
            width: 3rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          margin-top: -3px;
          .title {
            margin-bottom: -3px !important;
            font-size: .9rem !important;
          }
          .copy {
            line-height: 13px !important;
            font-size: .75rem !important;
          }
        }
      }  
    }
    .step8-line1 {
      // right: 19%;
    }
  }
  @media only screen and (max-width: 575px) {    
    .click-step8 {
      right: 8.2% !important;
      &.open {
        width: 90%;
      }
      &.closed {
        right: 9.8%;
        top: 55%;
      }
      .title-only {
        margin-top: -20px;
        p {
          font-size: .55rem;
          line-height: 10px;
        }
      }
      .full-info-wrap {
        padding: 0 7vw 1rem 0 !important;
        .icon {
          margin-left: .15rem !important;
          margin-top: 1.25rem !important;
          img {
            width: 3rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          margin-top: -3px;
          .title {
            margin-bottom: -1px !important;
            font-size: .8rem !important;
          }
          .copy {
            line-height: 12px !important;
            font-size: .7rem !important;
          }
        }
      }  
    }
    .step8-line1 {
      right: 17.2%;
      height: 20%;
    }
  }
`
 
const Step8 = ({ currStep, updateCurrStep, newStep, closeSteps }) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => currStep === 8 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  const buttonClick = (fullInfo) => {
    updateCurrStep(8);
  }
  const info = {
    icon: Icon, 
    title: {
      full: 'Monitoring and Testing',
      piece1: 'Monitoring',
      piece2: 'and Testing'
    }, 
    copy: 'The treated effluent, having been tested throughout the treatment process, is discharged into Lake Lewisville. The effluent discharged meets stringent requirements, with pollutant removal maintained at 98% or greater.'
  };

  const buttonClosed = `click-step click-step8 closed`;
  const buttonOpen = `click-step click-step8 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step8-line1 open`;
  const stepLineOneClosed = `step-line step8-line1`;
  const circleClosed = `circle-step circle-step8`;
  const circleOpen = `circle-step circle-step8 open`;
  
  return (
    <Wrap>
      <button className={currStep === 8 ? buttonOpen : buttonClosed} onClick={() => buttonClick(fullInfo)}>
        <div className={currStep === 8 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {marginTop: '3rem'} : {marginTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 8 ? {opacity: 1, transition: 'opacity .75s ease .25s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 8 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
              </div>
          : <div className="title title-only" style={currStep !== 8 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
            </div>
          }
        </div>
      </button>      
      <div className={currStep === 8 ? circleOpen : circleClosed}>8</div>
      <div className={currStep === 8 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  )
}
 
export default Step8