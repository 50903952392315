import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from "../images/Grit_Removal.png"

const step3IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step3 {
    height: 8vw;
    top: 13%;
    left: 10%;
    &.open {
      z-index: 150 !important;
      border-right: 6px solid white !important;
      width: 45%;
      top: 26%; 
      left: 41%;
      clip-path: polygon(15% 0%, 0% 100%, 100% 100%, 100% 0%); 
      transition: left .75s ease, top .5s ease .75s, width .5s ease 1.25s, clip-path .5s ease 1.25s, border-right .5s ease 1.25s;
    }
    &.closed {
      border-right: 0;
      width: 9.5vw;
      top: 13%;
      left: 10%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .5s ease, border-right .25s ease, clip-path .5s ease, top .5s ease .5s, left .75s ease 1s;
    }
    .info-wrap {
      .title-only {
        margin-top: 10px;
        p {
          font-size: 1rem;
          line-height: 14px;
          margin-bottom: -5px;
        }
      }
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 10px 10px 1rem 5vw;
        .icon {
          margin-top: 1rem;
          margin-right: .5rem;
          img {
            width: 3.5rem;
            height: 3.5rem;
          }
          img.open {
            animation: ${step3IconGrow} .5s ease .75s;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: .25rem;
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step3-line1 {
    height: 4px;
    top: 22%;
    right: 54.7%;
    width: 29%;
    transition: width .75s ease 1s;
    &.open {
      width: 0%;
      transition: width .75s ease;
    }
  }
  .step3-line2 {
    width: 4px;
    bottom: 66%;
    right: 54.5%;
    height: 12%;
    transition: height .5s ease .5s;
    &.open {
      height: 0%;
      transition: height .75s ease .75s;
    }
  }
  .circle-step3 {
    top: 31%;
    right: 53%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step3 {
      &.open {
        width: 50%;
        top: 24%;
        left: 40%;
      }
      .full-info-wrap {
        padding: 5px 10px 1rem 5vw !important;
        .icon {
          margin-top: 1rem !important;
          margin-right: .5rem !important;
          img {
            width: 3.5rem !important;
            height: 3.5rem !important;
          }
        }
        .title-and-copy {
          padding-right: .25rem !important;
          .title {
            margin-bottom: 3px !important;
            font-size: 1.3rem !important;
          }
          .copy {
            line-height: 19px !important;
            font-size: 1rem !important;
          }
        }
      }  
    }
    .step3-line1 {
      right: 54.2%;
    }
    .step3-line2 {
      right: 54%;
    }
  }
  @media only screen and (max-width: 1007px) {
    .click-step3 {
      &.open {
        width: 57%;
      }
      .full-info-wrap {
        padding: 5px 10px 1rem 6vw !important;
        .icon {
          margin-top: 1rem !important;
          margin-right: .5rem !important;
          img {
            width: 3rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          padding-right: .25rem !important;
          .title {
            margin-bottom: 0px !important;
            font-size: 1.1rem !important;
          }
          .copy {
            line-height: 16px !important;
            font-size: .9rem !important;
          }
        }
      }  
    }
    .step3-line1 {
      right: 54.5%;
    }
    .step3-line2 {
      right: 54.5%;
    }
  }
  @media only screen and (max-width: 850px) {
    .click-step3 {
      &.open {
        width: 58%;
      }
      .full-info-wrap {
        padding: 0px 10px 1rem 6vw !important;
        .icon {
          margin-top: 1rem !important;
          margin-right: .5rem !important;
          img {
            width: 3rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          padding-right: .25rem !important;
          .title {
            margin-bottom: 0px !important;
            font-size: 1rem !important;
          }
          .copy {
            line-height: 15px !important;
            font-size: .85rem !important;
          }
        }
      }  
    }
    .step3-line1 {
      right: 54.5%;
    }
    .step3-line2 {
      right: 54.5%;
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step3 {
      &.open {
        width: 59%;
      }
      .title-only {
        p {
          font-size: .8rem !important;
        }
      }
      .full-info-wrap {
        padding: 0px 0px 1rem 6vw !important;
        .icon {
          margin-top: .5rem !important;
          margin-right: .5rem !important;
          img {
            width: 2.2rem !important;
            height: 2.2rem !important;
          }
        }
        .title-and-copy {
          padding-right: .25rem !important;
          .title {
            margin-bottom: -3px !important;
            font-size: .8rem !important;
          }
          .copy {
            line-height: 11px !important;
            font-size: .65rem !important;
          }
        }
      } 
    }
    .step3-line1 {
      right: 55%;
    }
    .step3-line2 {
      right: 55%;
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step3 {
      &.open {
        width: 62%;
        top: 20%;
        left: 35%;
      }
      &.closed {
        top: 12%;
        left: 5%;
      }
      .title-only {
        p {
          font-size: .75rem !important;
          line-height: 13px !important;
        }
      }
      .full-info-wrap {
        padding: 0px 0px 1rem 6vw !important;
        .icon {
          margin-top: 1rem !important;
          margin-right: .25rem !important;
          img {
            width: 2.2rem !important;
            height: 2.2rem !important;
          }
        }
        .title-and-copy {
          padding-right: .25rem !important;
          .title {
            margin-bottom: -3px !important;
            font-size: .8rem !important;
          }
          .copy {
            line-height: 11px !important;
            font-size: .65rem !important;
          }
        }
      } 
    }
    .step3-line1 {
      right: 55%;
    }
    .step3-line2 {
      right: 55%;
    }
  }
`
 
const Step3 = ({ currStep, updateCurrStep, newStep, closeSteps }) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => currStep === 3 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  const buttonClick = (fullInfo) => {
    updateCurrStep(3);
  }
  const info = {
    icon: Icon, 
    title: {
      full: 'Grit Removal',
      piece1: 'Grit',
      piece2: 'Removal'
    }, 
    copy: 'Heavy material, such as sand and gravel, is removed from the wastewater. This material is also disposed of in a landfill.'
  };

  const buttonClosed = `click-step click-step3 closed`;
  const buttonOpen = `click-step click-step3 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step3-line1 open`;
  const stepLineOneClosed = `step-line step3-line1`;
  const stepLineTwoOpen = `step-line step3-line2 open`;
  const stepLineTwoClosed = `step-line step3-line2`;
  const circleClosed = `circle-step circle-step3`;
  const circleOpen = `circle-step circle-step3 open`;

  return (
    <Wrap>
      <button className={currStep === 3 ? buttonOpen : buttonClosed} onClick={() => buttonClick(fullInfo)}>
        <div className={currStep === 3 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {marginTop: '1.5rem'} : {marginTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 3 ? {opacity: 1, transition: 'opacity .75s ease .75s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 3 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
              </div>
          : <div className="title title-only" style={currStep !== 3 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
            </div>
          }
        </div>
      </button>      
      <div className={currStep === 3 ? circleOpen : circleClosed}>3</div>
      <div className={currStep === 3 ? stepLineOneOpen : stepLineOneClosed} />
      <div className={currStep === 3 ? stepLineTwoOpen : stepLineTwoClosed} />
    </Wrap>
  )
}
 
export default Step3