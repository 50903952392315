import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import JobPreview from "./JobPreview";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { colors } from "../../../../vars/palette";
import { JobFields } from "../../../../node/fragments/NodeJobFields";

const controlsHeight = 50; // pixels

const WrapCont = styled(Container)`
  width: 100%;
  position: relative;
  overflow: hidden;
  .row {
    flex-wrap: nowrap;
    transition: left 0.5s ease;
  }
  .slides-row {
    position: absolute;
    width: 100%;
    left: 0;
    margin: 0 !important;
    .slide {
      padding: 2rem;
    }
  }
  .control-row {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: ${controlsHeight};
    padding: 0;
    .control-col {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      button {
        margin: 0 15px;
        font-size: 3rem;
        background: ${colors.hex("white")};
        color: ${colors.hex("secondary")};
        border: none;
      }
      .dot {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        border: 1px solid ${colors.hex("secondary")};
        margin: 0 5px -5px 5px;
        &.current {
          background: ${colors.hex("secondary")};
        }
      }
    }
  }
`;

const JobSlider = ({ jobs }: {jobs: JobFields[]}) => {
  const [currSlide, setCurrSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(null);
  const [slideHeight, setSlideHeight] = useState(null);

  const slideRef = useRef<HTMLDivElement>();
  const onResize = () => {
    setSlideWidth(slideRef?.current?.offsetWidth);
    setSlideHeight(slideRef?.current?.offsetHeight);
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  const updateCurrSlide = (prevNext: "next" | "prev") => {
    return prevNext === "prev"
      ? currSlide > 0
        ? setCurrSlide(currSlide - 1)
        : null
      : currSlide < groupedJobs.length - 1
      ? setCurrSlide(currSlide + 1)
      : null;
  };

  // console.log(jobs)

  let groupedJobs = [];
  let newArr = [];
  for (let i = 0; i < jobs.length; i++) {
    newArr.push(jobs[i]);
    if ((i + 1) % 3 === 0 || i + 1 === jobs.length) {
      groupedJobs.push(newArr);
      newArr = [];
    }
  }

  // console.log("groupedJobs :", groupedJobs)

  return (
    <WrapCont style={{ height: slideHeight + controlsHeight }}>
      <Row className="slides-row" style={{ left: -(currSlide * slideWidth) }}>
        {groupedJobs.map((jobArr, i) => {
          return (
            <Col xs={12} className="slide" key={`slide` + i} ref={slideRef}>
              {jobArr.map((job, j) => {
                return <JobPreview job={job} key={job.title + j} noDesc />;
              })}
            </Col>
          );
        })}
      </Row>
      {groupedJobs.length > 1 && (
        <Row className="control-row">
          <Col xs={12} className="control-col">
            <button onClick={() => updateCurrSlide("prev")} aria-label="Back">
              <IoIosArrowBack />
              <span className="sr-only">Back</span>
            </button>
            {groupedJobs.map((slide, i) => {
              return (
                <div
                  className={currSlide === i ? `dot current` : `dot`}
                  key={"slide" + i}
                />
              );
            })}
            <button onClick={() => updateCurrSlide("next")} aria-label="Next">
              <IoIosArrowForward />
              <span className="sr-only">Next</span>
            </button>
          </Col>
        </Row>
      )}
    </WrapCont>
  );
};

export default JobSlider;
