import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from "../images/Filtration.png"

const step6IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step6 {
    z-index: 150 !important; //need this step to have higher z-index than others because it overlaps another step when open
    height: 8vw;
    top: 71%;
    &.open {     
      z-index: 150 !important;
      border-left: 6px solid white !important;
      width: 40%;
      top: 48%;
      right: 31.9%;
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 85% 0%); 
      transition: right .75s ease, top .5s ease .75s, width .5s ease 1.25s, clip-path .5s ease 1.25s, border-left .5s ease 1.25s;
    }
    &.closed {
      border-left: 0;
      width: 9.5vw;
      top: 71%;
      right: 23%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .5s ease, border-left .25s ease, clip-path .5s ease, top .5s ease .5s, right .75s ease 1s;
    }
    .info-wrap {
      .title {
        text-align: center;
        margin-bottom: -10px;
        p {
          margin-bottom: -2px;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 10px 5vw 1rem 10px;
        .icon {
          margin-top: .5rem;
          img {
            width: 5rem;
            height: 4rem;
          }
          img.open {
            animation: ${step6IconGrow} .5s ease .75s;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 90%;
          padding-right: 1rem;
          .title {
            margin-bottom: 5px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step6-line1 {
    height: 4px;
    top: 77.6%;
    left: 63.45%;
    width: 7%;
    transition: width .75s ease 1s;
    &.open {
      width: 0%;
      transition: width .75s ease;
    }
  }
  .step6-line2 {
    width: 4px;
    top: 56%;
    right: 36.3%;
    height: 22%;
    transition: height .5s ease .5s;
    &.open {
      height: 0%;
      transition: height .75s ease .75s;
    }
  }
  .circle-step6 {
    top: 53%;
    right: 35%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step6 {
      &.open {
        width: 50%;
        top: 48%;
        right: 30.5%;
      }
      &.closed {
        top: 71%;
        right: 23%;
      }
      .full-info-wrap {
        padding: 10px 6.5vw 1rem 10px !important;
        .icon {
          margin-top: .5rem !important;
          img {
            width: 5rem !important;
            height: 4rem !important;
          }
        }
        .title-and-copy {
          width: 90% !important;
          padding-right: 1rem !important;
          .title {
            margin-bottom: 5px !important;
            font-size: 1.3rem !important;
          }
          .copy {
            line-height: 19px !important;
            font-size: 1rem !important;
          }
        }
      }  
    }
    .circle-step6 {
      right: 35.3%;
    }
  }
  @media only screen and (max-width: 1007px) {
    .click-step6 {
      &.open {
        width: 55%;
        top: 46%;
        right: 30.5%;
      }
      &.closed {
        top: 71%;
        right: 23%;
      }
      .full-info-wrap {
        padding: 5px 8vw 1rem 3px !important;
        .icon {
          margin-top: .75rem !important;
          img {
            width: 4rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          width: 90% !important;
          padding-right: 1rem !important;
          .title {
            margin-bottom: 5px !important;
            font-size: 1.3rem !important;
          }
          .copy {
            line-height: 19px !important;
            font-size: 1rem !important;
          }
        }
      } 
    }
    .circle-step6 {
      right: 35.3%;
    }
  }
  @media only screen and (max-width: 850px) {
    .click-step6 {
      &.open {
        width: 60%;
        top: 46%;
        right: 30.5%;
      }
      &.closed {
        top: 71%;
        right: 23%;
      }
      .full-info-wrap {
        padding: 5px 8vw 1rem 3px !important;
        .icon {
          margin-top: .5rem !important;
          img {
            width: 4rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          width: 90% !important;
          padding-right: 1rem !important;
          .title {
            margin-bottom: 2px !important;
            font-size: 1.1rem !important;
          }
          .copy {
            line-height: 16px !important;
            font-size: .9rem !important;
          }
        }
      }
    }
    .circle-step6 {
      right: 35.3%;
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step6 {
      &.open {
        width: 65%;
        top: 46%;
        right: 30.5%;
      }
      &.closed {
        top: 71%;
        right: 23%;
      }
      .title-only {
        margin-top: -10px;
        p {
          font-size: .7rem !important;
        }
      }
      .full-info-wrap {
        padding: 2px 8vw 1rem 1px !important;
        .icon {
          margin-top: .5rem !important;
          img {
            width: 3rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          width: 100% !important;
          padding-right: .5rem !important;
          .title {
            margin-bottom: 0px !important;
            font-size: .9rem !important;
          }
          .copy {
            line-height: 13px !important;
            font-size: .8rem !important;
          }
        }
      }
    }
    .step6-line1 {
      left: 62.8%;
      top: 77%;
    }
    .step6-line2 {
      right: 37%;
    }
    .circle-step6 {
      right: 35.3%;
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step6 {
      &.open {
        width: 65%;
        top: 46%;
        right: 28%;
      }
      &.closed {
        top: 67%;
        right: 21%;
      }
      .title-only {
        p {
          font-size: .7rem !important;
        }
      }
      .full-info-wrap {
        padding: 2px 8vw 1rem 1px !important;
        .icon {
          margin-top: 1rem !important;
          img {
            width: 3rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          width: 100% !important;
          padding-right: 0.25rem !important;
          .title {
            margin-bottom: 0px !important;
            font-size: .9rem !important;
          }
          .copy {
            line-height: 12px !important;
            font-size: .75rem !important;
          }
        }
      }
    }
    .step6-line1 {
      top: 76.5%;
    }
    .circle-step6 {
      right: 35.3%;
    }
  }
`
 
const Step6 = ({ currStep, updateCurrStep, newStep, closeSteps }) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => currStep === 6 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  const buttonClick = (fullInfo) => {
    updateCurrStep(6);
  }
  const info = {
    icon: Icon, 
    title: {
      full: 'Filtration',
    }, 
    copy: 'The clarified effluent flows through a sand filter to remove any remaining particles.'
  };

  const buttonClosed = `click-step click-step6 closed`;
  const buttonOpen = `click-step click-step6 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step6-line1 open`;
  const stepLineOneClosed = `step-line step6-line1`;
  const stepLineTwoOpen = `step-line step6-line2 open`;
  const stepLineTwoClosed = `step-line step6-line2`;
  const circleClosed = `circle-step circle-step6`;
  const circleOpen = `circle-step circle-step6 open`;
  
  return (
    <Wrap>
      <button className={currStep === 6 ? buttonOpen : buttonClosed} onClick={() => buttonClick(fullInfo)}>
        <div className={currStep === 6 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {paddingTop: '2rem'} : {paddingTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 6 ? {opacity: 1, transition: 'opacity .75s ease .75s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 6 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
              </div>
            : <div className="title title-only" style={currStep !== 6 ? {opacity: 1, transition: 'opacity .75s ease .5s'} : {opacity: 0, transition: 'opacity 1s ease'}}>
                <p>{info.title.full}</p>
              </div>
          }
        </div>
      </button>      
      <div className={currStep === 6 ? circleOpen : circleClosed}>6</div>
      <div className={currStep === 6 ? stepLineOneOpen : stepLineOneClosed} />
      <div className={currStep === 6 ? stepLineTwoOpen : stepLineTwoClosed} />
    </Wrap>
  )
}
 
export default Step6