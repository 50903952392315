import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Body from "../../../../flexible/blocks/BlockBody/BlockBody";
import { FoundLocation, LocationContext } from "../../context/LocationProvider";
import { Loader } from "../Loader";
import { graphql, useStaticQuery } from "gatsby";
import WidgetSubTitle from "../../../../common/typography/WidgetSubTitle";
import WidgetTitle from "../../../../common/typography/WidgetTitle";
import UButton from "../../../../common/Button";
import { colors } from "../../../../../vars/palette";
import { PageBlocks } from "../../../../../classes/Page";

const Styled = {
  Rec: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3vh;
    span {
      font-size: 4vh;
      padding: 1.25vh 1.5vh;
      border: 1px solid ${colors.hex("white")};
      background: ${colors.hex("primary")};
    }
  `
};

const getAmount = (region: FoundLocation, search: Region[]) => {
  let amount = 0;
  if (region) {
    search.forEach((r) => {
      //console.log(r.region, region.region);
      if (r.region === region.region) {
        amount = r.amount;
      }
    });
  }
  return amount;
};

const Rec = ({
  region,
  regions
}: {
  region?: FoundLocation;
  regions: Region[];
}) => {
  //console.log(region, regions)
  const amount = getAmount(region, regions);
  return (
    <Styled.Rec>
      <span>
        {amount} inch{amount === 1 ? "" : "es"}
      </span>
    </Styled.Rec>
  );
};

export const Recommendation = () => {
  const data: WmyData = useStaticQuery(query);
  const { regions } = data;
  const search = regions.edges.map((e) => e.node);
  const block: PageBlocks = {
    copy: undefined,
    title: "Your Weekly Watering Recommendation",
    hideTitle: true,
    showBottomDivider: false,
    dividerPosition: null,
    pages: [],
    waveOptions: "Bottom" as const,
    style: "Blue Background" as const
  };
  return (
    <Body block={block}>
      <LocationContext.Consumer>
        {({ doLocCheck, start, userRegion, isLoading }) => {
          return start ? (
            isLoading ? (
              <Row className="flex-column">
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: "initial"
                  }}>
                  <Loader />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: "initial"
                  }}>
                  Loading...
                </Col>
              </Row>
            ) : userRegion ? (
              <Row className="flex-column">
                <Col>
                  <WidgetTitle>Your Weekly Watering Recommendation</WidgetTitle>
                </Col>
                <Col>
                  <Rec
                    region={
                      typeof userRegion !== "string" ? userRegion : undefined
                    }
                    regions={search}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="flex-column">
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: "initial"
                  }}>
                  <WidgetSubTitle>
                    You are outside the service area of the Upper Trinity Water
                    District. Please visit:
                  </WidgetSubTitle>
                </Col>

                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: "initial"
                  }}>
                  <UButton white to="https://watermyyard.org" target="blank">
                    WaterMyYard.org
                  </UButton>
                </Col>
              </Row>
            )
          ) : (
            <Row className="flex-column">
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "initial"
                }}>
                <WidgetTitle>Your Weekly Watering Recommendation</WidgetTitle>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "initial"
                }}>
                <UButton white action={doLocCheck}>
                  Get My Recommendation
                </UButton>
              </Col>
            </Row>
          );
        }}
      </LocationContext.Consumer>
    </Body>
  );
};

interface Region {
  region: string;
  amount: number;
}

interface WmyData {
  regions: {
    edges: {
      node: Region;
    }[];
  };
}

const query = graphql`
  {
    regions: allContentfulWaterMyYard {
      edges {
        node {
          amount
          region
        }
      }
    }
  }
`;
