import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import NameWave from "./BODNameplateWaveImg";
import { colors } from "../../../../../../vars/palette";

const NamePlateWrap = styled(Row)`
  width: 100%;
  max-width: 25vw;
  margin: auto;
  position: relative;

  @media only screen and (max-width: 992px) {
    max-width: 50vw;
  }
  @media only screen and (max-width: 768px) {
    max-width: 90vw;
  }
  .name-row > .col {
    background: ${colors.hex("white")};
  }
  .name-row > .col {
    display: flex;
    justify-content: center;
    text-align: center;
    color: ${colors.hex("secondary")};
    line-height: 1.2;
    // next two lines fix safari's issues with flexbox (without them, the nameplates are disjointed and you can't see the text on the nameplate)
    -webkit-flex: 0;
    flex-shrink: 0;
  }
  .name-row > .col:first-child {
    font-size: 1.125rem;
    padding: 0;
  }
  .name,
  .job,
  .years {
    line-height: 1.1;
  }
  .years {
  }
  .name {
  }
  .job {
  }
  .wave {
    padding: 0;
    flex-grow: 0;
    position: relative;
    top: 1px;
    z-index: 5;
  }
  .name-shadow {
    position: absolute;
    top: 10%;
    left: 0;
    height: 90%;
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }
`;

const Nameplate = ({
  name,
  job,
  style,
  years,
  shadow
}: {
  name: string;
  job: string;
  style?: React.CSSProperties;
  years?: string;
  shadow?: boolean;
}) => {
  const hasYears = years && years !== "";

  const midRowStyle = { padding: `5px 0 0 0` };
  const endRowStyle = {
    // marginTop: "-8px",
    // padding: `0 0 12px 0`,
    fontSize: "1rem"
  };

  const rowStyle = {
    ...style,
    //minHeight: hasYears ? "140px" : "120px",
    justifyContent: "flex-start"
    //boxShadow: hasYears ? "3px 4px 6px rgba(0,0,0,.2)" : "none",
  };

  return (
    <NamePlateWrap className="flex-column" style={rowStyle}>
      <Col className="wave">
        <NameWave />
      </Col>
      <Col style={{ borderBottom: `1.5rem solid white` }}>
        <Row className="flex-column name-row">
          <Col className="name" style={midRowStyle}>
            <strong style={{ paddingTop: ".5rem" }}>{name}</strong>
          </Col>
          <Col className="job" style={hasYears ? midRowStyle : endRowStyle}>
            {job}
          </Col>
          {hasYears && <Col className="years">{years}</Col>}
        </Row>
      </Col>
      {shadow && <div className="name-shadow" />}
    </NamePlateWrap>
  );
};

export default Nameplate;
