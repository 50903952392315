import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { WmkLink } from "wmk-link";
import { graphql, useStaticQuery } from "gatsby";
import { colors } from "../../vars/palette";
import { Page, PageBlocks } from "../../classes/Page";
import { MenuDropdownContainerFields } from "../../node/fragments/NodeMenuContainerFields";

const Styled = {
  Container: styled(Container)`
    background: ${({ isblue }) => (isblue ? colors.hex("secondary") : "none")};
    //box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  `,
  BreadCrumbs: styled(Row)`
    padding: 2vh;
    justify-content: center;
  `,
  Crumb: styled(Col)`
    border: 1px solid ${colors.hex("silver")};
    display: inline-block;
    padding: 0.75vh 2vh;
    svg {
      margin-right: 0.5vh;
    }
    transition: all 0.3s ease;
    &:not(:first-child) {
      border: 1px solid ${colors.hover("silver", 0.2, "flip")};
      border-left: none;
      background-color: ${colors.hover("silver", 0.2, "flip")};
      &:hover {
        transition: all 0.45s ease;
        a {
          color: ${colors.hex("accent")};
        }
      }
      a {
        color: ${colors.hex("secondary")};
        text-decoration: none;
      }
      &.active {
        background-color: ${colors.hex("white")};
        border: 1px solid ${colors.hover("silver", 0.2, "flip")};
        color: ${colors.hex("text")};
        border-top-right-radius: 1vh;
        border-bottom-right-radius: 1vh;
        border-left: none;
      }
      &.parent {
        border: 1px solid ${colors.hover("silver", 0.2, "flip")};
        color: ${colors.hex("text")};
      }
      &.sub-parent {
      }
    }
    &:first-child {
      padding: 0.75vh 2vh 0.75vh 3vh;
      background-color: ${({ isblue }) =>
        isblue ? colors.hex("primary") : colors.hex("secondary")};
      border-color: ${colors.hover("secondary")};
      border-top-left-radius: 1vh;
      border-bottom-left-radius: 1vh;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      &:hover {
        background-color: ${colors.hover("secondary")};
        transition: all 0.45s ease;
      }
      a {
        color: ${colors.hex("white")};
        text-decoration: none;
      }
    }
  `
};
const BreadCrumbs = ({
  pageData,
  firstBlock
}: {
  pageData: Page;
  firstBlock?: PageBlocks;
}) => {
  const { mainMenu }: { mainMenu: MenuDropdownContainerFields } =
    useStaticQuery(query);
  const menu: MenuDropdownContainerFields[] = mainMenu.menuItems.reduce(
    (dropdown, menuItem) => {
      if (menuItem.__typename === "ContentfulMenuDropdownContainer") {
        dropdown.push(menuItem);
      }
      return dropdown;
    },
    []
  );
  const { parentPageSlug, subParentPageSlug, title } = pageData;
  const isblue =
    firstBlock && firstBlock.style && firstBlock.style === "Blue Background";
  const getBreadCrumbParent = (parent: string) => {
    const title =
      menu.length && menu.map((t) => (t.slug === parent ? t.title : ""));
    const _title = title.join("");
    return _title !== "" ? _title : null;
  };
  const getBreadCrumbSub = (sub: string) => {
    let subTitle = "";
    menu.map((m) =>
      m.menuItems.forEach((i) => {
        if (i.slug === sub) {
          subTitle = i.title;
        }
      })
    );
    return subTitle;
  };
  const parent = getBreadCrumbParent(parentPageSlug);
  return (
    <Styled.Container fluid isblue={isblue ? "true" : undefined}>
      <Styled.BreadCrumbs>
        <Styled.Crumb xs="auto">
          <WmkLink to="/">
            <FaHome /> Home
          </WmkLink>
        </Styled.Crumb>
        {parent && (
          <Styled.Crumb xs="auto" className="parent">
            {parent}
          </Styled.Crumb>
        )}
        {subParentPageSlug && (
          <Styled.Crumb xs="auto" className="sub-parent">
            <WmkLink to={"/" + parentPageSlug + "/" + subParentPageSlug}>
              {getBreadCrumbSub(subParentPageSlug)}
            </WmkLink>
          </Styled.Crumb>
        )}
        <Styled.Crumb xs="auto" className="active">
          {title}
        </Styled.Crumb>
      </Styled.BreadCrumbs>
    </Styled.Container>
  );
};

export default BreadCrumbs;

const query = graphql`
  {
    mainMenu: contentfulMenuContainer(title: { eq: "Main Menu" }) {
      ...NodeMenuContainerFields
    }
  }
`;
