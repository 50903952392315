import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Nameplate from "../Nameplate/BODNameplate";
import { colors } from "../../../../../../vars/palette";
import { Img, WmkImage } from "wmk-image";
import { BoardMemberQuery } from "../BoardOfDirectors";

const EDWrap = styled(Row)`
  & > .col {
    display: flex;
    justify-content: center;
    // next two lines fix safari's issues with flexbox (without them, the nameplate overlaps the headshot and the "Board Meetings" section gets pulled up too high)
    -webkit-flex: 0;
    flex-shrink: 0;
  }
`;

const ExecutiveDirector = ({ ed }: { ed: BoardMemberQuery }) => {
  const { name, type, headshot } = ed;
  return (
    <Container fluid style={{ background: colors.hex("secondary") }}>
      <Row className="flex-column">
        <Col style={{ marginTop: "40px" }}>
          <EDWrap className="flex-column">
            {headshot && (
              <Col>
                <WmkImage
                  image={new Img(headshot)}
                  style={{ borderRadius: "50%" }}
                />
              </Col>
            )}
          </EDWrap>
        </Col>
        <Col
          style={{
            position: "relative",
            bottom: "60px",
            marginBottom: "-60px",
            display: "flex",
            justifyContent: "center"
          }}>
          <Nameplate name={name} job={type} />
        </Col>
      </Row>
    </Container>
  );
};

export default ExecutiveDirector;
