import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Timestamp from "./Timestamp";
import WidgetSubTitle from "../../../common/typography/WidgetSubTitle";
import GraphWave from "./GraphWave";
import styled from "styled-components";
import DivBot from "../../../flexible/blocks/BlockBody/Divider/DivBot";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { colors } from "../../../../vars/palette";
import { LakeFields } from "./LakeLevels";

const LakeWrap = styled(Row)``;

export interface LakeProperties {
  short_name: string;
  timestamp: string;
  conservation_pool_elevation: string;
  elevation: string;
  conservation_capacity: string;
  conservation_storage: string;
}

const LakeLevel = ({ lake }: { lake: LakeFields }) => {
  const {
    short_name,
    timestamp,
    conservation_pool_elevation,
    elevation,
    conservation_capacity,
    conservation_storage
  } = lake.properties;
  return (
    <LakeWrap className="flex-column">
      <Col>
        <WidgetSubTitle
          gold
          style={{ textShadow: "none", margin: 0, lineHeight: 1, padding: 0 }}>
          {short_name} Lake
        </WidgetSubTitle>
      </Col>
      <Col>
        <h5
          style={{
            color: colors.hex("primary"),
            fontWeight: "bold",
            textAlign: "center",
            margin: 0,
            lineHeight: 2
          }}>
          {lake.loc}
        </h5>
      </Col>
      <Col>
        <Timestamp timestamp={timestamp} />
      </Col>
      <Col>
        <DivBot pos="Center" />
      </Col>
      <Col>
        <LakeGraph
          capacity={conservation_capacity}
          storage={conservation_storage}
        />
      </Col>
      <Col
        style={{ paddingTop: "2rem", textAlign: "right", fontSize: ".9rem" }}>
        Water Level:{" "}
        <span style={{ color: colors.hex("primary"), fontWeight: "bold" }}>
          {elevation}ft
        </span>
      </Col>
      <Col style={{ textAlign: "right", fontSize: ".9rem" }}>
        Height Above Conservation Pool:{" "}
        <span style={{ color: colors.hex("accent"), fontWeight: "bold" }}>
          <HeightAbovePool
            level={elevation}
            pool={conservation_pool_elevation}
          />
        </span>
      </Col>
    </LakeWrap>
  );
};

export default LakeLevel;

const HeightAbovePool = ({ level, pool }: { level: string; pool: string }) => {
  const height = Number(level) - Number(pool);
  return <>{height.toFixed(2)}ft</>;
};

const LakeGraph = ({
  storage,
  capacity
}: {
  storage: string;
  capacity: string;
}) => {
  const [height, setHeight] = useState(15);
  const [scrollPos, setScrollPos] = useState(0);
  const decimal = (Number(storage) / Number(capacity)) * 100;
  const percent = decimal === 100 ? decimal : decimal.toFixed(1);
  //const height = 400;
  const refGraph = useRef<HTMLDivElement>();

  useEffect(() => {
    const top = refGraph.current.getBoundingClientRect().top;
    if (top < 350) {
      setHeight(400);
    }
  }, [scrollPos]);

  useScrollPosition(({ currPos }) => {
    setScrollPos(currPos.y);
  });
  return (
    <div
      style={{
        height,
        minHeight: 400,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        //background: colors.hex("tertiary"),
        position: "relative"
      }}
      ref={refGraph}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
        <span
          style={{
            color: percent > 60 ? colors.hex("white") : colors.hex("primary"),
            fontSize: "6vh",
            fontWeight: "bold"
          }}>
          {percent}%
        </span>
      </div>
      <GraphWave percent={Number(percent)} height={height} speed={1.5} />
    </div>
  );
};
