import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { MenuItemFields } from "../../node/fragments/NodeMenuItemFields";
import { colors } from "../../vars/palette";
import { buildSlug } from "../../vars/ThemeOptions";

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  a {
    background: ${colors.hex("secondary")};
    color: ${colors.hex("white")};
    text-decoration: none;
    &:hover {
      background: ${colors.hover("secondary", 0.4)};
    }
    border: 0;
    border-radius: 10px;
    padding: 5px 2rem;
  }
`;

const BlockButton = ({ button }: { button: MenuItemFields }) => {
  const { title, asset, entry, target, type, url } = button;
  const isInternal = type === "Internal";
  const isAsset = asset ? true : false;
  const _target = isInternal ? undefined : target;
  const _to = isInternal ? buildSlug(entry) : isAsset ? asset.file.url : url;

  return (
    <Wrap>
      <WmkLink
        to={_to}
        target={_target}
        label={entry ? "Read more about " + entry.title : title}>
        {title}
      </WmkLink>
    </Wrap>
  );
};

export default BlockButton;
