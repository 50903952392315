import React from "react";
import { Container } from "react-bootstrap";
import Wave from "../../../flexible/blocks/BlockBody/Wave/Wave";
import ExcerptsCategory from "./ExcerptsCategory";
import WidgetSubTitle from "../../../common/typography/WidgetSubTitle";
import { Row } from "react-bootstrap";
import { colors } from "../../../../vars/palette";
import { NewsPost } from "../../../../classes/News";

const NewsExcerpts = ({
  newsGroups
}: {
  newsGroups: { title: string; news: NewsPost[] }[];
}) => {
  return (
    <>
      <Wave wave="Top" target="top" />
      <Container fluid style={{ background: colors.hex("secondary") }}>
        {newsGroups && newsGroups.length ? (
          newsGroups.map((n) => {
            const { title, news } = n;
            return (
              <Row
                key={title}
                className="flex-column"
                style={{ padding: "4vh 0" }}>
                <WidgetSubTitle>{title}</WidgetSubTitle>
                <ExcerptsCategory categories={news} />
              </Row>
            );
          })
        ) : (
          <em>No posts available at this time.</em>
        )}
      </Container>
    </>
  );
};

export default NewsExcerpts;
