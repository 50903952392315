import React from "react";
import { colors } from "../../../../vars/palette";

const GraphWave = ({
  percent,
  height,
  speed
}: {
  percent: number;
  height: number;
  speed: number;
}) => {
  const h = height * (percent / 100);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={h}
      width={259}
      style={{
        transition: `all ${speed}s ease`
      }}
      //viewBox={`0 0 259 ${h}`}
    >
      <path
        fill={colors.hex("secondary")}
        d={`M256.48,5.27l-1.63-.9C250.5,1.93,247.06,0,239.49,0s-11,1.93-15.36,4.37l-1.63.9a37.92,37.92,0,0,1-20.09,5.13,37.92,37.92,0,0,1-20.09-5.13l-1.63-.9C176.33,1.93,172.9,0,165.32,0s-11,1.93-15.36,4.37l-1.63.9a37.92,37.92,0,0,1-20.09,5.13,37.92,37.92,0,0,1-20.09-5.13l-1.63-.9C102.17,1.93,98.73,0,91.16,0s-11,1.93-15.36,4.37l-1.63.9A37.92,37.92,0,0,1,54.08,10.4,38,38,0,0,1,34,5.27l-1.62-.9C28,1.93,24.57,0,17,0S6,1.93,1.63,4.37L0,5.27V${h}H259V6.6C258.14,6.17,257.29,5.72,256.48,5.27Z`}
      />
    </svg>
  );
};

export default GraphWave;
