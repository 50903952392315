import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Wave from "../../../flexible/blocks/BlockBody/Wave/Wave";
import WidgetSubTitle from "../../../common/typography/WidgetSubTitle";
import FeaturedMain from "./FeaturedMain";
import FeaturedRecent from "./RecentReleases";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";
import { NewsPost } from "../../../../classes/News";

const MainWrap = styled(Container)`
  position: absolute;
  top: 0;
`;

const FeaturedNews = ({
  featured,
  releases,
  mediaContactHeight
}: {
  featured: NewsPost;
  releases: NewsPost[];
  mediaContactHeight: number;
}) => {
  const [newsHeight, setNewsHeight] = useState(0);
  const [winWidth, setWinWidth] = useState(0);

  const newsRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleResize = () => {
      const height =
        winWidth > 768
          ? newsRef.current.offsetHeight - mediaContactHeight / 1.25
          : newsRef.current.offsetHeight;
      setNewsHeight(height);
      setWinWidth(window.outerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [newsHeight, mediaContactHeight, winWidth]);
  return (
    <>
      <Container
        fluid
        style={{ background: colors.hex("secondary"), position: "relative" }}>
        <div style={{ height: newsHeight }} />
        <MainWrap ref={newsRef}>
          <WidgetSubTitle style={{ padding: "4vh 0" }}>
            UTRWD NEWS
          </WidgetSubTitle>
          <Row>
            <Col sm={12} md={7} lg={8}>
              <FeaturedMain newsItem={featured} />
            </Col>
            <Col sm={12} md={5} lg={4}>
              <FeaturedRecent news={releases} />
            </Col>
          </Row>
        </MainWrap>
      </Container>
      <Wave wave="Bottom" target="bottom" />
    </>
  );
};

export default FeaturedNews;
