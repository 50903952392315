import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle
} from "react-icons/io";
import { colors } from "../../../../../vars/palette";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { SprinklerFields } from "../../../../../node/fragments/NodeSprinklersFields";

const RoundImage = styled(WmkImage)`
  border-radius: 50%;
`;

const FlexCenterRow = styled(Row)`
  position: relative;
  .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  overflow: hidden;
  svg {
    max-width: 64px;
  }
`;

const Table = ({ children }: { children: React.ReactNode }) => {
  return <Container className="flex-column">{children}</Container>;
};

const SprinklerIcon = ({
  icon,
  index
}: {
  icon: ContentfulImageQuery;
  index: number;
}) => {
  const maxWidth = index === 2 ? "64px" : "48px";
  return <WmkImage image={new Img(icon)} style={{ maxWidth }} />;
  //<img src={icon.url()} alt={icon.title} style={{ maxWidth }} />;
};

const Rate = ({ rate }: { rate: string }) => {
  return (
    <Col
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "1.5vh 0",
        textAlign: "center"
      }}>
      <span
        style={{
          border: `1px solid ${colors.hex("primary")}`,
          padding: ".3vh 1.5vh"
        }}>
        Average precipitation rate: {rate}"/hour
      </span>
    </Col>
  );
};

const TH = styled(Col)`
  border: none !important;
  color: ${colors.hex("secondary")};
  text-align: center;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 1.1;
  font-size: 0.9rem;
`;

const TR = ({ children }: { children: React.ReactNode }) => (
  <Row>{children}</Row>
);

const TDWrap = styled(Col)`
  font-weight: 600;
  color: ${colors.hex("accent")};
  text-align: ${({ left }) => (left ? "left" : "center")};
  border: none !important;
  span {
    margin-left: 0.5vh;
    display: inline-block;
  }
  font-size: 3.5vh;
  span {
    font-size: 3.5vh;
  }
`;

const StyledDot = styled.div`
  height: 2.5vh;
  width: 2.5vh;
  display: inline-block;
  background: ${colors.hex("primary")};
  border-radius: 50%;
  margin: 0.25vh;
  position: relative;
  top: 0.33vh;
`;

const Cycle = ({
  children,
  left,
  sm
}: {
  children: React.ReactNode;
  left?: "true";
  sm: number;
}) => {
  const num =
    typeof children === "string" ? parseInt(children) : Number(children);
  const arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(i);
  }

  return (
    <TDWrap sm={sm} style={{ alignItems: "center" }} left={left}>
      {arr && arr.length
        ? arr.map((dot) => <StyledDot key={"dot" + dot} />)
        : null}
      <span>{children}</span>
    </TDWrap>
  );
};

const Donut = ({
  children,
  sm,
  xs
}: {
  children: React.ReactNode;
  sm: number;
  xs?: number;
}) => {
  const _children = Number(children);

  const data = {
    // labels: ["Minutes", "Minutes"],
    datasets: [
      {
        data: [_children / 60, 1],
        backgroundColor: [colors.hex("primary")],
        borderWidth: [0, 0],
        hoverBorderWidth: [0, 0],
        hoverBackgroundColor: [colors.hex("secondary")]
      }
    ]
  };
  return (
    <Col sm={sm} xs={xs} style={{ position: "relative" }}>
      <Doughnut data={data} options={{ cutoutPercentage: 85 }} />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: ".25rem",
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: colors.hex("accent"),
          fontWeight: 600
        }}>
        <span style={{ fontSize: "3.5vh" }}>{children}</span>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            background: colors.hex("white"),
            borderRadius: "50%",
            zIndex: -1
          }}
        />
      </div>
    </Col>
  );
};

const TD = ({
  children,
  type,
  left,
  sm,
  xs
}: {
  children: React.ReactNode;
  type?: string;
  left?: boolean;
  sm?: number;
  xs?: number;
}) => {
  const isPie = type === "pie";
  const isCycle = type === "cycle";
  const small = sm ? sm : xs;
  return isPie ? (
    <Donut sm={small} xs={xs}>
      {children}
    </Donut>
  ) : isCycle ? (
    <Cycle left={left ? "true" : undefined} sm={small}>
      {children}
    </Cycle>
  ) : (
    <TDWrap sm={small} xs={xs}>
      {children}
    </TDWrap>
  );
};

const OvalWrap = styled.div`
  position: absolute;
  top: -15vh;
  width: 120%;
  height: 25vh;
  background: white;
  border-radius: 50%;
  z-index: 1;
`;

const Oval = () => {
  return (
    <OvalWrap>
      <div />
    </OvalWrap>
  );
};

const RecNavBtn = styled.button`
  appearance: none;
  background: none;
  //opacity: 0.85;
  border: none;
  font-size: 5.5vh;
  svg {
    color: ${colors.hex("secondary")};
    transition: all 0.3s ease;
  }
  @media only screen {
    :focus {
      outline: none;
    }
  }
  &:hover {
    svg {
      color: ${colors.hex("accent")};
      transition: all 0.3s ease;
    }
  }
`;

const Sprinkler = ({
  sprinkler,
  index
}: {
  sprinkler: SprinklerFields;
  index: number;
}) => {
  const [winWidth, setWinWidth] = useState(0);
  const [activeSprinkler, setActiveSprinkler] = useState(0);
  const { recs, title, perZone, image, icon, rate } = sprinkler;
  const headers = {
    amount: "Amount Recommended in Inches",
    perZone: "Zone Runtime in Minutes",
    cycles: "No. Of Cycles Per Zone",
    total: "Total Runtime Per Zone"
  };
  const labels = [
    { sm: 2, children: "Amount Recommended in Inches", type: "amount" },
    { sm: 3, children: "Zone Runtime in Minutes" },
    { sm: 4, children: "No. Of Cycles Per Zone" },
    { sm: 3, children: "Total Runtime Per Zone" }
  ];
  const fixed = new Img(image);
  useEffect(() => {
    const resize = () => {
      setWinWidth(window.innerWidth);
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [winWidth]);
  return (
    <>
      <FlexCenterRow>
        <Oval />
        {winWidth > 760 && (
          <Col
            style={{
              display: "flex",
              position: "relative",
              zIndex: 2,
              flex: "initial",
              justifyContent: "flex-end"
            }}
            sm={4}>
            <SprinklerIcon icon={icon} index={index} />
          </Col>
        )}
        {fixed && (
          <Col style={{ position: "relative", zIndex: 2 }}>
            <RoundImage image={fixed} />
          </Col>
        )}
        {winWidth > 760 && (
          <Col
            style={{
              display: "flex",
              flex: "initial",
              justifyContent: "flex-start",
              position: "relative",
              zIndex: 2,
              alignItems: "center",
              fontWeight: 600,
              color: colors.hex("primary")
            }}
            sm={4}>
            {title}
          </Col>
        )}
      </FlexCenterRow>
      <Row>
        <Rate rate={rate} />
      </Row>
      <Row style={{ padding: "3vh" }}>
        {winWidth > 760 ? (
          <Table>
            <TR>
              {labels.map((l) => (
                <TH key={l.children} {...l} />
              ))}
            </TR>
            <TR>
              {recs.map((r) => {
                const { cycles, amount } = r;
                const content = [
                  { sm: 2, children: amount, label: "amount" },
                  {
                    sm: 3,
                    type: "pie",
                    children: perZone,
                    label: "perZone"
                  },
                  {
                    sm: 4,
                    left: true,
                    type: "cycle",
                    children: cycles,
                    label: "cycles"
                  },
                  {
                    sm: 3,
                    type: "pie",
                    children: Number(perZone) * Number(cycles),
                    label: "total"
                  }
                ];
                return content.map((c, i) => (
                  <TD key={"td-" + c.sm + i} {...content[i]} />
                ));
                // </TR>
              })}
            </TR>
          </Table>
        ) : (
          recs.map((row, i) => {
            const { cycles, amount } = row;
            return (
              i === activeSprinkler && (
                <Container key={"rec" + i} style={{ padding: "4vh" }}>
                  <TR>
                    <TH xs={6}> {headers.amount}</TH>
                    <TH xs={6}>{headers.perZone}</TH>
                  </TR>
                  <TR>
                    <TD xs={6}>{amount}</TD>
                    <TD xs={6} type="pie">
                      {perZone}
                    </TD>
                  </TR>
                  <TR>
                    <TH xs={6}>{headers.cycles}</TH>
                    <TH xs={6}>{headers.total}</TH>
                  </TR>
                  <TR>
                    <TD xs={6} type="cycle">
                      {cycles}
                    </TD>
                    <TD xs={6} type="pie">
                      {Number(perZone) * Number(cycles)}
                    </TD>
                  </TR>
                  <Row>
                    <Col
                      xs={6}
                      style={{
                        display: "flex",
                        flex: "initial",
                        justifyContent: "flex-end"
                      }}>
                      {i >= 1 ? (
                        <RecNavBtn
                          onClick={() => setActiveSprinkler(i - 1)}
                          aria-label="previous recommendation">
                          <IoIosArrowDropleftCircle />
                        </RecNavBtn>
                      ) : null}
                    </Col>
                    <Col xs={6}>
                      {" "}
                      {i < recs.length - 1 ? (
                        <RecNavBtn
                          onClick={() => setActiveSprinkler(i + 1)}
                          aria-label="next recommendation">
                          <IoIosArrowDroprightCircle />
                        </RecNavBtn>
                      ) : null}
                    </Col>
                  </Row>
                </Container>
              )
            );
          })
        )}
      </Row>
    </>
  );
};

export default Sprinkler;
