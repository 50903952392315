import React from "react";
import styled from "styled-components";
import WaterTreatment from "../component-utrwd-treatment-graphic/WaterTreatment";

const Wrap = styled.div``;

const WaterTreatmentDeliveryPage = () => {
  return (
    <Wrap>
      <WaterTreatment />
    </Wrap>
  );
};

export default WaterTreatmentDeliveryPage;
