import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from "../images/Aeration_Biological_Treatment.png"

const step4IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step4 {
    height: 8vw;
    top: 55%;
    left: 41%;
    &.open {     
      z-index: 150 !important;
      border-right: 6px solid white !important;
      width: 55%;
      top: 38%; 
      clip-path: polygon(15% 0%, 0% 100%, 100% 100%, 100% 0%);
      transition: top .75s ease, width .5s ease .75s, clip-path .5s ease .75s, border-right .5s ease .75s;
    }
    &.closed {
      border-right: 0;
      width: 9.5vw;
      top: 55%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .65s ease, border-right .5s ease, clip-path .65s ease, top 1s ease .65s;
    }
    .info-wrap {
      .title-only {
        p {
          font-size: .8rem;
          line-height: 13px;
        }        
      }
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 5px 0px 1rem 5.5vw;
        .icon {
          margin-right: 1rem;
          margin-top: .65rem;
          img {
            width: 5rem;
            height: 4rem;
          }
          img.open {
            animation: ${step4IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          // width: 75%;
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 16px;
            font-size: .9rem;
          }
        }
      }  
    }
  }
  .step4-line1 {
    width: 4px;
    top: 48%;
    right: 54%;
    height: 12%;
    transition: height .25s ease-in .65s;
    &.open {
      height: 0%;
      transition: height .75s ease;
    }
  }
  .circle-step4 {
    top: 45%;
    right: 52.4%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (min-width: 783px) and (max-width: 1200px) {
    .click-step4 {
      left: 40.3%;
      &.open {
        width: 58%;
      }
      .title-only {
        margin-top: -.5rem;
        p {
          font-size: .7rem !important;
          line-height: 12px !important;          
        }
      }
      .full-info-wrap {
        padding: 5px 0px 1rem 5.5vw !important;
        .icon {}
        img {
          margin-right: 0rem !important;
          margin-top: .5rem !important;
          width: 3rem !important;
          height: 2rem !important; 
        }
        .title-and-copy {
          margin-top: -5px;
          .title {
            margin-bottom: 1px !important;
            font-size: .9rem !important;
          }
          .copy {
            line-height: 11px !important;
            font-size: .7rem !important;
          }
        }
      }
    }    
    .step4-line1 {
      right: 53.6%;
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step4 {
      left: 39.7%;
      &.open {
        width: 60%;
      }
      .title-only {
        margin-top: -1rem;
        p {
          font-size: .5rem !important;
          line-height: 9px !important;
        }
      }
      .full-info-wrap {
        padding: 2px 0px 1rem 5.5vw !important;
        .title-and-copy {
          margin-top: -5px;
          .title {
            margin-bottom: -3px !important;
            font-size: .8rem !important;
          }
          .copy {
            line-height: 9px !important;
            font-size: .6rem !important;
          }
        }
      }
      .icon {
        margin-right: .25rem !important;
        margin-top: .4rem !important;
        img {
          width: 2rem !important;
          height: 1.5rem !important;
        }
      }
    }    
    .step4-line1 {
      right: 54.2%;
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step4 {
      left: 35.8%;
      &.open {
        top: 36%;
        width: 63%;
      }
      .title-only {
        margin-top: -1rem;
        p {
          font-size: .6rem !important;
          line-height: 10px !important;
        }
      }
      .full-info-wrap {
        padding: 2px 0px 1rem 5.5vw !important;
        .title-and-copy {
          margin-top: -5px;
          .title {
            margin-bottom: -3px !important;
            font-size: .8rem !important;
          }
          .copy {
            line-height: 9px !important;
            font-size: .6rem !important;
          }
        }
      }
      .icon {
        margin-right: .25rem !important;
        margin-top: 1rem !important;
        img {
          width: 2rem !important;
          height: 1.5rem !important;
        }
      }
    }    
    .step4-line1 {
      right: 54.2%;
    }
  }
`
 
const Step4 = ({ currStep, updateCurrStep, newStep, closeSteps }) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => currStep === 4 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  const buttonClick = (fullInfo) => {
    updateCurrStep(4);
  }
  const info = {
    icon: Icon, 
    title: {
      full: 'Aeration',
      piece1: 'Aeration/',
      piece2: 'Biological',
      piece3: 'Treatment'
    }, 
    copy: 'Wastewater receives the majority of its treatment in this step. Beneficial microorganisms consume the organic matter and transform it into cell tissues, water, and nitrogen. Infused air bubbles provide the right environment for the microorganisms.'
  };

  const buttonClosed = `click-step click-step4 closed`;
  const buttonOpen = `click-step click-step4 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step4-line1 open`;
  const stepLineOneClosed = `step-line step4-line1`;
  const circleClosed = `circle-step circle-step4`;
  const circleOpen = `circle-step circle-step4 open`;
  
  return (
    <Wrap>
      <button className={currStep === 4 ? buttonOpen : buttonClosed} onClick={() => buttonClick(fullInfo)}>
        <div className={currStep === 4 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {marginTop: '2.5rem'} : {marginTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 4 ? {opacity: 1, transition: 'opacity .75s ease .25s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 4 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
              </div>
          : <div className="title title-only" style={currStep !== 4 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
              <p>{info.title.piece3}</p>
            </div>
          }
        </div>
      </button>      
      <div className={currStep === 4 ? circleOpen : circleClosed}>4</div>
      <div className={currStep === 4 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  )
}
 
export default Step4