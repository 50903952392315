import React, { useRef, useEffect } from "react";
import WidgetSubTitle from "../../common/typography/WidgetSubTitle";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { WmkLink } from "wmk-link";
import styled from "styled-components";
import { colors } from "../../../vars/palette";

const CenteredCol = styled(Col)`
  text-align: center;
  padding: 0 2rem;
  a {
    color: ${colors.hex("text")};
    &:hover {
      text-decoration: none;
      color: ${colors.hex("accent")};
    }
  }
`;

const MediaContact = ({ setHeight }: { setHeight: Function }) => {
  const { contact } = useStaticQuery(query);
  const name = contact.addressLine1;
  const job = contact.addressLine2;
  const { email, phoneNumber } = contact;

  const mediaRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleResize = () => {
        setHeight(mediaRef?.current?.offsetHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setHeight]);
  return (
    <Container ref={mediaRef}>
      <Row style={{ justifyContent: "flex-end", width: "100%" }}>
        <Col sm={12} md={5} lg={4}>
          <Row className="flex-column">
            <Col>
              <WidgetSubTitle blue>
                For general media inquiries, please contact:
              </WidgetSubTitle>
            </Col>
            <CenteredCol>
              <span
                style={{
                  color: colors.hex("secondary"),
                  fontWeight: "bold",
                  fontSize: "1.33rem"
                }}>
                {name}
              </span>
            </CenteredCol>
            <CenteredCol>
              <span style={{ color: colors.hex("secondary") }}>{job}</span>
            </CenteredCol>
            <CenteredCol>
              <WmkLink tel>{phoneNumber}</WmkLink>
            </CenteredCol>
            <CenteredCol>
              <WmkLink mailto>{email}</WmkLink>
            </CenteredCol>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MediaContact;

export const query = graphql`
  query {
    contact: contentfulGlobal(title: { eq: "UTRWD Media Contact" }) {
      ...NodeGlobalFields
    }
  }
`;
