import React from "react";

export const IconMulti = () => {
  return (
    <svg viewBox="0 0 95.54 91.88">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon
            className="cls-1"
            points="73 33 77.54 33 77.54 37.54 73 37.54 73 33 73 33"
          />
          <polygon
            className="cls-1"
            points="91 42.17 95.54 42.17 95.54 46.71 91 46.71 91 42.17 91 42.17"
          />
          <polygon
            className="cls-1"
            points="82.08 37.54 86.62 37.54 86.62 42.08 82.08 42.08 82.08 37.54 82.08 37.54"
          />
          <polygon
            className="cls-1"
            points="73 15.17 77.54 15.17 77.54 19.71 73 19.71 73 15.17 73 15.17"
          />
          <polygon
            className="cls-1"
            points="91 6 95.54 6 95.54 10.54 91 10.54 91 6 91 6"
          />
          <polygon
            className="cls-1"
            points="82.08 10.63 86.62 10.63 86.62 15.17 82.08 15.17 82.08 10.63 82.08 10.63"
          />
          <path
            className="cls-2"
            d="M28.69,87.34,31,82.79H64.79l2.27,4.55Zm7.68-18.17h23v9.08h-23V69.17Zm23-27.55v23h-23v-23ZM40.91,32.54H54.84v4.54H40.91V32.54ZM36.37,25a32.9,32.9,0,0,1,23,0v3h-23V25ZM67.59,78.25H63.92V37.08H59.38V32.54h4.54V22a37.94,37.94,0,0,0-32.09,0V32.54h4.54v4.54H31.83V78.25H28.15l-4.54,9.09H9.12v4.54h77.5V87.34H72.13l-4.54-9.09Z"
          />
          <polygon
            className="cls-1"
            points="73 26.93 77.54 26.93 77.54 31.47 73 31.47 73 26.93 73 26.93"
          />
          <polygon
            className="cls-1"
            points="91 26.93 95.54 26.93 95.54 31.47 91 31.47 91 26.93 91 26.93"
          />
          <polygon
            className="cls-1"
            points="82.08 26.93 86.62 26.93 86.62 31.47 82.08 31.47 82.08 26.93 82.08 26.93"
          />
          <polygon
            className="cls-1"
            points="73 21.18 77.54 21.18 77.54 25.72 73 25.72 73 21.18 73 21.18"
          />
          <polygon
            className="cls-1"
            points="91 21.18 95.54 21.18 95.54 25.72 91 25.72 91 21.18 91 21.18"
          />
          <polygon
            className="cls-1"
            points="82.08 21.18 86.62 21.18 86.62 25.72 82.08 25.72 82.08 21.18 82.08 21.18"
          />
          <polygon
            className="cls-1"
            points="22.54 33 18 33 18 37.54 22.54 37.54 22.54 33 22.54 33"
          />
          <polygon
            className="cls-1"
            points="4.54 42.17 0 42.17 0 46.71 4.54 46.71 4.54 42.17 4.54 42.17"
          />
          <polygon
            className="cls-1"
            points="13.46 37.54 8.92 37.54 8.92 42.08 13.46 42.08 13.46 37.54 13.46 37.54"
          />
          <polygon
            className="cls-1"
            points="22.54 15.17 18 15.17 18 19.71 22.54 19.71 22.54 15.17 22.54 15.17"
          />
          <polygon
            className="cls-1"
            points="4.54 6 0 6 0 10.54 4.54 10.54 4.54 6 4.54 6"
          />
          <polygon
            className="cls-1"
            points="13.46 10.63 8.92 10.63 8.92 15.17 13.46 15.17 13.46 10.63 13.46 10.63"
          />
          <polygon
            className="cls-1"
            points="33.79 9.17 29.25 9.17 29.25 13.71 33.79 13.71 33.79 9.17 33.79 9.17"
          />
          <polygon
            className="cls-1"
            points="15.79 0 11.25 0 11.25 4.54 15.79 4.54 15.79 0 15.79 0"
          />
          <polygon
            className="cls-1"
            points="24.71 4.63 20.17 4.63 20.17 9.17 24.71 9.17 24.71 4.63 24.71 4.63"
          />
          <polygon
            className="cls-1"
            points="61.75 9.17 66.29 9.17 66.29 13.71 61.75 13.71 61.75 9.17 61.75 9.17"
          />
          <polygon
            className="cls-1"
            points="79.75 0 84.29 0 84.29 4.54 79.75 4.54 79.75 0 79.75 0"
          />
          <polygon
            className="cls-1"
            points="70.83 4.63 75.37 4.63 75.37 9.17 70.83 9.17 70.83 4.63 70.83 4.63"
          />
          <polygon
            className="cls-1"
            points="15.79 51.21 11.25 51.21 11.25 46.67 15.79 46.67 15.79 51.21 15.79 51.21"
          />
          <polygon
            className="cls-1"
            points="24.71 46.58 20.17 46.58 20.17 42.04 24.71 42.04 24.71 46.58 24.71 46.58"
          />
          <polygon
            className="cls-1"
            points="79.75 51.21 84.29 51.21 84.29 46.67 79.75 46.67 79.75 51.21 79.75 51.21"
          />
          <polygon
            className="cls-1"
            points="70.83 46.58 75.37 46.58 75.37 42.04 70.83 42.04 70.83 46.58 70.83 46.58"
          />
          <polygon
            className="cls-1"
            points="22.54 26.93 18 26.93 18 31.47 22.54 31.47 22.54 26.93 22.54 26.93"
          />
          <polygon
            className="cls-1"
            points="4.54 26.93 0 26.93 0 31.47 4.54 31.47 4.54 26.93 4.54 26.93"
          />
          <polygon
            className="cls-1"
            points="13.46 26.93 8.92 26.93 8.92 31.47 13.46 31.47 13.46 26.93 13.46 26.93"
          />
          <polygon
            className="cls-1"
            points="22.54 21.18 18 21.18 18 25.72 22.54 25.72 22.54 21.18 22.54 21.18"
          />
          <polygon
            className="cls-1"
            points="4.54 21.18 0 21.18 0 25.72 4.54 25.72 4.54 21.18 4.54 21.18"
          />
          <polygon
            className="cls-1"
            points="13.46 21.18 8.92 21.18 8.92 25.72 13.46 25.72 13.46 21.18 13.46 21.18"
          />
        </g>
      </g>
    </svg>
  );
};
