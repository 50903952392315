import React from "react";
import styled from "styled-components";
import { colors } from "../../../vars/palette";

const SubTitleWrap = styled.h3`
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  text-align: center;
`;

const WidgetSubTitle = ({
  children,
  style,
  blue,
  gold
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  blue?: boolean;
  gold?: boolean;
}) => {
  const color = blue
    ? { color: colors.hex("primary") }
    : gold
    ? { color: colors.hex("accent") }
    : { color: colors.hex("white") };
  const subStyle = { ...style, ...color };
  return (
    <SubTitleWrap className="widget-sub-title" style={subStyle}>
      {children}
    </SubTitleWrap>
  );
};

export default WidgetSubTitle;
