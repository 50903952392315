import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
// import Icon7 from "../images/icon-step7.png"

const Icon7 = require("../images/icon-step7.png");

const step7IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`;

const Wrap = styled.div`
  .click-step7 {
    z-index: 150 !important; //need this step to have higher z-index than others because it overlaps another step when open
    height: 9vw;
    top: 71%;
    // right: 85%;
    &.open {
      z-index: 150 !important;
      border-left: 6px solid white !important;
      width: 43%;
      top: 57%;
      right: 55.9%;
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 85% 0%);
      transition: right 0.75s ease, top 0.5s ease 0.75s, width 0.5s ease 1.25s,
        clip-path 0.5s ease 1.25s, border-left 0.5s ease 1.25s;
    }
    &.closed {
      border-left: 0;
      width: 10.5vw;
      top: 71%;
      right: 68%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width 0.5s ease, border-left 0.25s ease, clip-path 0.5s ease,
        top 0.5s ease 0.5s, right 0.75s ease 1s;
    }
    // .right-border-div {
    //   height: 100%;
    //   width: 5px;
    //   background: white;
    // }
    .info-wrap {
      .title {
        text-align: center;
        margin-bottom: -10px;
        p {
          margin-bottom: -2px;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 5px 0px 1rem 0.25rem;
        .icon {
          margin-right: -1rem;
          margin-top: 0;
          img {
            width: 4rem;
            height: 5rem;
          }
          img.open {
            animation: ${step7IconGrow} 0.5s ease 0.75s;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 70%;
          padding-right: 1rem;
          .title {
            margin-bottom: 5px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .step7-line1 {
    height: 4px;
    top: 79.8%;
    right: 61.3%;
    width: 12%;
    transition: width 0.75s ease 1s;
    &.open {
      width: 0%;
      transition: width 0.75s ease;
    }
  }
  .step7-line2 {
    width: 4px;
    top: 68%;
    right: 61.3%;
    height: 12%;
    transition: height 0.5s ease 0.5s;
    &.open {
      height: 0%;
      transition: height 0.75s ease 0.75s;
    }
  }
  .circle-step7 {
    top: 65%;
    right: 60%;
  }

  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step7 {
      top: 67%;
      &.open {
        width: 45%;
        right: 52.5%;
        top: 57%;
      }
      &.closed {
        top: 67% !important;
        right: 66%;
      }
      .full-info-wrap {
        .title-only {
          p {
            font-size: 0.9rem !important;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1007px) {
    .click-step7 {
      top: 69%;
      &.open {
        width: 45%;
        right: 52.5%;
        top: 57%;
      }
      &.closed {
        top: 69%;
        right: 66%;
      }
    }
  }
  @media only screen and (max-width: 850px) {
    .click-step7 {
      top: 69%;
      &.open {
        width: 45%;
        right: 52.5%;
        top: 57%;
      }
      &.closed {
        top: 69%;
        right: 66%;
      }
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step7 {
      top: 69%;
      &.open {
        width: 47%;
        right: 52.5%;
        top: 57%;
      }
      &.closed {
        top: 69%;
        right: 66%;
      }
      .title-only {
        p {
          font-size: 0.8rem !important;
          margin-bottom: -5px !important;
        }
      }
      .full-info-wrap {
        padding: 0px 0px 1rem 0.05rem !important;
        .title-and-copy {
          padding-right: 0.25rem !important;
        }
        .title {
          font-size: 0.9rem !important;
          margin-bottom: 1px !important;
        }
        .copy {
          font-size: 0.8rem !important;
          line-height: 14px !important;
        }
        .icon {
          padding-top: 0.5rem;
          margin-right: -10px !important;
          img {
            height: 3.5rem !important;
            width: 2.5rem !important;
            padding-right: 0rem;
            margin-right: -10px !important;
          }
        }
      }
    }
    .step7-line1 {
      right: 62%;
      width: 15%;
      transition: width 0.75s ease 1s;
      &.open {
        width: 0%;
        transition: width 0.75s ease;
      }
    }
    .step7-line2 {
      right: 62%;
      transition: height 0.5s ease 0.5s;
      &.open {
        height: 0%;
        transition: height 0.75s ease 0.75s;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step7 {
      top: 66%;
      &.open {
        width: 45%;
        right: 52%;
      }
      &.closed {
        right: 72%;
      }
      .title-only {
        margin-top: -10px !important;
        p {
          font-size: 0.7rem !important;
          margin-bottom: -7px !important;
        }
      }
      .full-info-wrap {
        padding-top: 0 !important;
        .title-and-copy {
          width: 77% !important;
          padding-right: 0.1rem !important;
          margin-left: -5px;
          width: 75% !important;
        }
        .title {
          font-size: 0.8rem !important;
          margin-bottom: -2px !important;
        }
        .copy {
          font-size: 0.6rem !important;
          line-height: 11px !important;
        }
        .icon {
          padding-top: 0.25rem;
          img {
            height: 3rem !important;
            width: 2.5rem !important;
            padding-right: 0.2rem;
          }
        }
      }
    }
    .step7-line1 {
      right: 62.5%;
      width: 20%;
      transition: width 0.75s ease 1s;
      &.open {
        width: 0%;
        transition: width 0.75s ease;
      }
    }
    .step7-line2 {
      right: 62.5%;
      transition: height 0.5s ease 0.5s;
      &.open {
        height: 0%;
        transition: height 0.75s ease 0.75s;
      }
    }
  }
`;

const Step7 = ({
  currStep,
  updateCurrStep
}: {
  currStep: number;
  updateCurrStep: Function;
}) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(
      () => (currStep === 7 ? setFullInfo(true) : setFullInfo(false)),
      750
    );
  }, [currStep]);
  // const [rightBorder, setRightBorder] = useState(false);
  // const delayedStates = (fullInfo, rightBorder) => {
  //   const fullInfoTO = setTimeout(() => setFullInfo(!fullInfo), 500);
  //   const rightBorderTO = setTimeout(() => {setRightBorder(!rightBorder); console.log('rightBorderTO fired off')}, 1000);
  // }
  const buttonClick = () => {
    updateCurrStep(7);
  };
  const info = {
    icon: Icon7?.default,
    title: {
      full: "Treated Water Storage",
      piece1: "Treated",
      piece2: "Water",
      piece3: "Storage"
    },
    copy: "The fully treated drinking water is stored in underground storage tanks (clearwells) and pumped to customers as needed."
  };

  const buttonClosed = `click-step click-step7 closed`;
  const buttonOpen = `click-step click-step7 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step7-line1 open`;
  const stepLineOneClosed = `step-line step7-line1`;
  const stepLineTwoOpen = `step-line step7-line2 open`;
  const stepLineTwoClosed = `step-line step7-line2`;

  return (
    <Wrap>
      <button
        className={currStep === 7 ? buttonOpen : buttonClosed}
        onClick={() => buttonClick()}>
        <div
          className={currStep === 7 ? infoWrapOpen : infoWrapClosed}
          style={!fullInfo ? { paddingTop: "2rem" } : { paddingTop: "0" }}>
          {fullInfo ? (
            <div
              className="full-info-wrap"
              style={
                currStep === 7
                  ? { opacity: 1, transition: "opacity .75s ease .75s" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <div className="title-and-copy">
                <p className="title" style={fullInfo && { textAlign: "left" }}>
                  {info.title.full}
                </p>
                <p className="copy">{info.copy}</p>
              </div>
              <div className="icon">
                <img
                  className={fullInfo ? `open` : `closed`}
                  src={info.icon}
                  alt={info.title.full + ` icon`}
                  style={
                    currStep === 7
                      ? {
                          transform: "scale(1)",
                          transition: "transform .75s ease"
                        }
                      : {
                          transform: "scale(0)",
                          transition: "transform .75s ease"
                        }
                  }
                />
              </div>
            </div>
          ) : (
            <div
              className="title title-only"
              style={
                currStep !== 7
                  ? { opacity: 1, transition: "opacity .75s ease" }
                  : { opacity: 0, transition: "opacity 1s ease" }
              }>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
              <p>{info.title.piece3}</p>
            </div>
          )}
        </div>
        {/* {rightBorder && <div className="right-border-div" />} */}
      </button>
      <div
        className={
          currStep === 7
            ? "circle-step circle-step7 open"
            : "circle-step circle-step7"
        }>
        7
      </div>
      <div className={currStep === 7 ? stepLineOneOpen : stepLineOneClosed} />
      <div className={currStep === 7 ? stepLineTwoOpen : stepLineTwoClosed} />
    </Wrap>
  );
};

export default Step7;
