import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../ui/colors";
import { colors as palletColors } from "../../vars/palette";
// import { colors } from "../../../../vars/palette";
// import { BREAKPOINT_MEDIUM_MAX, Type } from "../../../ui/Typography";

const CalculatorButton = styled.button`
  width: 100%;
  display: inline-block;
  margin: ${({ type }) => (type === "submit" ? `.5rem 0` : `1rem 0`)};
  border: none;
  background: ${colors.blue};
  color: white;
  text-transform: uppercase;
  &:disabled {
    background: gray;
  }
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  transition: all 0.3s ease;
  &:hover {
    background: ${colors.lightBlue};
    &:disabled {
      background: gray;
    }
  }
`;

const altFormStyles = `
  position: relative;
  margin: 3rem 0;
  width: 50%;
  .form-col {
    margin-bottom: 1.5rem;
    padding-left: 6px;
    padding-right: 6px;
  }
  .form-group {
    position: relative;
  }
  input,
  textarea,
  select {
    border-radius: 0;
    // border: 1px solid blue;
    border: 1px solid rgb(200,201,201);
    color: rgb(148, 148, 148);
    &:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  label {
    position: relative;
    top: 8px;
    background: white;
    color: rgb(148, 148, 148);
    text-transform: uppercase;
    font-weight: 500;
  }
  .hidden {
    opacity: 0;
    height: 0;
    position: absolute;
    top: -100vh;
  }
  .submit-reset {
    button {
      width: 100%;
      margin-right: .5rem;
      padding: 0.8rem;
      background: ${colors.blue};
      border: none;
      border-radius: 0;
      :hover {
        background: ${colors.lightBlue};
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 80%;
  }
`;
const AltStyledForm = styled(Form)`
  ${altFormStyles}
`;

const hexEncode = (str) => {
  var hex, i;

  var result = "";
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += ("000" + hex).slice(-4);
  }

  return result;
};

// const hexDecode = (str) => {
//   var j;
//   var hexes = str.match(/.{1,4}/g) || [];
//   var back = "";
//   for (j = 0; j < hexes.length; j++) {
//     back += String.fromCharCode(parseInt(hexes[j], 16));
//   }

//   return back;
// };

export const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 152, 204, 0.8);
`;

const LockBox = styled.div`
  width: 40rem;
  padding: 8vh 7vw;
  background: white;
  position: relative;
  z-index: 100002;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  max-width: 80vw;
  transition: all 0.5s ease;
  .logo-line-2 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: -10px;
    color: ${palletColors.hex("primary")};
    transition: all 0.5s ease;
    margin-bottom: 2rem;
  }

  .form-section {
    max-width: 60vw;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-message {
    color: red;
    margin-top: 2rem;
    a {
      color: red;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 6vh 6vw;
    // overflow: scroll;
    width: fit-content;

    .form-section {
      max-width: 100vw;
      width: 100%;
    }
  }
`;

const ExitButton = styled.div`
  border: none;
  background: none;
  font-size: 1.25rem;
  position: absolute;
  top: 2vh;
  right: 3vh;
  @media only screen and (max-width: 767px) {
    top: 1vh;
    right: 2vh;
  }
  a {
    color: black;
    &:hover {
      color: lightBlue;
    }
  }
`;

const Locked = ({ children, setPledgeIsClicked, width }) => {
  const [isLocked, setIsLocked] = useState(true);
  const [pass, setPass] = useState("");
  const [isInArea, setIsInArea] = useState(false);
  const [error, setError] = useState(false);
  // console.log("what is this even doing ----------------------------");

  // useEffect(() => {
  //   if (window && sessionStorage?.getItem("memberSectionUnlocked")) {
  //     setIsLocked(false)
  //   }
  // },[])

  useEffect(() => {
    if (isInArea === true && window && width <= 767) {
      window
        .open("https://mailchi.mp/utrwd/pledge-2-water-less-yall", "_self")
        .focus();
    } else if (isInArea === true && window) {
      window
        .open("https://mailchi.mp/utrwd/pledge-2-water-less-yall", "_blank")
        .focus();
    }
  }, [isInArea]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const hexed = hexEncode(pass);
    const zips = [
      76247, 75022, 75028, 75077, 75065, 76208, 76210, 76226, 75009, 75068,
      75078, 76227
    ];
    if (
      zips.find((zip) => {
        return parseInt(pass) === zip;
      })
    ) {
      setError(false);
      setIsInArea(true);
      // sessionStorage.setItem("memberSectionUnlocked", "true")
    } else {
      setError(true);
      setPass("");
    }
  };

  const handleChange = (e) => {
    const p = e.target.value;
    setPass(() => {
      if (p === "") {
        setError(false);
      }
      return p;
    });
  };

  return isLocked ? (
    <div
      style={{
        position: `fixed`,
        zIndex: 100001,
        height: `100vh`,
        width: `100vw`,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      <Bg />

      <LockBox>
        <ExitButton
          onClick={() => {
            setPledgeIsClicked(false);
          }}>
          <WmkLink to="#">
            <FaTimes />
          </WmkLink>
        </ExitButton>
        <p className="logo-line-2" style={{ textAlign: "center" }}>
          Please verify you reside in UTRWD's service area.
        </p>
        {/* <Row
          style={{
            width: "100%",
            justifyContent: "center",
            padding: "1.5rem 0"
          }}>
          {memberCompanies.map((logo, i) => {
            return (
              <Col
                xs="auto"
                style={{ padding: "0 .25rem" }}
                key={logo.title + i}>
                <GatsbyImage
                  image={logo.image.gatsbyImageData}
                  alt={logo.title}
                />
              </Col>
            );
          })}
        </Row> */}
        <div className="form-section">
          <AltStyledForm onSubmit={handleSubmit} style={{ margin: 0 }}>
            {/* <AltStyledForm.Label>
              Enter Member Login Password
            </AltStyledForm.Label> */}

            <AltStyledForm.Control
              type="number"
              placeholder="Enter your zip code here"
              value={pass}
              onChange={handleChange}
              autoComplete={"current-password"}
            />
            <CalculatorButton type="submit">Submit</CalculatorButton>
          </AltStyledForm>
          {error ? (
            // <Card
            //   bg={"warning"}
            //   style={{
            //     display: `flex`,
            //     justifyContent: "center",
            //     alignItems: "center"
            //   }}>
            //   <p style={{ padding: `1rem 1rem 0 1rem` }}>
            //     Invalid password. Please try again.
            //   </p>
            // </Card>
            <p className="error-message">
              {`Unfortunately, you reside outsize UTRWD's service area and are
              not eligible to participate in the Pledge 2 drawings. However, we
              encourage everyone to Water Less Y'all.`}{" "}
              <WmkLink
                to="#water-less-yall-tiles"
                onClick={() => {
                  setPledgeIsClicked(false);
                }}>{`Click here for water saving tips >>`}</WmkLink>
            </p>
          ) : null}
        </div>
      </LockBox>
    </div>
  ) : (
    <>{children}</>
  );
};

export default Locked;
