import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SGButtons from "./SGButtons";
import SGInfo from "./SGInfo";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { colors } from "../../../../ui/colors";
import { SprinklerFields } from "../../../../../node/fragments/NodeSprinklersFields";

const GuideWrap = styled(Col)`
  background: ${colors.cyan.alpha(0.125).toString()};
  padding: 0 0 4vh 0;
`;

export const SprinklerGuide = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const data: SprinklersQuery = useStaticQuery(query);
  const sprinklers = data.sprinklers.edges.map((e) => e.node);
  return (
    <Container>
      <Row>
        <Col
          style={{
            maxWidth: 760,
            display: "flex",
            justifyContent: "center",
            margin: "auto"
          }}>
          <Row className="flex-column" style={{ width: "100%" }}>
            <Col>
              <SGButtons
                sprinklers={sprinklers}
                active={activeIndex}
                set={setActiveIndex}
              />
            </Col>
            <GuideWrap>
              <SGInfo sprinklers={sprinklers} active={activeIndex} />
            </GuideWrap>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

interface SprinklersQuery {
  sprinklers: { edges: { node: SprinklerFields }[] };
}

const query = graphql`
  {
    sprinklers: allContentfulSprinklers {
      edges {
        node {
          ...NodeSprinklersFields
        }
      }
    }
  }
`;
