import React from "react";
import styled from "styled-components";
import { ContactFields } from "../../../node/fragments/NodeContactFields";
import { colors } from "../../../vars/palette";
import BlockButton from "../../common/MenuItemButton";

const WrapRow = styled.tr`
  color: ${colors.hex("white")};
  background: ${colors.hex("primary")};
  border: 7px solid ${colors.hex("secondary")};
  td {
    border: 4px solid ${colors.hex("secondary")};
    padding: 25px;
    p {
      margin-bottom: 0;
      &.big-n-bold {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    .button-wrap {
      margin: 1rem auto 0 auto;
      a {
        background: ${colors.hex("white")};
        color: ${colors.hex("secondary")};
        &:hover {
          background: ${colors.hex("tertiary")};
        }
      }
    }
    :last-of-type {
      max-width: 600px;
    }
  }
`;

interface SingleContactFields extends ContactFields {
  index: number;
}

const SingleContact = ({
  category,
  contactName,
  contactTitle,
  description,
  //phoneNumber,
  button
}: //index
SingleContactFields) => {
  return (
    <WrapRow>
      <td align="center">
        <p className="big-n-bold">{category && category}</p>
      </td>
      <td align="center">
        <p className="big-n-bold">{contactName && contactName}</p>
        <p>{contactTitle && contactTitle}</p>
        <p className="big-n-bold">
          {description && description?.description && description?.description}
        </p>
        {button && (
          <div className="button-wrap">
            <BlockButton button={button} />
          </div>
        )}
      </td>
    </WrapRow>
  );
};

export default SingleContact;
