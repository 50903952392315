import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { PieceSpecs } from "./Pieces/Piece";

const PieceWrap = styled.div<{ pieceSpecs: PieceSpecs; clickedPiece: string }>`
  position: absolute;
  ${({ pieceSpecs }) => {
    const width = pieceSpecs.width;
    const x = pieceSpecs.x;
    const y = pieceSpecs.y;
    const z = pieceSpecs.z;
    return `
      width: ${width};
      height: auto;
      left: ${x};
      top: ${y};
      z-index: ${z};
    `;
  }}
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  ${({ clickedPiece }) =>
    clickedPiece !== "" ? "filter: brightness(50%);" : null}
`;

const InteractiveGraphicBackground = ({
  clickedPiece
}: {
  clickedPiece: string;
}) => {
  const data: PieceQuery = useStaticQuery(query);
  const bgPieces = data.pieces.edges.map((p) => p.node);
  return Array.isArray(bgPieces) ? (
    <>
      {bgPieces.map((piece, i) => {
        const title = piece.title ? piece.title.split("- ")[2] : "";
        // console.log("title: ", title);
        const fluid = new Img(piece);
        const specs =
          title === "sky"
            ? { width: "100%", x: "0%", y: "-18%", z: 0 }
            : title === "sun"
            ? { width: "17%", x: "54%", y: "10%", z: 1 }
            : title === "yard base"
            ? { width: "100%", x: "0%", y: "-18%", z: 2 }
            : title === "hose"
            ? {
                width: "95.3%",
                x: "2.7%",
                y: "79%",
                z: 5
              }
            : { width: `0%`, x: `0`, y: `0`, z: 0 };
        return (
          <PieceWrap key={title} pieceSpecs={specs} clickedPiece={clickedPiece}>
            <WmkImage image={fluid} />
          </PieceWrap>
        );
      })}
    </>
  ) : null;
};

export default InteractiveGraphicBackground;

interface PieceQuery {
  pieces: { edges: { node: ContentfulImageQuery }[] };
}

const query = graphql`
  query {
    pieces: allContentfulAsset(
      filter: { title: { regex: "/interactive graphic - background/i" } }
    ) {
      edges {
        node {
          title
          gatsbyImageData
          file {
            url
            contentType
          }
        }
      }
    }
  }
`;
