import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { WmkLink } from "wmk-link"
import { buildSlug } from "../../../vars/ThemeOptions";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../../common/Button";
import { IoIosClose } from "react-icons/io";
import { colors } from "../../../vars/palette";

const SubscribeWrap = styled.div`
  position: fixed;
  z-index: 500;
  right: 0;
  top: 45%;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
  .flex-column {
    background: ${colors.hex("white")};
    padding: 2vh 2vh 2vh calc(2vh + 16px);
  }
  button {
    color: ${colors.hex("secondary")};
    border: none;
    background: none;
    appearance: none;
    font-size: 2rem;
    line-height: 0;
    left: -2vh;
    top: 0.725rem;
  }
`;

const SubscribeWidget = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { options, events } = useStaticQuery(graphql`
    {
      options: contentfulGlobal(title: { eq: "UTRWD Footer Options" }) {
        ...NodeGlobalFields
      }
      events: contentfulPages(slug: { eq: "upcoming-events" }) {
        slug
        parentPageSlug
        title
        subParentPageSlug
      }
    }
  `);
  const { button } = options;
  return (
    isOpen && (
      <SubscribeWrap>
        <button
          style={{ position: "absolute" }}
          onClick={() => setIsOpen(false)}
          aria-label="Close Newsletter Signup Box"
        >
          <IoIosClose />
        </button>
        <Row className="flex-column">
          <Col>
            <h5
              style={{
                fontWeight: "bold",
                color: colors.hex("secondary"),
                paddingRight: "2rem",
                margin: 0,
              }}
            >
              Stay on Top of UTRWD Happenings
            </h5>
          </Col>
          <Col>
            <h6
              style={{
                color: colors.hex("accent"),
                padding: ".25rem 0 .5rem 0",
              }}
            >
              Sign-Up for our e-Newsletter
            </h6>
          </Col>
          <Col>
            <Button
              to={button.linkUrl}
              target={button.target}
              style={{
                width: "100%",
                display: "block",
                maxWidth: "90%",
                textAlign: "center",
              }}
            >
              Subscribe
            </Button>
          </Col>
          <Col>
            <WmkLink to={buildSlug(events)} style={{
              color: colors.hex("accent"),
              textDecoration: 'underline',
              textAlign: 'center',
              display: 'block',
              width: '90%',
              fontWeight: 'bold',
              paddingTop: '.5rem'
            }}>Check Out Upcoming Events</WmkLink>
          </Col>
        </Row>
      </SubscribeWrap>
    )
  );
};

export default SubscribeWidget;
