import React from "react";
import Wave from "../../../../../flexible/blocks/BlockBody/Wave/Wave";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Nameplate from "../Nameplate/BODNameplate";
import { colors } from "../../../../../../vars/palette";
import { BoardMemberQuery } from "../BoardOfDirectors";
import { Img, WmkImage } from "wmk-image";

const Officers = styled(Row)`
  @media only screen and (max-width: 992px) {
    .officer:nth-child(3),
    & > .officer:last-child {
      bottom: 0;
      margin-bottom: 70px;
    }
  }
  @media only screen and (max-width: 768px) {
    .officer:first-child {
      margin-bottom: 30px;
    }
    .officer:not:(:first-child) {
      bottom: 0;
      margin-bottom: 30px;
    }
    .officer:last-child {
      margin-bottom: 20px;
      margin-top: -30px;
    }
  }
`;

const Officer = styled(Col)`
  position: relative;
  bottom: 120px;
  margin-bottom: -90px;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const BoardOfficers = ({ officers }: { officers: BoardMemberQuery[] }) => {
  return (
    officers &&
    officers.length && (
      <div>
        <Wave wave="Top" target="top" />
        <Container fluid style={{ backgroundColor: colors.hex("secondary") }}>
          <Container>
            <Officers>
              {officers.map((officer, i) => {
                const { name, job, org, headshot } = officer;
                return (
                  <Officer
                    key={name + i}
                    sm={12}
                    md={6}
                    lg={3}
                    className="officer">
                    {headshot && (
                      <Row>
                        <Col
                          style={{ display: "flex", justifyContent: "center" }}>
                          <WmkImage
                            image={new Img(headshot)}
                            style={{
                              borderRadius: "50%",
                              position: "relative",
                              top: "30px",
                              zIndex: 3
                              //border: `8px solid ${colors.hex("secondary")}`,
                            }}
                          />
                          <div
                            style={{
                              background: colors.hex("secondary"),
                              position: "absolute",
                              width: "200px",
                              height: "200px",
                              borderRadius: "50%",
                              top: "20px"
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                    <Nameplate
                      name={name}
                      job={job ? job + ", " + org : org}
                      style={{ zIndex: 2, position: "relative" }}
                    />
                  </Officer>
                );
              })}
            </Officers>
          </Container>
        </Container>
      </div>
    )
  );
};

export default BoardOfficers;
