import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
//import Theme from "../../../../vars/ThemeOptions";
import OldUpcoming from "./OldUpcoming";
import { getCurrentEvents } from "../../Home/NewsAndEvents/EventsFeed/EventsFeed";
import { EventsFields } from "../../../../node/fragments/NodeEventsFields";
import { Event } from "../../../../classes/Events";

const WrapCont = styled(Container)`
  margin: 3rem auto 0 auto;
  max-width: 760px;
  & > div {
    display: flex;
    justify-content: center;
  }
`;

interface EventsQuery {
  allContentfulEvents: {
    edges: {
      node: EventsFields;
    }[];
  };
}

const EventsPage = () => {
  const data: EventsQuery = useStaticQuery(graphql`
    query {
      allContentfulEvents(sort: { fields: date, order: ASC }) {
        edges {
          node {
            ...NodeEventsFields
          }
        }
      }
    }
  `);
  const eventData = data.allContentfulEvents.edges.map(
    (p) => new Event(p.node)
  );
  return (
    <WrapCont>
      <Row>
        <Col xs={12}>
          <OldUpcoming eventData={getCurrentEvents(eventData)} />
        </Col>
      </Row>
    </WrapCont>
  );
};

export default EventsPage;
