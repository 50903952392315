import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import WidgetSubTitle from "../../../../../common/typography/WidgetSubTitle";
import { graphql, useStaticQuery } from "gatsby";
import Nameplate from "../Nameplate/BODNameplate";
import CarouselArrow from "./CarouselArrow";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { colors } from "../../../../../ui/colors";
import { BoardOfDirectorsFields } from "../../../../../../node/fragments/NodeBODFields";

const PastWrap = styled(Container)`
  padding: 4vh 0;
  // .pres-slide > .row {
  //     margin: 0;
  // }
  button {
    color: ${colors.cyan.getHex()};
    font-size: 10rem;
    background: none;
    border: none;
    transform: scaleX(0.75);
  }
`;

const PastPresidents = () => {
  const [active, setActive] = useState(1);
  const [slideWidth, setSlideWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  const slideRef = useRef<HTMLDivElement>();

  const data: PrezQuery = useStaticQuery(query);

  const presidents = data.presidents.edges.map((e) => e.node);

  useEffect(() => {
    const resize = () => {
      setSlideWidth(
        slideRef.current.querySelector<HTMLDivElement>(".pres-slide")
          .offsetWidth
      );
      setWindowWidth(window.outerWidth);
      // console.log(slideWidth)
      // console.log(window.outerWidth)
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [active, slideWidth]);

  return (
    <PastWrap id="past-wrap" style={{ position: "relative" }}>
      <CarouselArrow
        prev
        active={active}
        setActive={setActive}
        total={presidents.length}>
        <MdKeyboardArrowLeft />
      </CarouselArrow>
      <Container>
        <Row>
          <Col>
            <WidgetSubTitle blue>Past Board Presidents</WidgetSubTitle>
          </Col>
        </Row>
        <Row
          style={{
            overflow: "hidden",
            padding: "10vh 0",
            position: "relative",
            height: "15rem"
          }}
          ref={slideRef}>
          <Row
            style={{
              flexWrap: "nowrap",
              position: "absolute",
              left:
                windowWidth >= 1215
                  ? -slideWidth * (active - 2) - 125
                  : windowWidth <= 1214 && windowWidth >= 1008
                  ? -slideWidth * (active - 1) + 135
                  : windowWidth <= 1007 && windowWidth >= 782
                  ? -slideWidth * (active - 1) + 15
                  : -slideWidth * active + 15,
              width: "100%",
              transition: "all .5s ease",
              padding: "0"
            }}>
            {presidents.map((prez, i) => {
              const { name, org, years } = prez;
              return (
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  key={name + i}
                  className="pres-slide">
                  <Nameplate name={name} job={org} years={years} shadow />
                </Col>
              );
            })}
          </Row>
        </Row>
      </Container>
      <CarouselArrow
        next
        active={active}
        setActive={setActive}
        total={presidents.length}>
        <MdKeyboardArrowRight />
      </CarouselArrow>
    </PastWrap>
  );
};

export default PastPresidents;

interface PrezQuery {
  presidents: { edges: { node: BoardOfDirectorsFields }[] };
}

export const query = graphql`
  {
    presidents: allContentfulBoardMember(
      filter: { memberType: { eq: "Past President" } }
      sort: { fields: sortName, order: ASC }
    ) {
      edges {
        node {
          ...NodeBODFields
        }
      }
    }
  }
`;
