import React from "react";
import WaterTreatmentDeliveryPage from "../components/routes/Water-Treatment-Delivery";
import WastewaterTreatmentGraphic from "../components/routes/Wastewater-Treatment";
import BoardOfDirectors from "../components/routes/AboutUs/WhoWeAre/BoardOfDirectors/BoardOfDirectors";
//mport HUCBefore from "../components/routes/HelpUsConserve/HUCBefore";
import PageHero from "../components/common/PageHero";
import { graphql, useStaticQuery } from "gatsby";
import FlexContent from "../components/flexible/FlexibleContent";
import JobsSection from "../components/routes/JoinOurTeam/Jobs";
import PastPresidents from "../components/routes/AboutUs/WhoWeAre/BoardOfDirectors/PastPresidents/BoardPastPresidents";
import Newsroom from "../components/routes/Newsroom/Newsroom";
import LakeLevels from "../components/routes/Home/LakeLevels/LakeLevels";
import EventsPage from "../components/routes/Newsroom/UpcomingEvents/UpcomingEvents";
import ContactPage from "../components/routes/Contact/Contact";
import DisposalEventsBox from "../components/routes/HowToDispose/DisposalEventsBox";
import { WmkSeo } from "wmk-seo";
import {
  Recommendation,
  SprinklerGuide
} from "../components/routes/WaterMyYard/index";
import { LocationProvider } from "../components/routes/WaterMyYard/context/LocationProvider";
import BreadCrumbs from "../components/common/BreadCumbs";
import InteractiveGraphic from "../components/routes/InteractiveGraphic/InteractiveGraphic";
import { PageFields } from "./fragments/NodePageFields";
import { GlobalFields } from "./fragments/NodeGlobalFields";
import { Page } from "../classes/Page";
import WaterLessYall from "../components/routes/WaterLessYall/WaterLessYall";
import MediaGalleryPage from "../components/common/MediaGallery/MediaGalleryPage";
import { useLayoutContext } from "../context/LayoutContext";
import { useState, useEffect } from "react";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import styled from "styled-components";
import Locked from "../components/common/locked";
import Button from "../components/common/Button";
import { Col, Container, Row } from "react-bootstrap";
import { colors } from "../vars/palette";
import { WmkLink } from "wmk-link";

// const BodyWrap = styled.article`
//   max-width: 700px;
//   margin: auto;
//   padding: 2vw 0 0 0;
//   overflow: hidden;
//   position: relative;
//   .p {
//     padding: 0.5rem 0;
//     a {
//       color: ${colors.hex("accent")};
//       &:hover {
//         color: ${colors.hex("secondary")};
//       }
//     }
//   }
//   .body-copy-title {
//     font-weight: bold;
//     color: ${colors.hex("primary")};
//     text-align: center;
//     text-shadow: 3px 3px 4px ${colors.rgba("black", 0.16)};
//   }
//   &.blue {
//     .wmk-link {
//       color: ${colors.hex("white")};
//       background-color: none;
//       text-decoration: underline !important;
//       &:hover: {
//         color: ${colors.hex("accent")};
//         background-color: none;
//       }
//     }
//     .utrwd-btn {
//       background-color: ${colors.hex("white")};
//       color: ${colors.hex("primary")};
//       text-decoration: none !important;
//       &:hover {
//         background-color: ${colors.hex("primary")};
//         color: ${colors.hex("white")};
//       }
//     }
//   }
// `;

export default ({
  data,
  pageContext
}: {
  data: PageQuery;
  pageContext: { slug: string };
}) => {
  const [winWidth, setWinWidth] = useState();
  const [pledgeIsClicked, setPledgeIsClicked] = useState();
  useEffect(() => {
    const resize = () => {
      setWinWidth(window.innerWidth);
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [winWidth]);
  const page = new Page(data.page);
  const context = useLayoutContext();
  context.unhideAlert();
  let RenderBefore = null,
    RenderAfter = null,
    RenderMiddle = null,
    RenderMainMiddle = null;
  const { slug } = pageContext;
  switch (slug) {
    case "media":
      RenderBefore = <MediaGalleryPage />;
      break;
    case "board-of-directors":
      RenderMiddle = <BoardOfDirectors />;
      RenderAfter = <PastPresidents />;
      break;
    // case "help-us-conserve":
    //   //RenderBefore = <HUCBefore />;
    //   break;
    case "lake-levels":
      RenderMiddle = <LakeLevels />;
      break;
    case "newsroom":
      RenderBefore = <Newsroom />;
      break;
    case "water-treatment-and-delivery":
      RenderMiddle = (
        <div style={{ paddingTop: "4vh" }}>
          <WaterTreatmentDeliveryPage />
        </div>
      );
      break;
    case "wastewater-treatment":
      RenderMiddle = (
        <div style={{ paddingTop: "4vh" }}>
          <WastewaterTreatmentGraphic />
        </div>
      );
      break;
    case "join-our-team":
      RenderMiddle = <JobsSection />;
      break;
    case "upcoming-events":
      RenderBefore = <EventsPage />;
      break;
    case "upcoming-events-development":
      RenderBefore = <EventsPage />;
      break;
    case "contact":
      RenderBefore = <ContactPage />;
      break;
    case "how-to-dispose":
      RenderMiddle = <DisposalEventsBox />;
      break;
    case "save-outdoors":
      RenderMiddle = <InteractiveGraphic />;
      break;

    // case "water-less-yall":
    //   RenderMiddle = <WaterLessYall />;
    //   context.hideAlert();
    //   //RenderAfter = <InteractiveGraphic />;
    //   break;
    case "water-less-yall":
      RenderMainMiddle = (
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "2rem"
            }}>
            <div
              className="zip-button"
              style={{ width: "20rem" }}
              onClick={() => {
                setPledgeIsClicked(true);
              }}>
              <WmkLink to="#">
                <WmkImage image={new Img(data.pledgeNowButton)} />
              </WmkLink>
              {/* <Button to="#" style={{ display: "inline-block" }}>
                {`Pledge to Water Less Y'all!`}
              </Button> */}
            </div>
          </div>
      )
      RenderMiddle = (
        <>
          <Container
            style={{
              maxWidth: "700px",
              margin: "auto",
              padding: "2vw 0 0 0",
              overflow: "hidden",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <Row style={{ width: "100%" }}>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingBottom: "2rem"
                  }}>
                  <h4 style={{ fontWeight: "bold", color: "#197FAA" }}>
                    <b>Just 2 Will Do</b>
                  </h4>
                  <div className="p">{`Yards are tougher in Texas. Two days a week is enough to keep them healthy and pretty. Responsible hand watering of flowerpots and beds is allowed. Below are great resources and tips to help you water smart.`}</div>
                </div>
              </Col>
            </Row>
            {/* <Row style={{ width: "100%" }}>
              <Col
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                <div
                  style={{
                    background: "#197FAA",
                    borderTop: "6px solid #197FAA",
                    borderLeft: "10px solid white",
                    borderRight: "10px solid white",
                    width: "60%",
                    marginBottom: "3rem"
                  }}></div>
              </Col>
            </Row> */}
          </Container>
          {pledgeIsClicked ? (
            <Locked setPledgeIsClicked={setPledgeIsClicked} width={winWidth}>
              <WaterLessYall />
            </Locked>
          ) : (
            <WaterLessYall />
          )}
        </>
      );
      context.hideAlert();
      //RenderAfter = <InteractiveGraphic />;
      break;

    default:
    //RenderAfter = <>{console.log(pageContext)}</>;
  }
  const mobileImage = new Img(data.mobileImage);
  const path =
    page.parentPageSlug && page.subParentPageSlug
      ? `/${page.parentPageSlug}/${page.subParentPageSlug}/`
      : page.parentPageSlug
      ? `/${page.parentPageSlug}/`
      : "/";

  const [zipValue, setZipValue] = useState("");
  return (
    <>
      <WmkSeo.Meta
        title={page.titleBar}
        description={page.meta}
        path={path}
        slug={page.slug}
        siteTitle={"UTRWD"}
        ogImageUrl={page.image?.url("ogImage")}
        twitterImageUrl={page.image?.url("twitterImage")}
        baseUrl={"https://utrwd.com"}
      />

      {/* <OverlayWrapper>
        <div className="verifyDiv">
          <h3 className="logo-line-2">
            Please verify you reside in UTRWD's service area. Enter your zip
            code here
          </h3>
          <form>
            <input
              value={zipValue}
              onChange={(e) => setZipValue(e.target.value)}
            />
          </form>
        </div>
      </OverlayWrapper> */}
      {/* <Locked> */}

      <PageHero
        title={page.title}
        heroImage={page.image}
        titleBar={page.titleBar}
        width={winWidth}
        mobileImage={
          slug && slug.includes("water-less-yall") ? mobileImage : null
        }
      />
      <BreadCrumbs pageData={page} firstBlock={page.blocks.main[0]} />
      {page.slug === "water-my-yard" ? (
        <LocationProvider>
          <Recommendation />
          <FlexContent blocks={page.blocks?.main} main />
          <SprinklerGuide />
          <FlexContent blocks={page.blocks?.secondary} />
        </LocationProvider>
      ) : (
        <>
          {RenderBefore}
          <FlexContent blocks={page.blocks?.main} main />
          {RenderMainMiddle}
          <FlexContent blocks={page.blocks?.mainPt2} />
          {RenderMiddle}
          <FlexContent blocks={page.blocks?.secondary} />
          {RenderAfter}
        </>
      )}
      {/* </Locked> */}
    </>
  );
};

export interface PageQuery {
  page: PageFields;
  meta: GlobalFields;
  mobileImage: ContentfulImageQuery;
}

export const query = graphql`
  query pageQuery($slug: String!) {
    page: contentfulPages(slug: { eq: $slug }) {
      ...NodePageFields
      ...BuilderBlockFields
      slug
      parentPageSlug
      subParentPageSlug
    }
    meta: contentfulGlobal(title: { eq: "UTRWD Footer Options" }) {
      title: siteTitle
      baseUrl: siteUrl
    }
    mobileImage: contentfulAsset(
      filename: { eq: "water-less-yall-page-header-mobile.png" }
    ) {
      gatsbyImageData(quality: 100)
      title
      file {
        url
        contentType
      }
      description
    }
    pledgeNowButton: contentfulAsset(
      title: { eq: "alert banner pledge now button v2" }
    ) {
      gatsbyImageData(quality: 100)
      title
      file {
        url
        contentType
      }
      description
    }
  }
`;
