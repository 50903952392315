import React from "react";
import styled from "styled-components";
import { colors } from "../../../../../ui/colors";

const ArrowWrap = styled.button`
  position: absolute;
  z-index: 50 !important;
  top: 22%;
  background: ${colors.white.getHex()} !important;
  @media only screen and (max-width: 767px) {
  }
`;

const CarouselArrow = ({
  next,
  prev,
  active,
  setActive,
  children,
  total
}: {
  next?: boolean;
  prev?: boolean;
  active: number;
  setActive: (n: number) => void;
  children: React.ReactNode;
  total: number;
}) => {
  const dir = (!next && !prev) || prev ? "prev" : "next";

  const handleArrow = (dir: "prev" | "next", active: number) => {
    if (dir === "prev") {
      if (active > 0) {
        setActive(active - 1);
      } else {
        setActive(total - 1);
      }
    }
    if (dir === "next") {
      if (active < total - 1) {
        setActive(active + 1);
      } else {
        setActive(0);
      }
    }
  };
  return (
    <ArrowWrap
      className={dir}
      style={{
        left: dir === "prev" ? "-8vw" : "initial",
        right: dir === "next" ? "-8vw" : "initial"
      }}
      onClick={() => handleArrow(dir, active)}>
      {children}
    </ArrowWrap>
  );
};

export default CarouselArrow;
