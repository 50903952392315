import React from "react";

const NewsTimeAgo = ({
  date,
  style
}: {
  date: Date;
  style: React.CSSProperties;
}) => {
  const _date = date.getTime();
  const now = new Date().getTime();
  const ago =
    _date < now ? " ago" : _date === now ? "Posted today" : " in the future";

  const diff = now - _date;
  return (
    <span style={style}>
      {timeSince(diff)}
      {ago}
    </span>
  );
};

export default NewsTimeAgo;

const timeSince = (diff: number) => {
  const msec = Math.abs(diff);
  const since = {
    yy: Math.floor(msec / 1000 / 60 / 60 / 24 / 365),
    mo: Math.floor(msec / 1000 / 60 / 60 / 24 / 30),
    dd: Math.floor(msec / 1000 / 60 / 60 / 24),
    hh: Math.floor(msec / 1000 / 60 / 60),
    mm: Math.floor(msec / 1000 / 60),
    ss: Math.floor(msec / 1000)
  };
  return diff
    ? since.yy
      ? since.yy > 1
        ? since.yy + " years"
        : since.yy + " year"
      : since.mo
      ? since.mo > 1
        ? since.mo + " months"
        : since.mo + " month"
      : since.dd
      ? since.dd > 1
        ? since.dd + " days"
        : since.dd + " day"
      : since.hh
      ? since.hh > 1
        ? since.hh + " hours"
        : since.hh + " hour"
      : since.mm
      ? since.mm > 1
        ? since.mm + " minutes"
        : since.mm + " minute"
      : since.ss > 1
      ? since.ss + " seconds"
      : since.ss + " second"
    : null;
};
