import React from "react";
import styled from "styled-components";
import { colors } from "../../../vars/palette";

const TitleWrap = styled.h2`
  color: ${colors.hex("primary")};
  text-shadow: 3px 3px 4px ${colors.rgba("black", 0.1)};
  font-weight: bold;
  text-align: center;
`;

const WidgetTitle = ({
  children,
  style
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <TitleWrap className="widget-title" style={style}>
      {children}
    </TitleWrap>
  );
};

export default WidgetTitle;
