import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageData, Img, WmkImage } from "wmk-image";

const NameWave = () => {
  const { wave }: { wave: { sharp: GatsbyImageData } } = useStaticQuery(graphql`
    {
      wave: file(relativePath: { eq: "nameplate_wave_blue.png" }) {
        sharp: childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <WmkImage
      style={{ width: "100%", transform: "scale(1)" }}
      image={new Img({ title: "Nameplate Blue Wave", ...wave.sharp })}
    />
  );
};

export default NameWave;
