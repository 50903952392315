import React, { useState } from "react";
import styled from "styled-components"
// import { Container } from "react-bootstrap"
import BGImage from "./images/Graphic_NoSteps.png"
import Step1 from "./WaterSteps/Step1"
import Step2 from "./WaterSteps/Step2"
import Step3 from "./WaterSteps/Step3"
import Step4 from "./WaterSteps/Step4"
import Step5 from "./WaterSteps/Step5"
import Step6 from "./WaterSteps/Step6"
import Step7 from "./WaterSteps/Step7" 
import Step8 from "./WaterSteps/Step8"


const WaterTreatment = () => {

  const [currStep, setCurrStep] = useState(0);
  const updateCurrStep = (stepNum: number) => {
    return(
      stepNum === currStep 
        ? closeSteps()
        : newStep(stepNum)
    )
  };
  const newStep = (stepNum: number) => {
    setCurrStep(stepNum); 
  }
  const closeSteps = () => {
    setCurrStep(0);
  };

  return (
    <Wrap>
      <img className="background" src={BGImage} alt="infographic depicting the water treatment plant from above" />
      <div className="click-areas-wrap">
        <Step1 currStep={currStep} updateCurrStep={updateCurrStep} />
        <Step2 currStep={currStep} updateCurrStep={updateCurrStep} />
        <Step3 currStep={currStep} updateCurrStep={updateCurrStep} />
        <Step4 currStep={currStep} updateCurrStep={updateCurrStep} />
        <Step5 currStep={currStep} updateCurrStep={updateCurrStep} />
        <Step6 currStep={currStep} updateCurrStep={updateCurrStep} />
        <Step7 currStep={currStep} updateCurrStep={updateCurrStep} />
        <Step8 currStep={currStep} updateCurrStep={updateCurrStep} />
        <div className="off-click" onClick={() => closeSteps()} />
      </div>      
    </Wrap>
  );
};

export default WaterTreatment; 

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 51vw;
  img.background {
    width: 100%;
    height: 51vw;
  }
  .click-areas-wrap { 
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 54vw;
    .info-wrap {
      .title, .title-only {
        line-height: 18px;
        font-size: 15px;
        font-weight: bold;
      }
      .copy {
        font-size: .9rem;
      }
    }
    .click-step {
      position: absolute;
      z-index: 100;
      background: #1A7FAB;
      color: white;
      border: 0;
    }
    .step-line {
      background: #1A7FAB;
      position: absolute;
      z-index: 20;
    }
    .circle-step {
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      background: #B0D6E0;
      font-size: 1.5rem;
      font-weight: bold;
      border-radius: 100%;
      width: auto;
      padding: 2px 15px;
      position: absolute;
      z-index: 25;
      transition: all 1s ease;
      &.open {
        font-size: 0rem;
        padding: 0;
        transition: all 1s ease .5s;
      }
    }
    .off-click {
      position: absolute;
      top: 0;
      z-index: 15;
      width: 100%;
      height: 100%;
      // background: green;
    }
  }

  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step {
      height: 24%;
      &.closed {
        width: 18%;
      }
    }
    .circle-step {
      font-size: 1rem !important;
      padding: 3px 12px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .click-step {
      height: 27%;
      &.closed {
        width: 19%;
      }
    }
    .circle-step {
      font-size: .9rem !important;
      padding: 2px 10px !important;
    }
  }
  @media only screen and (max-width: 475px) {
    .click-step {
      height: 30%;
      &.closed {
        width: 20%;
      }
    }
    .circle-step {
      font-size: .7rem !important;
      padding: 0 5px !important;
    }
  }
`