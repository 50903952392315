import React from "react";
import { Container, Row } from "react-bootstrap";
import NewsExcerpt from "./NewsExcerpt";

const ExcerptsCategory = ({ categories }) => {
  return (
    <Container fluid style={{ padding: "4vh 0" }}>
      <Row style={{ justifyContent: "center" }} className="g-0">
        {categories && categories.length > 0 ? (
          categories.map((news) => {
            return (
              <NewsExcerpt key={news.slug} news={news} style={{ padding: 0 }} />
            );
          })
        ) : (
          <em>No eNewsletters posted at this time</em>
        )}
      </Row>
    </Container>
  );
};

export default ExcerptsCategory;
