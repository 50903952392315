import React from "react";

export const IconSpray = () => {
  return (
    <svg viewBox="0 0 95.54 82.13">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M28.69,77.59,31,73H64.79l2.27,4.55Zm7.68-18.17h23V68.5h-23V59.42Zm23-27.55v23h-23v-23ZM40.91,22.79H54.84v4.54H40.91V22.79Zm-4.54-7.54a32.9,32.9,0,0,1,23,0v3h-23v-3ZM67.59,68.5H63.92V27.33H59.38V22.79h4.54V12.21a37.94,37.94,0,0,0-32.09,0V22.79h4.54v4.54H31.83V68.5H28.15l-4.54,9.09H9.12v4.54h77.5V77.59H72.13L67.59,68.5Z"
          />
          <polygon
            className="cls-2"
            points="73 9.17 77.54 9.17 77.54 13.71 73 13.71 73 9.17 73 9.17"
          />
          <polygon
            className="cls-2"
            points="73 18.25 77.54 18.25 77.54 22.79 73 22.79 73 18.25 73 18.25"
          />
          <polygon
            className="cls-2"
            points="91 9.17 95.54 9.17 95.54 13.71 91 13.71 91 9.17 91 9.17"
          />
          <polygon
            className="cls-2"
            points="91 18.25 95.54 18.25 95.54 22.79 91 22.79 91 18.25 91 18.25"
          />
          <polygon
            className="cls-2"
            points="91 27.42 95.54 27.42 95.54 31.96 91 31.96 91 27.42 91 27.42"
          />
          <polygon
            className="cls-2"
            points="91 0 95.54 0 95.54 4.54 91 4.54 91 0 91 0"
          />
          <polygon
            className="cls-2"
            points="0 9.17 4.54 9.17 4.54 13.71 0 13.71 0 9.17 0 9.17"
          />
          <polygon
            className="cls-2"
            points="0 18.25 4.54 18.25 4.54 22.79 0 22.79 0 18.25 0 18.25"
          />
          <polygon
            className="cls-2"
            points="0 27.42 4.54 27.42 4.54 31.96 0 31.96 0 27.42 0 27.42"
          />
          <polygon className="cls-2" points="0 0 4.54 0 4.54 4.54 0 4.54 0 0 0 0" />
          <polygon
            className="cls-2"
            points="18.2 9.17 22.75 9.17 22.75 13.71 18.2 13.71 18.2 9.17 18.2 9.17"
          />
          <polygon
            className="cls-2"
            points="18.2 18.25 22.75 18.25 22.75 22.79 18.2 22.79 18.2 18.25 18.2 18.25"
          />
          <polygon
            className="cls-2"
            points="82.08 13.71 86.62 13.71 86.62 18.25 82.08 18.25 82.08 13.71 82.08 13.71"
          />
          <polygon
            className="cls-2"
            points="82.08 22.79 86.62 22.79 86.62 27.33 82.08 27.33 82.08 22.79 82.08 22.79"
          />
          <polygon
            className="cls-2"
            points="82.08 4.63 86.62 4.63 86.62 9.17 82.08 9.17 82.08 4.63 82.08 4.63"
          />
          <polygon
            className="cls-2"
            points="9.12 13.71 13.66 13.71 13.66 18.25 9.12 18.25 9.12 13.71 9.12 13.71"
          />
          <polygon
            className="cls-2"
            points="9.12 22.79 13.66 22.79 13.66 27.33 9.12 27.33 9.12 22.79 9.12 22.79"
          />
          <polygon
            className="cls-2"
            points="9.12 4.63 13.66 4.63 13.66 9.17 9.12 9.17 9.12 4.63 9.12 4.63"
          />
        </g>
      </g>
    </svg>
  );
};
