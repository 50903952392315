import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
// import Icon8 from "../images/icon-step8.png"

const Icon8 = require("../images/icon-step8.png");

const step8IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`;

const Wrap = styled.div`
  .click-step8 {
    height: 9vw;
    top: 45%;
    &.open {    
      z-index: 150 !important;
      border-right: 6px solid white !important; 
      width: 50%;
      left: 16%; 
      clip-path: polygon(15% 0%, 0% 100%, 100% 100%, 100% 0%); 
      transition: left .75s ease, width .5s ease .75s, clip-path .5s ease .75s, border-right .5s ease .75s;
    }
    &.closed {
      border-right: 0;
      width: 10.5vw;
      left: 4%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .65s ease, border-right .5s ease, clip-path .65s ease, left .5s ease .65s;
    }
    // .right-border-div {
    //   height: 100%;
    //   width: 5px;
    //   background: white;
    // }
    .info-wrap {
      .title-only {
        margin-top: 10px;
        p {
          font-size: .9rem;
          margin-bottom: -3px;
        }
      }
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 5px 10px 1rem 7vw;
        .icon {
          margin-right: .5rem;
          margin-top: 1rem;
          img {
            width: 5rem;
            height: 3.5rem;
          }
          img.open {
            animation: ${step8IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            margin-bottom: 8px;
            font-size: 1.4rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step8-line1 {
    height: 4px;
    top: 53%;
    right: 79%;
    width: 11%;
    transition: width .25s ease-in .65s;
    &.open {
      width: 0%;
      transition: width .75s ease;
    }
  }
  .circle-step8 {
    top: 51%;
    right: 77%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step8 {
      top: 45%;
      &.open {
        width: 40%;
        left: 16%; 
      }
      &.closed {
        left: 4%;
      }
      .full-info-wrap {
        padding: 5px 10px 1rem 4vw !important;
        .icon {
          margin-top: 2.5rem !important;
        }
        .title {
          font-size: 1.2rem !important;
        }
        .copy {

        }
      }
    }
    .step8-line1 {
      height: 4px;
      top: 53%;
      right: 79%;
      width: 11%;
      transition: width .25s ease-in .65s;
      &.open {
        width: 0%;
        transition: width .75s ease;
      }
    }
  }
  @media only screen and (max-width: 1007px) {
    .click-step8 {
      top: 43%;
      &.open {
        width: 65%;
        left: 13%; 
      }
      &.closed {
        left: 4%;
      }
      .title-only {
        margin-top: 0 !important;
        p {
          font-size: .8rem !important;
          margin-bottom: -4px !important;
        }
      }
      .full-info-wrap {
        padding: 3px 10px 1rem 7vw !important;
        .icon {
          margin-right: .5rem;
          margin-top: 1.5rem !important;
          img {
            width: 3.5rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 7px !important;
            font-size: 1.1rem !important;
          }
          .copy {
            text-align: left;
            line-height: 18px !important;
            font-size: 1rem !important;
          }
        }
      }
    }
    .step8-line1 {
      height: 4px;
      top: 53%;
      right: 79%;
      width: 11%;
      transition: width .25s ease-in .65s;
      &.open {
        width: 0%;
        transition: width .75s ease;
      }
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step8 {
      top: 43%;
      &.open {
        width: 70%;
        left: 11%; 
      }
      &.closed {
        left: 4%;
      }
      .title-only {
        margin-top: 0 !important;
        p {
          font-size: .7rem !important;
          margin-bottom: -7px !important;
        }
      }
      .full-info-wrap {
        padding: 0px 5px 1rem 8vw !important;
        .icon {
          margin-right: .75rem !important;
          margin-top: 1rem;
          img {
            width: 4rem !important;
            height: 2.5rem !important;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 1px !important;
            font-size: .9rem !important;
          }
          .copy {
            text-align: left;
            line-height: 15px !important;
            font-size: .85rem !important;
          }
        }
      }
    }
    .step8-line1 {
      height: 4px;
      top: 53%;
      right: 79%;
      width: 11%;
      transition: width .25s ease-in .65s;
      &.open {
        width: 0%;
        transition: width .75s ease;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step8 {
      top: 43%;
      &.open {
        width: 70%;
        left: 10%; 
      }
      &.closed {
        left: 4%;
      }
      .title-only {
        margin-top: -10px !important;
        p {
          font-size: .6rem !important;
          margin-bottom: -9px !important;
        }
      }
      .full-info-wrap {
        padding: 0px 3px 1rem 7vw !important;
        .icon {
          margin-right: .5rem;
          margin-top: 1rem !important;
          img {
            width: 4rem !important;
            height: 2.5rem !important;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 0px !important;
            font-size: .75rem !important;
          }
          .copy {
            text-align: left;
            line-height: 12px !important;
            font-size: .7rem !important;
          }
        }
      }
    }
    .step8-line1 {
      height: 4px;
      top: 53%;
      right: 79%;
      width: 11%;
      transition: width .25s ease-in .65s;
      &.open {
        width: 0%;
        transition: width .75s ease;
      }
    }
`;

const Step8 = ({
  currStep,
  updateCurrStep
}: {
  currStep: number;
  updateCurrStep: Function;
}) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(
      () => (currStep === 8 ? setFullInfo(true) : setFullInfo(false)),
      750
    );
  }, [currStep]);
  // const [rightBorder, setRightBorder] = useState(false);
  // const delayedStates = (fullInfo, rightBorder) => {
  //   const fullInfoTO = setTimeout(() => setFullInfo(!fullInfo), 500);
  //   const rightBorderTO = setTimeout(() => {setRightBorder(!rightBorder); console.log('rightBorderTO fired off')}, 1000);
  // }
  const buttonClick = () => {
    updateCurrStep(8);
  };
  const info = {
    icon: Icon8?.default,
    title: {
      full: "Drinking Water Delivered to Customers",
      piece1: "Drinking",
      piece2: "Water",
      piece3: "Delivered to",
      piece4: "Customers"
    },
    copy: "As water moves through miles of pipeline, it is continuously monitored to ensure the water is safe to drink at homes, schools and businesses."
  };

  const buttonClosed = `click-step click-step8 closed`;
  const buttonOpen = `click-step click-step8 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step8-line1 open`;
  const stepLineOneClosed = `step-line step8-line1`;

  return (
    <Wrap>
      <button
        className={currStep === 8 ? buttonOpen : buttonClosed}
        onClick={() => buttonClick()}>
        <div
          className={currStep === 8 ? infoWrapOpen : infoWrapClosed}
          style={!fullInfo ? { paddingTop: "2rem" } : { paddingTop: "0" }}>
          {fullInfo ? (
            <div
              className="full-info-wrap"
              style={
                currStep === 8
                  ? { opacity: 1, transition: "opacity .75s ease .25s" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <div className="icon">
                <img
                  className={fullInfo ? `open` : `closed`}
                  src={info.icon}
                  alt={info.title.full + ` icon`}
                  style={
                    currStep === 8
                      ? {
                          transform: "scale(1)",
                          transition: "transform .75s ease"
                        }
                      : {
                          transform: "scale(0)",
                          transition: "transform .75s ease"
                        }
                  }
                />
              </div>
              <div className="title-and-copy">
                <p className="title" style={fullInfo && { textAlign: "left" }}>
                  {info.title.full}
                </p>
                <p className="copy">{info.copy}</p>
              </div>
            </div>
          ) : (
            <div
              className="title-only"
              style={
                currStep !== 8
                  ? { opacity: 1, transition: "opacity .75s ease" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <p>{info.title.piece1}</p>
              <p>{info.title.piece2}</p>
              <p>{info.title.piece3}</p>
              <p>{info.title.piece4}</p>
            </div>
          )}
        </div>
        {/* {rightBorder && <div className="right-border-div" />} */}
      </button>
      <div
        className={
          currStep === 8
            ? "circle-step circle-step8 open"
            : "circle-step circle-step8"
        }>
        8
      </div>
      <div className={currStep === 8 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  );
};

export default Step8;
