import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
// import Icon2 from "../images/icon-step2.png"

const Icon2 = require("../images/icon-step2.png")

const step2IconGrow = keyframes`
0% {transform: scale(0);}
100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step2 {
    height: 9vw;
    top: 21%;
    &.open {
      z-index: 150 !important;
      border-left: 6px solid white !important;
      width: 75%;
      right: 19%; 
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 85% 0%); 
      transition: right .75s ease, width .5s ease .75s, clip-path .5s ease .75s, border-left .5s ease .75s;
    }
    &.closed {
      border-left: 0;
      width: 10.5vw;
      right: 7%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .65s ease, border-left .5s ease, clip-path .65s ease, right .5s ease .65s;
    }
    .info-wrap {
      .title {
        text-align: center;
        &.title-only {
          margin-top: 20px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 5px 3rem 1rem 1rem;
        .icon {
          margin-right: 4rem;
          margin-top: 1rem;
          img {
            width: 6rem;
            height: 4rem;
          }
          img.open {
            animation: ${step2IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 10px;
          .title {
            margin-bottom: 3px;
            font-size: 1.2rem;
          }
          .copy {
            text-align: left;
            line-height: 18px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step2-line1 {
    height: 4px;
    top: 29%;
    left: 76%;
    width: 12%;
    transition: width .25s ease-in .65s;
    &.open {
      width: 0%;
      transition: width .75s ease;
    }
  }
  .circle-step2 {
    top: 27%;
    right: 22.5%;
  }

  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step2 {
      top: 18%;
      &.open {
        width: 75%;
        right: 15%;
      }
      &.closed {
        right: 3%;
      }
      .full-info-wrap {
        padding-top: 1% !important;
        .title {
          // font-size: .9rem !important;
        }
        .copy {
          // font-size: .7rem !important;
        }
        .icon {
          padding-top: 0rem;
          img {
            height: 4rem !important;
            padding-right: 1rem;
          }
        }
      }
    }
    .step2-line1 {
      right: 19%; 
      width: 15%;
      transition: width .25s ease-in .65s;
      &.closed {
        width: 0%;
      }
    }
  }
  @media only screen and (max-width: 850px) {
    .click-step2 {
      top: 19%;
      &.open {
        width: 75%;
      }
      .info-wrap.closed {
        .title {
          font-size: .7rem;
        }
      }
      .full-info-wrap {
        padding-top: 1% !important;
        .title {
          font-size: .9rem !important;
          margin-bottom: 1px !important;
        }
        .copy {
          font-size: .7rem !important;
          line-height: 14px !important;
        }
        .icon {
          padding-top: 0.5rem;
          margin-right: 0 !important;
          img {
            height: 3rem !important;
            width: 5.5rem !important;
            padding-right: 1rem;
          }
        }
      }
    }
    .step2-line1 {
      right: 19%; 
      width: 15%;
      transition: width .25s ease-in .65s;
      &.closed {
        width: 0%;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step2 {
      top: 19%;
      &.open {
        width: 85%;
      }
      .info-wrap.closed {
        .title {
          font-size: .6rem;
          line-height: 13px !important;
          margin-top: 1vw !important;
        }
      }
      .full-info-wrap {
        padding: 0 15px 1rem 0 !important;
        margin-top: -4px;
        .title {
          font-size: .8rem !important;
          margin-bottom: -1px !important;
        }
        .copy {
          font-size: .6rem !important;
          line-height: 11px !important;
        }
        .icon {
          padding-top: 0.5rem;
          img {
            height: 2.5rem !important;
            width: 4.5rem !important;
            padding-right: .5rem;
          }
        }
      }
    }
    .step2-line1 {
      top: 31%;
      right: 19%; 
      width: 15%;
      transition: width .25s ease-in .65s;
      &.closed {
        width: 0%;
      }
    }
  }
`
 
const Step2 = ({ currStep, updateCurrStep }: {currStep: number, updateCurrStep: Function}) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => currStep === 2 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  // const [rightBorder, setRightBorder] = useState(false);
  // const delayedStates = (fullInfo, rightBorder) => {
  //   const fullInfoTO = setTimeout(() => setFullInfo(!fullInfo), 500);
  //   const rightBorderTO = setTimeout(() => {setRightBorder(!rightBorder); console.log('rightBorderTO fired off')}, 1000);
  // }
  const buttonClick = () => {
    updateCurrStep(2);
  }
  const info = {
    icon: Icon2?.default, 
    title: {
      full: 'Coagulation'
    }, 
    copy: 'The untreated water is rapidly mixed with chemicals called coagulants (polymers, ferric sulfate, sodium hypochlorite). These chemicals cause the dirt and debris (sediment) in the water to bind together, making them easier to remove.'
  };

  const buttonClosed = `click-step click-step2 closed`;
  const buttonOpen = `click-step click-step2 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step2-line1 open`;
  const stepLineOneClosed = `step-line step2-line1`;
  
  return (
    <Wrap>
      <button className={currStep === 2 ? buttonOpen : buttonClosed} onClick={() => buttonClick()}>
        <div className={currStep === 2 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {paddingTop: '2rem'} : {paddingTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 2 ? {opacity: 1, transition: 'opacity .75s ease .25s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 2 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
              </div>
          : <div className="title title-only" style={currStep !== 2 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.full}</p>
            </div>
          }
        </div>
        {fullInfo && <div className="right-border-div" />}
      </button>      
      <div className={currStep === 2 ? "circle-step circle-step2 open" : "circle-step circle-step2"}>2</div>
      <div className={currStep === 2 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  )
}
 
export default Step2