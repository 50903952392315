import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Container, Row } from "react-bootstrap";
import { Media, MediaItem, MediaQuery } from "./classes/Media";
import { Tag } from "wmk-tag";
import { MediaTypes, TagQuery } from "../../../node/mediaTag";
import { startCase } from "lodash";
import { Typography } from "../../ui/Typography";
import MediaCatTile from "./parts/MediaCatTile";
import { slugify } from "../../routes/Home/NewsAndEvents/NewsFeed/PostTag";
import { Breadcrumbs } from "./parts/Breadcrumbs";

const MediaGalleryPage = ({ init }: { init?: Tag }) => {
  const data: GalleryQuery = useStaticQuery(query);
  const media = new Media(data);
  const catObj: { photos?: TagQuery[]; videos?: TagQuery[] } = {};

  const categories = [
    { name: "Teacher/Student Resources", slug: "#TeacherStudentResources" },
    { name: "Watershed Protection", slug: "#WatershedProtection" },
    { name: "How to Save Water", slug: "#HowToSaveWater" },
    { name: "Irrigation System Tips", slug: "#IrrigationSystemTips" },
    { name: "Virtual Classes", slug: "#VirtualClasses" }
  ];

  const mediaReferences = [
    { categories: [], title: "UTRWD Photo Categories" },
    { categories: categories, title: "UTRWD Video Categories" }
  ];

  const cats: { photos?: TagQuery[]; videos?: TagQuery[] } =
    // data.catData.blocks.reduce((cats, block) => {
      mediaReferences.reduce((types, ref) => {
        const type = Array.isArray(ref.title.match(/video/i))
          ? "videos"
          : Array.isArray(ref.title.match(/photo/i))
          ? "photos"
          : undefined;
        if (!types[type]) {
          types[type] = ref.categories;
        } else {
          const old = types[type];
          types[type] = [...old, ...ref.categories];
        }
        return types;
      }, catObj);
      // return cats;
    // }, catObj);
  return (
    <Container>
      <Breadcrumbs />
      {Object.keys(cats).map((type: MediaTypes, i) => {
        const mediaItems: MediaItem[] = type in media ? media[type] : [];
        return (
          <Row key={type + i}>
            {cats[type].length > 0 ? (
              <Typography.H2>{startCase(type)}</Typography.H2>
            ) : null}

            {cats[type].map((cat, i) => {
              const category = {
                name: cat.name,
                slug: slugify(startCase(cat.slug))
              };
              const catMedia = mediaItems.reduce((matches, item) => {
                item.tags.forEach((t) => {
                  if (t.slug === category.slug) {
                    matches.push(item);
                  }
                });
                return matches;
              }, []);
              return catMedia.length > 0 ? (
                <MediaCatTile
                  key={cat.slug + i}
                  category={category}
                  type={type}
                  media={catMedia}
                />
              ) : null;
            })}
          </Row>
        );
      })}
    </Container>
  );
};

export default MediaGalleryPage;

export interface GalleryQuery extends MediaQuery {
  catData: {
    blocks: {
      copy: {
        references: {
          title: string;
          categories: {
            name: string;
            slug: string;
          }[];
        }[];
      };
    }[];
  };
}

const query = graphql`
  {
    photos: allFlickrPhoto {
      edges {
        node {
          ...NodeFlickrFields
        }
      }
    }
    videos: allYoutubeVideo {
      edges {
        node {
          ...NodeYoutubeFields
        }
      }
    }
  }
`;
