import React from "react";
import WidgetTitle from "../../../../common/typography/WidgetTitle";
import { graphql, useStaticQuery } from "gatsby";
import BoardOfficers from "./Officers/BoardOfficers";
import BoardCurrent from "./CurrentMembers/BoardCurrent";
import ExecutiveDirector from "./ExecutiveDirecor/ExectuiveDirector";
import { BoardOfDirectorsFields } from "../../../../../node/fragments/NodeBODFields";
import { ContentfulImageQuery } from "wmk-image";

const BoardOfDirectors = () => {
  const data: BoDQuery = useStaticQuery(query);
  const { allOfficers, allMembers, ed } = data;
  const officers = allOfficers.edges.map((e) => e.node);
  const members = allMembers.edges.map((e) => e.node);
  return (
    <div>
      <WidgetTitle
        style={{
          position: "relative",
          bottom: "70px",
          marginTop: "100px",
          fontSize: "3rem"
        }}>
        Board Of Directors
      </WidgetTitle>
      <WidgetTitle
        style={{ position: "relative", bottom: "70px", marginTop: "50px" }}>
        Board Officers
      </WidgetTitle>
      <BoardOfficers officers={officers} />
      <BoardCurrent members={members} />
      <ExecutiveDirector ed={ed} />
    </div>
  );
};

export default BoardOfDirectors;

export interface BoardMemberQuery extends BoardOfDirectorsFields {
  headshot: ContentfulImageQuery;
}

interface BoDQuery {
  allOfficers: {
    edges: {
      node: BoardMemberQuery;
    }[];
  };
  allMembers: {
    edges: {
      node: BoardOfDirectorsFields;
    }[];
  };
  ed: BoardMemberQuery;
}

export const query = graphql`
  {
    allOfficers: allContentfulBoardMember(
      filter: { memberType: { eq: "Officer" } }
      sort: { fields: sortName, order: ASC }
    ) {
      edges {
        node {
          ...NodeBODFields
          headshot {
            gatsbyImageData(height: 180, width: 180)
            file {
              url
              contentType
            }
            title
          }
        }
      }
    }
    allMembers: allContentfulBoardMember(
      filter: { memberType: { eq: "Current Member" } }
      sort: { fields: sortName, order: ASC }
    ) {
      edges {
        node {
          ...NodeBODFields
        }
      }
    }
    ed: contentfulBoardMember(memberType: { eq: "Executive Director" }) {
      ...NodeBODFields
      headshot {
        gatsbyImageData(height: 210, width: 210)
        file {
          url
          contentType
        }
        title
      }
    }
  }
`;
