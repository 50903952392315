import React from "react";
import { Row, Col } from "react-bootstrap";
import { WmkLink } from "wmk-link";
import NewsExcerptTitle from "../NewsExcerptTitle";
import NewsTimeAgo from "../NewsTimeAgo";
import styled from "styled-components";
import NewsDate from "../NewsDate";
import DefaultImage from "../../../common/DefaultImage";
import { colors } from "../../../../vars/palette";
import { NewsPost } from "../../../../classes/News";
import { Tag } from "wmk-tag";
import { WmkImage } from "wmk-image";

const Styled = {
  ViewMore: styled.div`
    text-align: right;
    a {
      &:hover {
        text-decoration: none;
      }
    }
  `,
  ExcerptImage: styled(Col)`
    .gatsby-image-wrapper {
      border-radius: 3px;
    }
  `
};

export const isENewsletter = (tags: Tag[]) =>
  tags && tags.find((e) => e.name === "UTRWD Enews") ? true : false;

const NewsExcerpt = ({
  news,
  home,
  noImage,
  style
}: {
  news: NewsPost;
  home?: boolean;
  noImage?: boolean;
  style?: React.CSSProperties;
}) => {
  const { title, path, date, content, tags, link, image } = news;
  const isENews = isENewsletter(tags);
  return (
    <Col
      sm={home ? false : 12}
      md={home ? false : 6}
      lg={home ? false : 4}
      className={home ? "home" : undefined}
      style={style}>
      <Row
        className="flex-column"
        style={{ padding: noImage ? "2vh" : "4vh 2vh" }}>
        {/* Excerpt Image */}
        {!noImage && (
          <Styled.ExcerptImage>
            <WmkLink
              to={isENews && link ? link : path}
              target={isENews && link ? "blank" : undefined}>
              {home && image ? (
                <WmkImage image={image} />
              ) : (
                <DefaultImage
                  size="thumb"
                  style={{ width: "100%", maxHeight: 220 }}
                />
              )}
            </WmkLink>
          </Styled.ExcerptImage>
        )}
        {/* Title & Timestamp */}
        <Col>
          <Row
            className="flex-column"
            style={{
              minHeight: 80
            }}>
            <Col style={{ paddingTop: !home ? 0 : "2vh" }}>
              <NewsExcerptTitle
                path={isENews ? undefined : path}
                url={isENews && link ? link : undefined}
                reverse={!home}>
                {title}
              </NewsExcerptTitle>
            </Col>
            <Col sm="auto">
              {home ? (
                <NewsDate style={{ color: colors.hex("accent") }}>
                  {date.toLocaleDateString()}
                </NewsDate>
              ) : (
                <NewsTimeAgo
                  style={{
                    fontSize: ".8rem",
                    color: home ? colors.hex("accent") : colors.hex("white")
                  }}
                  date={date}
                />
              )}
            </Col>
            <Col
              sm="auto"
              style={{
                borderBottom: "1px solid " + colors.hex("white"),
                margin: "0 1rem"
              }}
            />
          </Row>
        </Col>
        {/* Excerpt Copy */}
        <Col
          style={{
            color: home ? colors.hex("text") : colors.hex("white"),
            alignItems: "flex-end",
            paddingTop: home ? ".5vh" : "1.5vh"
          }}>
          {content.excerpt(240)}
          <Styled.ViewMore>
            <WmkLink
              to={isENews && link ? link : path}
              target={isENews && link ? "blank" : undefined}
              style={{
                color: home ? colors.hex("accent") : colors.hex("white"),
                textDecoration: home ? "none" : "underline"
              }}
              label={"Read more about " + title}>
              {home ? "...more" : "View More"}
            </WmkLink>
          </Styled.ViewMore>
        </Col>
      </Row>
    </Col>
  );
};

export default NewsExcerpt;
