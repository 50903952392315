import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import JobSlider from "./JobSlider/JobSlider";
import StepsToApply from "./StepsToApply";
import { colors } from "../../../vars/palette";
import { JobFields } from "../../../node/fragments/NodeJobFields";

const Wrap = styled(Container)`
  margin: 5vw auto;
  .slider-col,
  .steps-col {
    position: relative;
    border: 3px solid ${colors.hex("secondary")};
  }
  .slider-col {
    margin-left: 3%;
    max-width: 63%;
  }
  .slider-title,
  .steps-title {
    position: absolute;
    z-index: 10;
    top: -1.5vw;
    padding: 0 1rem;
    background: ${colors.hex("white")};
    color: ${colors.hex("coaccent")};
    h3 {
      font-weight: bold;
      font-size: 2vw;
    }
  }
  .slider-title {
    right: 10%;
  }
  .steps-title {
    left: 10%;
  }
  .steps-col {
    display: flex;
    justify-content: center;
    align-items: center;
    .steps-copy {
      padding: 1.5rem 5px 0 0;
      a {
        font-size: 1.25rem;
        color: ${colors.hex("accent")};
        display: block;
        text-align: left;
        margin: 0rem 0 0rem 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .slider-col,
    .steps-col {
      max-width: 90%;
      margin: 2rem 5%;
    }
    .steps-copy {
      a {
        font-size: 4.5vw !important;
      }
    }
    .slider-title {
      top: -3vw;
      right: 23%;
      h3 {
        font-size: 4vw;
      }
    }
    .steps-title {
      top: -3.5vw;
      left: 25%;
      h3 {
        font-size: 4vw;
      }
    }
  }
`;

interface JobQuery {
  allContentfulJobs: {
    edges: { node: JobFields }[];
  };
}

const query = graphql`
  query MyQuery {
    allContentfulJobs(
      sort: { order: DESC, fields: createdAt }
      filter: { slug: { ne: "schema-do-not-delete" } }
    ) {
      edges {
        node {
          ...NodeJobFields
        }
      }
    }
  }
`;

const Jobs = () => {
  const data: JobQuery = useStaticQuery(query);
  const jobData = data.allContentfulJobs.edges.map((p) => p.node);
  const noJobStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  return (
    <Wrap id="jobs">
      <Row>
        <Col md={4} sm={12} className="steps-col">
          <StepsToApply />
        </Col>
        <Col
          md={8}
          sm={12}
          className="slider-col"
          style={jobData.length ? {} : noJobStyles}>
          <div className="slider-title">
            <h3>OPEN POSITIONS</h3>
          </div>
          {jobData && jobData.length ? (
            <JobSlider jobs={jobData} />
          ) : (
            <h3>
              There are currently no open positions, please check back again
              later.
            </h3>
          )}
        </Col>
      </Row>
    </Wrap>
  );
};

export default Jobs;
