import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LakeLevel, { LakeProperties } from "./LakeLevel";

export interface LakeFields {
  name: string;
  loc: string;
  properties: LakeProperties;
}

const LakeLevels = ({
  lakes = [
    { name: "Lewisville", loc: "Denton County, TX" },
    { name: "RayRoberts", loc: "Denton County, TX" },
    { name: "JimChapman", loc: "Delta County, TX" }
  ]
}: {
  lakes?: { name: string; loc: string }[];
}) => {
  const [lakeLevels, setLakeLevels] = useState<LakeFields[]>();
  useEffect(() => {
    const api = `https://api.watermarkadvertising.net/tx_lakes.php`;
    const getLakes = (lakeData: any) => {
      const foundLakes: LakeFields[] = [];
      lakes.forEach((lake) => {
        const { name, loc } = lake;
        if (lakeData[name])
          foundLakes.push({ name: name, loc: loc, properties: lakeData[name] });
      });
      return foundLakes;
    };
    fetch(api)
      .then((res) => {
        return res.json();
      })
      .then((data) => setLakeLevels(getLakes(data)));
  }, []);
  return (
    <Container>
      <Row style={{ justifyContent: "center", padding: "0 0 3vh 0" }}>
        {lakeLevels &&
          lakeLevels.length &&
          lakeLevels.map((lake) => {
            return (
              <Col
                key={lake.name}
                style={{ flexGrow: 0, minWidth: "300px", paddingTop: "3vh" }}>
                <LakeLevel lake={lake} />
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};

export default LakeLevels;
