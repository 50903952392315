import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from "../images/Screening.png"

const step2IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`

const Wrap = styled.div`
  .click-step2 {
    height: 8vw;
    top: 31%;
    &.open {    
      z-index: 150 !important;
      border-right: 6px solid white !important; 
      width: 61%;
      left: 30%; 
      clip-path: polygon(15% 0%, 0% 100%, 100% 100%, 100% 0%); 
      transition: left .75s ease, width .5s ease .75s, clip-path .5s ease .75s, border-right .5s ease .75s;
    }
    &.closed {
      border-right: 0;
      width: 9.5vw;
      left: 10%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%, 50% 1%);
      transition: width .65s ease, border-right .5s ease, clip-path .65s ease, left .5s ease .65s;
    }
    .info-wrap {
      .title-only {
        margin-top: 10px;
        p {
          font-size: 1rem;
          margin-bottom: -3px;
        }
      }
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 5px 10px 1rem 8vw;
        .icon {
          margin-right: .75rem;
          margin-top: 1rem;
          img {
            width: 4rem;
            height: 4rem;
          }
          img.open {
            animation: ${step2IconGrow} .75s ease;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }  
    }
  }
  .step2-line1 {
    height: 4px;
    top: 42%;
    right: 63%;
    width: 19%;
    transition: width .25s ease-in .65s;
    &.open {
      width: 0%;
      transition: width .75s ease;
    }
  }
  .circle-step2 {
    top: 40%;
    right: 62%;
  }


  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step2 {
      &.open {
        width: 69%;
      }
      .title-only {
        p {
          font-size: 1rem !important;
        }        
      }
      .full-info-wrap {
        display: flex;
        padding: 5px 10px 1rem 8vw;
        .icon {
          margin-top: 1rem;
          img {
            width: 4rem;
            height: 4rem;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 3px !important;
            font-size: 1.3rem !important;
          }
          .copy {
            line-height: 17px !important;
            font-size: .95rem !important;
          }
        }
      }  
    }

  }
  @media only screen and (max-width: 1007px) {
    .click-step2 {
      &.open {
        width: 68%;
      }
      .full-info-wrap {
        display: flex;
        padding: 2px 10px 1rem 8vw !important;
        .icon {
          margin-top: .7rem !important;
          img {
            width: 3rem !important;
            height: 3rem !important;
          }
        }
        .title-and-copy {
          .title {
            margin-bottom: 1px !important;
            font-size: 1rem !important;
          }
          .copy {
            line-height: 13px !important;
            font-size: .75rem !important;
          }
        }
      }  
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step2 {
      &.open {
        width: 71%;
        left: 29%;
      }
      .title-only {
        margin-top: 0 !important;
        p {
          font-size: .8rem !important;
        }        
      }
      .full-info-wrap {
        display: flex;
        padding: 0px 0px 1rem 7vw !important;
        .icon {
          margin-top: .7rem !important;
          margin-right: .5rem !important;
          img {
            width: 2.25rem !important;
            height: 2.25rem !important;
          }
        }
        .title-and-copy {
          margin-top: -3px;
          .title {
            margin-bottom: -3px !important;
            font-size: .8rem !important;
          }
          .copy {
            line-height: 11px !important;
            font-size: .65rem !important;
          }
        }
      } 
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step2 {
      &.open {
        width: 75%;
        left: 25%; 
      }
      &.closed {
        top: 30%;
        left: 5%;
      }
      .title-only {
        margin-top: -10px !important;
        p {
          font-size: .75rem !important;
          margin-bottom: -9px !important;
        }
      }
      .full-info-wrap {
        padding: 0px 0px 1rem 7vw !important;
        .icon {
          margin-top: 1.2rem !important;
          margin-right: .5rem !important;
          img {
            width: 2rem !important;
            height: 2rem !important;
          }
        }
        .title-and-copy {
          margin-top: -3px;
          .title {
            margin-bottom: -3px !important;
            font-size: .8rem !important;
          }
          .copy {
            line-height: 10px !important;
            font-size: .6rem !important;
          }
        }
      } 
    }
    .step2-line1 {
      top: 46%;
    }
`
 
const Step1 = ({ currStep, updateCurrStep, newStep, closeSteps }) => {
  const [fullInfo, setFullInfo] = useState(false);  
  useEffect(() => {
    setTimeout(() => currStep === 2 ? setFullInfo(true) : setFullInfo(false) , 750)
  }, [currStep])
  const buttonClick = (fullInfo) => {
    updateCurrStep(2);
  }
  const info = {
    icon: Icon, 
    title: {
      full: 'Screening',
      piece1: 'Screening'
    }, 
    copy: 'Incoming wastewater passes through screening equipment where objects such as rags, wood fragments, plastics, and grease are removed. The material removed is pressed to remove excess water and disposed of in a landfill.'
  };

  const buttonClosed = `click-step click-step2 closed`;
  const buttonOpen = `click-step click-step2 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step2-line1 open`;
  const stepLineOneClosed = `step-line step2-line1`;
  const circleClosed = `circle-step circle-step2`;
  const circleOpen = `circle-step circle-step2 open`;
  
  return (
    <Wrap>
      <button className={currStep === 2 ? buttonOpen : buttonClosed} onClick={() => buttonClick(fullInfo)}>
        <div className={currStep === 2 ? infoWrapOpen : infoWrapClosed} style={!fullInfo ? {paddingTop: '2rem'} : {paddingTop: '0'}}>
          {fullInfo
            ? <div className="full-info-wrap" style={currStep === 2 ? {opacity: 1, transition: 'opacity .75s ease .25s'} : {opacity: 0, transition: 'opacity .75s ease'}}>
                <div className="icon">
                  <img className={fullInfo ? `open` : `closed`} src={info.icon} alt={info.title.full+` icon`} style={currStep === 2 ? {transform: 'scale(1)', transition: 'transform .75s ease'} : {transform: 'scale(0)', transition: 'transform .75s ease'}} />
                </div>
                <div className="title-and-copy">
                  <p className="title" style={fullInfo && {textAlign: "left"}}>{info.title.full}</p>
                  <p className="copy">{info.copy}</p>
                </div>
              </div>
          : <div className="title-only" style={currStep !== 2 ? {opacity: 1, transition: 'opacity .75s ease'} : {opacity: 0, transition: 'opacity .75s ease'}}>
              <p>{info.title.piece1}</p>
            </div>
          }
        </div>
      </button>      
      <div className={currStep === 2 ? circleOpen : circleClosed}>2</div>
      <div className={currStep === 2 ? stepLineOneOpen : stepLineOneClosed} />
    </Wrap>
  )
}
 
export default Step1