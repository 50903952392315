import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
// import Icon6 from "../images/icon-step6.png"

const Icon6 = require("../images/icon-step6.png");

const step6IconGrow = keyframes`
  0% {transform: scale(0);}
  100% {transform: scale(1);} 
`;

const Wrap = styled.div`
  .click-step6 {
    height: 9vw;
    top: 25%;
    right: 85%;
    &.open {
      z-index: 150 !important;
      border-left: 6px solid white !important;
      width: 45%;
      top: 35%;
      right: 53%;
      clip-path: polygon(0% 0%, 0% 100%, 85% 100%, 100% 0%);
      transition: right 0.75s ease, top 0.5s ease 0.75s, width 0.5s ease 1.25s,
        clip-path 0.5s ease 1.25s, border-left 0.5s ease 1.25s;
    }
    &.closed {
      border-left: 0;
      width: 10.5vw;
      top: 25%;
      right: 75%;
      clip-path: polygon(0 0, 50% 100%, 51% 100%, 100% 0);
      transition: width 0.5s ease, border-left 0.25s ease, clip-path 0.5s ease,
        top 0.5s ease 0.5s, right 0.75s ease 1s;
    }
    // .right-border-div {
    //   height: 100%;
    //   width: 5px;
    //   background: white;
    // }
    .info-wrap {
      .title {
        text-align: center;
        p {
          margin-bottom: 0;
        }
      }
      .full-info-wrap {
        display: flex;
        padding: 10px 0px 1rem 0.5rem;
        .icon {
          margin-right: -1rem;
          margin-top: 0;
          img {
            width: 4rem;
            height: 5rem;
          }
          img.open {
            animation: ${step6IconGrow} 0.5s ease 0.75s;
          }
        }
        .title-and-copy {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 70%;
          padding-right: 1rem;
          .title {
            margin-bottom: 3px;
            font-size: 1.3rem;
          }
          .copy {
            text-align: left;
            line-height: 19px;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .step6-line1 {
    height: 4px;
    top: 32%;
    right: 58.6%;
    width: 22%;
    transition: width 0.75s ease 1s;
    &.open {
      width: 0%;
      transition: width 0.75s ease;
    }
  }
  .step6-line2 {
    width: 4px;
    bottom: 56%;
    right: 58.6%;
    height: 12%;
    transition: height 0.5s ease 0.5s;
    &.open {
      height: 0%;
      transition: height 0.75s ease 0.75s;
    }
  }
  .circle-step6 {
    top: 40%;
    right: 57%;
  }

  // ========================================= //
  // ===============MEDIA QUERIES============= //
  // ========================================= //
  @media only screen and (max-width: 1200px) {
    .click-step6 {
      top: 25%;
      &.open {
        width: 45%;
        right: 50%;
      }
      &.closed {
        right: 72%;
      }
      .full-info-wrap {
        padding-top: 1% !important;
        .title {
          font-size: 1.2rem !important;
        }
        .copy {
          font-size: 1rem !important;
        }
        .icon {
          padding-top: 0.5rem;
          // margin-right: 15px !important;
          img {
            height: 6rem !important;
            padding-right: 0rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1007px) {
    .click-step6 {
      top: 25%;
      &.open {
        width: 45%;
        right: 50%;
      }
      &.closed {
        right: 72%;
      }
      .title-only {
        font-size: 0.9rem !important;
      }
      .full-info-wrap {
        padding: 1% 0px 1rem 0.5rem !important;
        .title {
          font-size: 1.2rem !important;
        }
        .copy {
          font-size: 0.9rem !important;
        }
        .icon {
          padding-top: 0.5rem;
          margin-right: -2rem !important;
          img {
            height: 5rem !important;
            padding-right: 0rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 850px) {
    .click-step6 {
      top: 25%;
      &.open {
        width: 45%;
        right: 50%;
      }
      &.closed {
        right: 72%;
      }
      .title-only {
        font-size: 0.7rem !important;
      }
      .full-info-wrap {
        padding-top: 1% !important;
        .title {
          font-size: 1rem !important;
        }
        .copy {
          font-size: 0.8rem !important;
          line-height: 15px !important;
        }
        .icon {
          padding-top: 0.5rem;
          img {
            height: 5rem !important;
            padding-right: 1rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 782px) {
    .click-step6 {
      top: 25%;
      &.open {
        width: 47%;
        right: 50%;
      }
      &.closed {
        right: 72%;
      }
      .full-info-wrap {
        padding: 1% 0px 1rem 0.15rem !important;
        .title-and-copy {
          padding-right: 0.1rem !important;
        }
        .title {
          font-size: 0.9rem !important;
          margin-bottom: -2px !important;
        }
        .copy {
          font-size: 0.75rem !important;
          line-height: 13px !important;
        }
        .icon {
          padding-top: 0.5rem;
          img {
            height: 4rem !important;
            padding-right: 1.25rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .click-step6 {
      top: 25%;
      &.open {
        width: 47%;
        right: 50%;
      }
      &.closed {
        right: 72%;
      }
      .full-info-wrap {
        padding: 0 0 1rem 0.05rem !important;
        .title-and-copy {
          padding-right: 0.25rem !important;
          // margin-left: -10px;
          width: 75% !important;
        }
        .title {
          font-size: 0.8rem !important;
          margin-bottom: -2px !important;
        }
        .copy {
          font-size: 0.65rem !important;
          line-height: 11px !important;
        }
        .icon {
          padding-top: 0.5rem;
          img {
            height: 3rem !important;
            width: 2.5rem !important;
            padding-right: 0.25rem;
          }
        }
      }
    }
    .step6-line1 {
      right: 59.4%;
      width: 22%;
      transition: width 0.75s ease 1s;
      &.open {
        width: 0%;
        transition: width 0.75s ease;
      }
    }
    .step6-line2 {
      right: 59.4%;
      transition: height 0.5s ease 0.5s;
      &.open {
        height: 0%;
        transition: height 0.75s ease 0.75s;
      }
    }
  }
`;

const Step6 = ({
  currStep,
  updateCurrStep
}: {
  currStep: number;
  updateCurrStep: Function;
}) => {
  const [fullInfo, setFullInfo] = useState(false);
  useEffect(() => {
    setTimeout(
      () => (currStep === 6 ? setFullInfo(true) : setFullInfo(false)),
      750
    );
  }, [currStep]);
  // const [rightBorder, setRightBorder] = useState(false);
  // const delayedStates = (fullInfo, rightBorder) => {
  //   const fullInfoTO = setTimeout(() => setFullInfo(!fullInfo), 500);
  //   const rightBorderTO = setTimeout(() => {setRightBorder(!rightBorder); console.log('rightBorderTO fired off')}, 1000);
  // }
  const buttonClick = () => {
    updateCurrStep(6);
  };
  const info = {
    icon: Icon6?.default,
    title: {
      full: "Disinfection"
    },
    copy: "A small amount of chloramine (chlorine + ammonia) is added to keep water free of bacteria and microbes as it travels through the pipeline system."
  };

  const buttonClosed = `click-step click-step6 closed`;
  const buttonOpen = `click-step click-step6 open`;
  const infoWrapOpen = `info-wrap open`;
  const infoWrapClosed = `info-wrap closed`;
  const stepLineOneOpen = `step-line step6-line1 open`;
  const stepLineOneClosed = `step-line step6-line1`;
  const stepLineTwoOpen = `step-line step6-line2 open`;
  const stepLineTwoClosed = `step-line step6-line2`;

  return (
    <Wrap>
      <button
        className={currStep === 6 ? buttonOpen : buttonClosed}
        onClick={() => buttonClick()}>
        <div
          className={currStep === 6 ? infoWrapOpen : infoWrapClosed}
          style={!fullInfo ? { marginTop: "-2rem" } : { marginTop: "0" }}>
          {fullInfo ? (
            <div
              className="full-info-wrap"
              style={
                currStep === 6
                  ? { opacity: 1, transition: "opacity .75s ease .75s" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <div className="title-and-copy">
                <p className="title" style={fullInfo && { textAlign: "left" }}>
                  {info.title.full}
                </p>
                <p className="copy">{info.copy}</p>
              </div>
              <div className="icon">
                <img
                  className={fullInfo ? `open` : `closed`}
                  src={info.icon}
                  alt={info.title.full + ` icon`}
                  style={
                    currStep === 6
                      ? {
                          transform: "scale(1)",
                          transition: "transform .75s ease"
                        }
                      : {
                          transform: "scale(0)",
                          transition: "transform .75s ease"
                        }
                  }
                />
              </div>
            </div>
          ) : (
            <div
              className="title title-only"
              style={
                currStep !== 6
                  ? { opacity: 1, transition: "opacity .75s ease" }
                  : { opacity: 0, transition: "opacity .75s ease" }
              }>
              <p>{info.title.full}</p>
            </div>
          )}
        </div>
        {/* {rightBorder && <div className="right-border-div" />} */}
      </button>
      <div
        className={
          currStep === 6
            ? "circle-step circle-step6 open"
            : "circle-step circle-step6"
        }>
        6
      </div>
      <div className={currStep === 6 ? stepLineOneOpen : stepLineOneClosed} />
      <div className={currStep === 6 ? stepLineTwoOpen : stepLineTwoClosed} />
    </Wrap>
  );
};

export default Step6;
