import React from "react";

const Timestamp = ({ timestamp }: { timestamp: string }) => {
  const date = new Date(timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  return (
    <p style={{ fontSize: ".7rem", lineHeight: 1, textAlign: "center" }}>
      Last updated {months[date.getMonth()]} {date.getDate()},{" "}
      {date.getFullYear()}
      {/* {" "}at{" "}
        {date.getHours() > 10 ? date.getHours() : "0" + date.getHours()}:
        {date.getMinutes() ? date.getMinutes() : "00"} */}
    </p>
  );
};

export default Timestamp;
